import React from "react";
import { connect } from "react-redux";
import { showInfoBanner } from "../../App/appActions";
import { removeFromCart } from "../../Auth/authActions";
import API from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import { Item } from "./Item";

const Items = ({ items, removeFromCart, user, ...rest }) => {
  const handleRemoveItem = (item) => {
    API.post(
      `Subscription/removeItemFromCart/${item.cartId}`,
      {},
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    )
      .then(({ data: r }) => {
        if (r.status) {
          showInfoBanner(
            (r.info && r.info?.friendlyMessage) ||
              `${item.name} paketi sepetten çıkarıldı.`,
            "success"
          );

          removeFromCart(item);
        } else {
          if (r.info && `friendlyMessage` in r.info)
            showInfoBanner(r.info.friendlyMessage, "error");
        }
      })
      .catch((err) => handleErrors(err));
  };

  return (
    <div className="items">
      {items.map((item) => (
        <Item key={item.id} item={item} removeFromCart={handleRemoveItem} />
      ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (item) => dispatch(removeFromCart(item)),
});

export default connect(null, mapDispatchToProps)(Items);
