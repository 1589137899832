import React, { Component } from "react";
import { uniqueId } from "lodash";

export default class HACheckbox extends Component {
  id = uniqueId();

  render() {
    return (
      <div className={`HACheckbox ${this.props.error ? `error` : ""}`}>
        <input
          type="checkbox"
          name={this.props.name}
          id={`ha-checkbox-${this.id}`}
          className="d-none"
          onChange={this.props.onChange ? this.props.onChange : () => {}}
          checked={this.props.checked && this.props.checked}
        />
        <label
          htmlFor={`ha-checkbox-${this.id}`}
          className="d-flex justify-content-start align-items-start"
        >
          <span className="box d-flex justify-content-center align-items-center">
            <svg
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.76534 6.80866C2.52708 6.80866 2.33213 6.70036 2.13718 6.46209L0.173285 4.04332C0.0577617 3.91336 0 3.74729 0 3.58845C0 3.25632 0.259928 2.99639 0.584838 2.99639C0.779783 2.99639 0.953069 3.06859 1.11913 3.2852L2.73646 5.36462L5.87726 0.33213C6.02166 0.108303 6.20217 0 6.39711 0C6.70758 0 7.01083 0.209386 7.01083 0.548736C7.01083 0.707581 6.92419 0.873646 6.83754 1.01805L3.36462 6.45487C3.213 6.69314 3.01083 6.80866 2.76534 6.80866Z"
                fill="#FFFFFF"
              />
            </svg>
          </span>
          <span className="text">{this.props.children}</span>
        </label>
      </div>
    );
  }
}
