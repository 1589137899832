import React from "react";
import { Helmet } from "react-helmet-async";
import { HAInstructor } from "../../utils/components/HAInstructor";
import socialMedias from "../../utils/db/socialMedias";

import IntroVideoArea from "../../utils/components/IntroVideoArea";
import API from "../../utils/API";
import { handleErrors, sendGAEvent } from "../../utils/helper";
import { useLocation, useParams } from "react-router";
import useAppContext from "../../utils/hooks/useAppContext";
import { HAButton } from "../../utils/components/HAButton";
import { Link } from "react-router-dom";

const WorkshopDetail = () => {
  const { slug } = useParams();
  const [socials, setSocials] = React.useState([]);
  const [workshop, setWorkshop] = React.useState({
    id: null,
    title: "",
    subTitle: "",
    avatarUrl: "",
    description: "",
    externalLink: "",
    specialties: [],
  });

  const { pathname } = useLocation();
  const { activeCategory, createModuleTree } = useAppContext();

  React.useEffect(() => {
    API.get(`/Workshops/WorkshopBySlug/${slug}`)
      .then(({ data: r }) => {
        const { data } = r;
        setWorkshop(data);

        setTimeout(() => {
          sendGAEvent("page_view", {
            pagePath: pathname,
            pageTitle: document.title,
          });
        }, 20);
      })
      .catch((err) => handleErrors(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  React.useEffect(() => {
    const socialPlatforms = socialMedias
      .filter((social) => workshop[social.id + "Url"])
      .map(({ id, logo }) => ({
        id,
        logo,
        link: workshop[id + "Url"].startsWith("http")
          ? workshop[id + "Url"]
          : `https://${workshop[id + "Url"]}`,
      }));

    setSocials(socialPlatforms);
  }, [workshop]);

  return (
    <div className="InstructorPage WorkshopDetail">
      <Helmet>
        <title>{workshop.title ? `${workshop.title} - Workshop` : ``}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="instructor-info">
              <h2 className="name">{workshop.title}</h2>
              <h5 className="title text-uppercase">{workshop.subTitle}</h5>
            </div>
            <div className="socials d-none d-lg-flex">
              {socials.map(({ id, logo, link }) => (
                <a
                  key={id}
                  href={link}
                  target="_blank"
                  className="social"
                  rel="noreferrer"
                >
                  {logo}
                </a>
              ))}
            </div>
            <Link to={workshop.externalLink} target="_blank">
              <HAButton
                className="btn btn-primary"
                onClick={() => createModuleTree()}
              >
                Satın Al
              </HAButton>
            </Link>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end mt-5 md:mt-0">
            <HAInstructor
              avatarUrl={workshop.avatarUrl}
              fullName={workshop.title}
              branch={workshop.subTitle}
              slug={workshop.slug}
              activeCategory={activeCategory}
            />
          </div>
          <div className="col-12 d-flex d-lg-none">
            <div className="socials d-flex">
              {socials.map(({ id, logo, link }) => {
                return (
                  <a
                    key={id}
                    href={link}
                    target="_blank"
                    className="social"
                    rel="noreferrer"
                  >
                    {logo}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        {workshop.videoThumbnail && workshop.playerEmbedUrl && (
          <div className="row">
            <div className="col-12">
              <IntroVideoArea
                cover={workshop.videoThumbnail}
                id={workshop.playerEmbedUrl}
              />
            </div>
          </div>
        )}

        {workshop.description && (
          <div className="row">
            <div className="col-12">
              <h6 className="sec-title">{workshop.title} Hakkında</h6>
              <div className="desc">
                <p dangerouslySetInnerHTML={{ __html: workshop.description }}/>
              </div>
            </div>
          </div>
        )}

        {workshop?.specialties?.length > 0 && (
          <div className="row">
            <div className="col-12">
              <h6 className="sec-title">{workshop.title} Uzmanlıkları</h6>
              <ul className="list">
                {workshop?.specialties?.map((content, i) => (
                  <li
                    key={i}
                    className="d-flex justify-content-start align-items-start"
                  >
                    <p className="mb-2">{content}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkshopDetail;
