import React, { Component } from "react";
import AddPaymentMethod from "./components/AddPaymentMethod";
import { PaymentMethods } from "./components/PaymentMethods";

import masterCard from "../../../assets/images/mastercard-logo.png";
import visa from "../../../assets/images/visa-logo.png";
import { EmptyArea } from "../../components/EmptyArea";
import HAModal from "../../../utils/components/HAModal";
import { CSSTransition } from "react-transition-group";
import { getIconByCategory, handleErrors } from "../../../utils/helper";
import AddNewCC from "../../../Cart/components/AddNewCC";
import API, { headers } from "../../../utils/API";
import { connect } from "react-redux";

class PaymentInfo extends Component {
  state = {
    isAddingPaymentMethod: false,
    hasPaymentMethods: false,
    cards: [],
  };

  paymentModalRef = React.createRef();

  componentDidMount = () => {
    this.handleGetCards();
  };

  componentDidUpdate = () => {
    if (
      `paymentInfo` in this.props &&
      this.props.paymentInfo.number &&
      this.props.paymentInfo.id !== 0 &&
      this.props.paymentInfo.type === 2
    ) {
      this.props.handleUpdatePaymentInfo({
        target: { name: "id", value: 0 },
      });
      this.props.handleUpdatePaymentInfo({
        target: { name: "number", value: "" },
      });
      const cards = [...this.state.cards];

      cards.forEach((card) => {
        card.isDefault = false;
      });

      this.setState({
        cards,
      });
    }
  };

  toggleAddingPaymentMethod = (
    e = null,
    isAddingPaymentMethod = !this.state.isAddingPaymentMethod
  ) => this.setState({ isAddingPaymentMethod });

  setDefaultCard = (cardToken) => {
    const cards = [...this.state.cards];

    cards.forEach((card) => {
      if (card.id === cardToken) card.isDefault = true;
      else card.isDefault = false;
    });

    this.setState({ cards }, () => {
      if (`handleUpdatePaymentInfo` in this.props) {
        const selectedCard = cards.find((card) => card.id === cardToken);
        this.props.handleSetPaymentInfo({
          id: selectedCard.id,
          number: selectedCard.number,
        });
      }
    });
  };

  handleSelectDefault = (cardToken) => {
    if (!(`handleUpdatePaymentInfo` in this.props)) {
      API.post(
        "Payment/SetDefaultCard",
        { cardToken },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${this.props.user.token}`,
          },
        }
      )
        .then(({ data: res }) => {
          const { status, info } = res;
          if (status) {
            this.setDefaultCard(cardToken);
            this.props.showInfoBanner(
              info?.friendlyMessage || "Varsayılan kart güncellendi.",
              "success"
            );
          } else {
            this.props.showInfoBanner(
              info?.friendlyMessage || "Varsayılan kart güncellenemedi.",
              "error"
            );
          }
        })
        .catch((err) => handleErrors(err));
    } else {
      this.setDefaultCard(cardToken);
    }
  };

  handleAddCard = (card) => {
    API.post("Payment/Card", card, {
      headers: { ...headers, Authorization: `Bearer ${this.props.user.token}` },
    })
      .then(({ data: res }) => {
        if (res.status) {
          this.props.showInfoBanner(res.info.friendlyMessage, "success");
          this.handleGetCards();
          this.toggleAddingPaymentMethod();
        } else if (`friendlyMessage` in res?.info)
          this.props.showInfoBanner(res.info.friendlyMessage, "error");
      })
      .catch((err) => handleErrors(err));
  };

  handleGetCards = () => {
    API.get("Payment/Card", {
      headers: { ...headers, Authorization: `Bearer ${this.props.user.token}` },
    })
      .then(({ data: res }) => {
        if (res.status) {
          const cards = res.data.map((card) => ({
            id: card.cardToken,
            number: `${card.binNumber}**********`.match(/.{1,4}/g).join(" "),
            isDefault: card.isDefault,
            issuerLogo:
              card.cardAssociation === "MASTER_CARD"
                ? masterCard
                : card.cardAssociation === "VISA"
                ? visa
                : "",
          }));

          this.setState({ cards, hasPaymentMethods: cards.length > 0 }, () => {
            const defaultCard = this.state.cards.find((card) => card.isDefault);

            if (this.props.handleSetPaymentType)
              if (cards.length > 0) this.props.handleSetPaymentType(1);
              else this.props.handleSetPaymentType(2);

            if (defaultCard && this.props.handleSetPaymentInfo) {
              this.props.handleSetPaymentInfo({
                id: defaultCard.id,
                number: defaultCard.number,
              });
            }
          });
        } else {
          if (`friendlyMessage` in res?.info)
            this.props.showInfoBanner(res.info.friendlyMessage, "error");
        }
      })
      .catch((err) => handleErrors(err));
  };

  handleDeleteCard = (e, cardToken) => {
    e.stopPropagation();

    API.delete(`Payment/Card`, {
      data: { cardToken },
      headers: {
        ...headers,
        Authorization: `Bearer ${this.props.user.token}`,
      },
    })
      .then(({ data: res }) => {
        if (res.status) {
          this.props.showInfoBanner(res.info.friendlyMessage, "success");
          this.handleGetCards();
        } else {
          if (`friendlyMessage` in res?.info)
            this.props.showInfoBanner(res.info.friendlyMessage, "error");
        }
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    return (
      <section className="page-section">
        <div className="row">
          <div className="col">
            <h6 className="section-title">Ödeme Bilgilerim</h6>
          </div>
        </div>
        {`isCart` in this.props && Boolean(this.state.cards.length) && (
          <div className="row">
            <div className="col-12">
              <div className="selection-area d-flex justify-content-start align-items-center">
                <div
                  className="selection d-flex justify-content-center align-items-center cursor--pointer"
                  data-is-selected={this.props.paymentInfo.type === 1}
                  onClick={() => this.props.handleSetPaymentType(1)}
                >
                  Kayıtlı Kartlarım
                </div>
                <div
                  className="selection d-flex justify-content-center align-items-center cursor--pointer"
                  data-is-selected={this.props.paymentInfo.type === 2}
                  onClick={() => this.props.handleSetPaymentType(2)}
                >
                  Yeni Kart Ekle
                </div>
              </div>
            </div>
          </div>
        )}
        {(this.props.newCardArea ||
          (this.props.paymentInfo?.type === 1 &&
            Boolean(this.state.cards.length))) && (
          <div className="row">
            <div className="col-12">
              {this.state.hasPaymentMethods ? (
                <PaymentMethods
                  cards={this.state.cards}
                  handleSelectDefault={this.handleSelectDefault}
                  handleDeleteCard={this.handleDeleteCard}
                  toggleAddingPaymentMethod={this.toggleAddingPaymentMethod}
                  theme={this.props.theme}
                  newCardArea={this.props.newCardArea}
                />
              ) : (
                <EmptyArea
                  icon={
                    getIconByCategory({
                      iconMainKey: "empty-payment-info-icon",
                    })?.["imageUrl"]
                  }
                  content="Henüz bir ödeme yönteminiz yok."
                  buttonText="Ödeme Yöntemi Ekle"
                  buttonLink={
                    `isCart` in this.props
                      ? () => this.props.handleSetPaymentType(2)
                      : this.toggleAddingPaymentMethod
                  }
                />
              )}
            </div>
          </div>
        )}
        {((!this.props.newCardArea && this.props.paymentInfo?.type === 2) ||
          (!this.props.newCardArea && !Boolean(this.state.cards.length))) && (
          <div className="row">
            <div className="col-12">
              <AddNewCC
                paymentInfo={this.props.paymentInfo}
                handleUpdatePaymentInfo={this.props.handleUpdatePaymentInfo}
                theme={this.props.theme}
              />
            </div>
          </div>
        )}
        {this.props.newCardArea && (
          <CSSTransition
            in={this.state.isAddingPaymentMethod}
            timeout={300}
            unmountOnExit
            nodeRef={this.paymentModalRef}
            classNames="HAModal"
          >
            <HAModal
              closeModal={this.toggleAddingPaymentMethod}
              nodeProp={this.paymentModalRef}
            >
              <AddPaymentMethod
                toggleAddingPaymentMethod={this.toggleAddingPaymentMethod}
                handleAddCard={this.handleAddCard}
              />
            </HAModal>
          </CSSTransition>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  theme: app.app.theme,
});

export default connect(mapStateToProps)(PaymentInfo);
