import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { Loader } from "./Loader";

export const HAButton = ({
  type,
  action,
  onClick,
  className,
  children,
  isLink,
  navLink,
  exact,
  disabled,
  isLoading,
  ...rest
}) => {
  return !isLink ? (
    <button
      className={`HAButton ${className}`}
      data-type={type}
      type={action}
      onClick={onClick}
      {...rest}
      disabled={disabled}
    >
      {isLoading ? <Loader type="dots" /> : children}
    </button>
  ) : `target` in rest || !action.startsWith("/") ? (
    <a
      href={action}
      className={`HAButton ${className}`}
      data-type={type}
      onClick={onClick}
      target={rest.target || !action.startsWith("/") ? "_blank" : "_self"}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : navLink ? (
    <NavLink
      to={action}
      className={`HAButton ${className}`}
      data-type={type}
      onClick={onClick}
      exact={exact}
    >
      {isLoading ? <Loader type="dots" /> : children}
    </NavLink>
  ) : (
    <Link
      to={action}
      className={`HAButton ${className}`}
      data-type={type}
      onClick={onClick}
    >
      {isLoading ? <Loader type="dots" /> : children}
    </Link>
  );
};

HAButton.defaultProps = {
  type: "pri",
  action: "button",
  onClick: () => {},
  className: "",
  isLink: false,
  navLink: false,
  exact: true,
  disabled: false,
};

HAButton.propTypes = {
  type: PropTypes.oneOf([
    "pri",
    "sec",
    "alt",
    "pri-empty",
    "sec-empty",
    "alt-empty",
    "gray",
    "gray-empty",
    "only-text",
  ]),
  action: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  isLink: PropTypes.bool,
  navLink: PropTypes.bool,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};
