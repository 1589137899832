import React from "react";
import { Helmet } from "react-helmet-async";
import CoursesIntro from "../Courses/components/CoursesIntro";

import CoursesList from "../components/CoursesList";

import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";

const AdditionalCourses = ({ user }) => {
  const [category, setCategory] = React.useState({
    title: "",
    name: "",
    desc: "",
    video: { cover: "", id: "" },
    courses: [],
  });

  React.useEffect(() => {
    API.get(`Course/GetCoursesBySubCategorySlug/ek-egitimler-117`, {
      headers: user.isLoggedIn
        ? { ...headers, Authorization: `Bearer ${user.token}` }
        : headers,
    })
      .then(({ data: res }) => {
        const { data } = res;

        const category = {
          title: data.title,
          name: data.name,
          desc: data.description,
          videoCover: data.videoThumbnail,
          videoUrl: data.videoUrl,
          courses: data.coursesOfSubCategory.map((course) => ({
            id: course.id,
            slug: course.slug,
            title: course.title,
            name: course.name,
            cover: course.imageUrl,
            courseCount: course.totalLesson,
            courseLength: course.videoLength,
            instructor: {
              id: data.teacher.id,
              slug: data.teacher.slug,
              name: data.teacher.fullName,
            },
          })),
        };

        setCategory(category);
      })
      .catch((err) => handleErrors(err));
  }, [user.isLoggedIn, user.token]);

  return (
    <>
      <Helmet>
        <title>{category?.name}</title>
      </Helmet>
      <div className="CoursesPage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <CoursesIntro
                    title={category?.name || ""}
                    desc={category?.desc || ""}
                    button={null}
                    video={{
                      cover: category?.videoCover || "",
                      id: category?.videoUrl || "",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <CoursesList
                    items={category?.courses || []}
                    course={category?.id || ""}
                    isAdditionalCourse
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalCourses;
