import React from "react";
import { Helmet } from "react-helmet-async";
import { HAInstructor } from "../utils/components/HAInstructor";
import socialMedias from "../utils/db/socialMedias";

import HAItem from "../utils/components/HAItem";
import IntroVideoArea from "../utils/components/IntroVideoArea";
import API from "../utils/API";
import { handleErrors, sendGAEvent } from "../utils/helper";
import { useLocation, useParams } from "react-router";
import useAppContext from "../utils/hooks/useAppContext";
import { HAButton } from "../utils/components/HAButton";
import { Link } from "react-router-dom";

const Instructor = () => {
  const [instructor, setInstructor] = React.useState({
    id: null,
    fullName: null,
    about: null,
    avatarUrl: null,
    branch: null,
    socials: [],
    courses: [],
  });

  const [socials, setSocials] = React.useState([]);
  const location = useLocation();
  const { slug } = useParams();
  const { activeCategory, createModuleTree } = useAppContext();

  React.useEffect(() => {
    API.get(`Teacher/TeacherBySlug/${slug}`)
      .then(({ data: r }) => {
        const { data } = r;

        data["courses"] = data["courses"].map((course) => ({
          ...course,
          slug: course.slug,
          cover: course.imageUrl,
          courseCount: course.totalLesson,
          courseLength: course.videoLength,
          instructor: { slug: data.slug, name: data.fullName },
        }));

        setInstructor(data);

        setTimeout(() => {
          sendGAEvent("page_view", {
            pagePath: location.pathname,
            pageTitle: document.title,
          });
        }, 20);
      })
      .catch((err) => handleErrors(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  React.useEffect(() => {
    const socialPlatforms = socialMedias
      .filter((social) => instructor[social.id + "Url"])
      .map(({ id, logo }) => ({
        id,
        logo,
        link: instructor[id + "Url"].startsWith("http")
          ? instructor[id + "Url"]
          : `https://${instructor[id + "Url"]}`,
      }));

    setSocials(socialPlatforms);
  }, [instructor]);

  return (
    <div className="InstructorPage">
      <Helmet>
        <title>
          {instructor.fullName ? `${instructor.fullName} - Eğitmen` : ``}
        </title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="instructor-info">
              <h2 className="name">{instructor.fullName}</h2>
              <h5 className="title text-uppercase">{instructor.title}</h5>
            </div>
            <div className="socials d-none d-lg-flex">
              {socials.map(({ id, logo, link }) => (
                <a
                  key={id}
                  href={link}
                  target="_blank"
                  className="social"
                  rel="noreferrer"
                >
                  {logo}
                </a>
              ))}
            </div>
            {activeCategory === "kurumsal" && (
              <div className="mt-4">
                <Link to="iletisime-gec">
                  <HAButton
                    className="btn btn-primary"
                    onClick={() => createModuleTree()}
                  >
                    İletişime Geç
                  </HAButton>
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <HAInstructor
              avatarUrl={instructor.avatarUrl}
              fullName={instructor.fullName}
              branch={instructor.branch}
              slug={instructor.slug}
              activeCategory={activeCategory}
            />
          </div>
          <div className="col-12 d-flex d-lg-none">
            <div className="socials d-flex">
              {socials.map(({ id, logo, link }) => {
                return (
                  <a
                    key={id}
                    href={link}
                    target="_blank"
                    className="social"
                    rel="noreferrer"
                  >
                    {logo}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        {activeCategory === "kurumsal" &&
          instructor.videoThumbnail &&
          instructor.playerEmbedUrl && (
            <div className="row">
              <div className="col-12 mt-4">
                <IntroVideoArea
                  cover={instructor.videoThumbnail}
                  id={instructor.playerEmbedUrl}
                />
              </div>
            </div>
          )}

        {instructor.about && (
          <div className="row">
            <div className="col-12">
              <h6 className="sec-title">{instructor.fullName} Hakkında</h6>
              <div className="desc">
                <p dangerouslySetInnerHTML={{ __html: instructor.about }} />
              </div>
            </div>
          </div>
        )}

        {activeCategory === "kurumsal" &&
          instructor?.specialties?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <h6 className="sec-title">
                  {instructor.fullName} Uzmanlıkları
                </h6>
                <ul className="list">
                  {instructor?.specialties?.map((content, i) => (
                    <li
                      key={i}
                      className="d-flex justify-content-start align-items-start"
                    >
                      <p className="mb-2">{content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        {Boolean(instructor.courses.length) && (
          <div className="row">
            <div className="col-12">
              <h6 className="sec-title">Eğitimleri</h6>
              <div className="courses">
                {instructor.courses.map((course) => (
                  <HAItem key={course.id} {...course} isDetail />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Instructor;
