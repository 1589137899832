import React from "react";
import { Link } from "react-router-dom";
import { calculateDurationFromSeconds, getRoute } from "../../../utils/helper";
import useAppContext from "../../../utils/hooks/useAppContext";

const SingleCourseList = ({
  order,
  title,
  duration,
  course,
  slug,
  isActive,
  chapter,
  isAvailableToUseForUser,
  videoUrl,
  ...rest
}) => {
  const { activeCategory } = useAppContext();

  return (
    <div
      className="SingleCourse d-flex flex-column justify-content-center cursor--pointer position-relative"
      data-is-active={isActive}
    >
      <div className="d-flex align-items-center justify-content-between my-auto">
        <div className="left-side d-flex align-items-center justify-content-start">
          <div className="number">
            {parseInt(order) < 10 && 0}
            {order}
          </div>
          {isAvailableToUseForUser && videoUrl ? (
            <Link
              to={getRoute("course-watching")
                ?.link?.replace(":slug", slug)
                ?.replace(":chapter", chapter)
                ?.replace(":course", course)
                ?.replace(":category", activeCategory)}
              className="stretched-link"
            >
              <h4 className="title m-0">{title}</h4>
            </Link>
          ) : (
            <h4 className="title m-0">{title}</h4>
          )}
        </div>
        <div className="right-side d-flex align-items-center justify-content-start">
          {`isFree` in rest && <div className="watch-free">Ücretsiz İzle</div>}
          <div className="duration d-flex align-items-center justify-content-start">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.3892 16 16 12.3892 16 8C16 3.61083 12.3892 0 8 0C3.61083 0 0 3.61083 0 8C0 12.3892 3.61083 16 8 16ZM8 14.0421C4.65396 14.0421 1.95787 11.346 1.95787 8C1.95787 4.64594 4.64594 1.94985 8 1.94985C11.346 1.94985 14.0421 4.64594 14.0501 8C14.0501 11.346 11.3541 14.0421 8 14.0421ZM8 9.13139C8.4012 9.13139 8.72217 8.81845 8.72217 8.41725V3.667C8.72217 3.2658 8.4012 2.95286 8 2.95286C7.5988 2.95286 7.27783 3.2658 7.27783 3.667V7.69508H4.37312C3.97192 7.69508 3.65898 8.00802 3.65898 8.41725C3.65898 8.81845 3.97192 9.13139 4.37312 9.13139H8Z"
                fill="#303030"
              />
            </svg>
            {calculateDurationFromSeconds(duration)}
          </div>
        </div>
      </div>
      <div className="duration-line">
        <div
          className="watched-duration"
          style={{
            width: `${(rest.userLessonDuration / duration) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SingleCourseList;
