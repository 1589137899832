export const login = (user) => ({
  type: "LOGIN",
  user,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const update = (user) => ({
  type: "UPDATE",
  user,
});

export const set = (user) => ({
  type: "SET",
  user,
});

export const showAuthBox = (authBoxType) => ({
  type: "SHOW_AUTH_BOX",
  authBoxType,
});

export const hideAuthBox = () => ({
  type: "HIDE_AUTH_BOX",
});

export const setCart = (cart) => ({ type: "SET_CART", cart });

export const resetCart = () => ({ type: "RESET_CART" });

export const addToCart = (item) => ({ type: "ADD_TO_CART", item });

export const removeFromCart = (item) => ({ type: "REMOVE_FROM_CART", item });
