import React from "react";
import { Link } from "react-router-dom";
import { HAButton } from "../../../utils/components/HAButton";
import IntroVideoArea from "../../../utils/components/IntroVideoArea";
import { calculateDurationFromSeconds, getRoute } from "../../../utils/helper";
import moment from "moment";

import useAppContext from "../../../utils/hooks/useAppContext";

const CourseIntro = ({
  title,
  duration,
  instructor,
  desc,
  video,
  lastDuration,
  isAvailableToUseForUser,
  startDate,
  courseSlug,
  firstVideo,
}) => {
  const { activeCategory } = useAppContext();

  return (
    <section className="CoursesIntro">
      <div className="row flex-column-reverse flex-lg-row align-items-lg-center">
        <div className="col-12 col-lg-6">
          <div className="course-info">
            <div className="row d-none d-lg-flex">
              <div className="col-12">
                <h1 className="course-title">{title}</h1>
              </div>
            </div>
            <div className="row d-none d-lg-flex">
              <div className="col-12">
                <h3 className="course-instructor">
                  <Link
                    to={getRoute("instructor")?.link?.replace(
                      ":slug",
                      instructor.slug
                    )}
                  >
                    {instructor.name}
                  </Link>
                </h3>
              </div>
            </div>
            <div className="row align-items-center align-items-lg-start">
              <div className="col-6 col-lg-12">
                <div className="durations d-flex justify-content-start align-items center">
                  <span className="duration cursor--default">
                    {duration.chapters} Bölüm
                  </span>
                  <span className="duration cursor--default">
                    {duration.courses} Ders
                  </span>
                </div>
              </div>
            </div>
            {!lastDuration && (
              <div className="row">
                <div className="col-12 d-flex justify-content-start">
                  {!isAvailableToUseForUser ? (
                    <Link
                      to={getRoute(
                        activeCategory === "kurumsal"
                          ? "contact-courses"
                          : "subscription"
                      )
                        ?.link.replace(":category", activeCategory)
                        ?.replace(":page", "egitim")
                        ?.replace(":slug?", courseSlug)}
                    >
                      <HAButton>
                        {activeCategory === "kurumsal"
                          ? "İletişime Geç"
                          : "Satın Al"}
                      </HAButton>
                    </Link>
                  ) : moment(startDate).isAfter(moment()) ? (
                    <span className="mt-3">
                      {moment(startDate).format("D MMMM YYYY")}'de eğitimlere
                      erişiminiz açılacaktır.
                    </span>
                  ) : (
                    <HAButton
                      isLink
                      action={getRoute("course-watching")
                        ?.link.replace(":category", activeCategory)
                        ?.replace(":course", courseSlug)
                        ?.replace(":chapter", firstVideo?.courseSectionSlug)
                        ?.replace(":slug", firstVideo?.slug)}
                      className="mb-3 mb-lg-0"
                    >
                      Eğitime Başla
                    </HAButton>
                  )}
                </div>
              </div>
            )}
            {lastDuration && (
              <div className="row">
                <div className="col-12">
                  {lastDuration.isCompleted && (
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="m-0 d-flex align-items-center justify-content-start">
                          <svg
                            className="icon-theming mr-1"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.6513 24C19.2114 24 24.6451 18.5663 24.6451 11.9938C24.6451 5.43373 19.199 0 12.639 0C6.07887 0 0.645142 5.43373 0.645142 11.9938C0.645142 18.5663 6.09125 24 12.6513 24ZM11.0423 18.0464C10.6338 18.0464 10.2996 17.8608 9.96541 17.4523L6.59873 13.3058C6.40069 13.083 6.30167 12.7983 6.30167 12.526C6.30167 11.9567 6.74726 11.5111 7.30424 11.5111C7.63844 11.5111 7.9355 11.6349 8.22018 12.0062L10.9927 15.5709L16.377 6.94379C16.6245 6.56008 16.934 6.37442 17.2681 6.37442C17.8004 6.37442 18.3202 6.73337 18.3202 7.31511C18.3202 7.58742 18.1717 7.8721 18.0232 8.11965L12.0696 17.4399C11.8097 17.8484 11.4631 18.0464 11.0423 18.0464Z"
                            />
                          </svg>

                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.6513 24C19.2114 24 24.6451 18.5663 24.6451 11.9938C24.6451 5.43373 19.199 0 12.639 0C6.07887 0 0.645142 5.43373 0.645142 11.9938C0.645142 18.5663 6.09125 24 12.6513 24ZM11.0423 18.0464C10.6338 18.0464 10.2996 17.8608 9.96541 17.4523L6.59873 13.3058C6.40069 13.083 6.30167 12.7983 6.30167 12.526C6.30167 11.9567 6.74726 11.5111 7.30424 11.5111C7.63844 11.5111 7.9355 11.6349 8.22018 12.0062L10.9927 15.5709L16.377 6.94379C16.6245 6.56008 16.934 6.37442 17.2681 6.37442C17.8004 6.37442 18.3202 6.73337 18.3202 7.31511C18.3202 7.58742 18.1717 7.8721 18.0232 8.11965L12.0696 17.4399C11.8097 17.8484 11.4631 18.0464 11.0423 18.0464Z"
                              fill="#B6E808"
                            />
                          </svg>

                          <span>
                            Tebrikler bu eğitimi başarıyla tamamladın!
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {lastDuration.totalUserWatchTime !== 0 && (
                      <div className="col-12">
                        <div className="duration-line d-flex justify-content-start align-items-center">
                          <div
                            className="watched-duration"
                            style={{
                              width: `${
                                (lastDuration.totalUserWatchTime /
                                  lastDuration.totalDurationOfCourse) *
                                100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <p className="watched-duration-text">
                          Toplam izlenen süre:{" "}
                          {calculateDurationFromSeconds(
                            lastDuration.totalUserWatchTime
                          )}
                        </p>
                      </div>
                    )}
                    <div className="col-12 d-flex justify-content-start">
                      {lastDuration.totalUserWatchTime === 0 ? (
                        firstVideo?.videoUrl ? (
                          <HAButton
                            isLink
                            action={getRoute("course-watching")
                              ?.link.replace(":category", activeCategory)
                              ?.replace(":course", lastDuration.courseSlug)
                              ?.replace(
                                ":chapter",
                                firstVideo?.courseSectionSlug
                              )
                              ?.replace(":slug", firstVideo?.slug)}
                            className="mb-3 mb-lg-0"
                          >
                            Eğitime Başla
                          </HAButton>
                        ) : (
                          ``
                        )
                      ) : lastDuration.totalUserWatchTime <
                        lastDuration.totalDurationOfCourse ? (
                        <HAButton
                          isLink
                          action={getRoute("course-watching")
                            ?.link.replace(":category", activeCategory)
                            ?.replace(":course", lastDuration.courseSlug)
                            ?.replace(
                              ":chapter",
                              lastDuration.activeSectionSlug
                            )
                            ?.replace(":slug", lastDuration.activeLessonSlug)}
                          className="mb-3 mb-lg-0"
                        >
                          Kaldığın Yerden Devam Et
                        </HAButton>
                      ) : firstVideo?.videoUrl ? (
                        <HAButton
                          isLink
                          action={getRoute("course-watching")
                            ?.link.replace(":category", activeCategory)
                            ?.replace(":course", lastDuration.courseSlug)
                            ?.replace(":chapter", firstVideo?.courseSectionSlug)
                            ?.replace(":slug", firstVideo?.slug)}
                          className="mb-3 mb-lg-0"
                        >
                          Eğitimi Yeniden İzle
                        </HAButton>
                      ) : (
                        ``
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!lastDuration && video.id && video.cover && (
          <div className="col-12 col-lg-6">
            <IntroVideoArea id={video.id} cover={video.cover} />
          </div>
        )}
        {lastDuration && video.cover && (
          <div className="col-12 col-lg-6">
            <div className="video-placeholder position-relative">
              <div className="play-button position-absolute w-100 h-100 d-flex justify-content-center align-items-center cursor--pointer">
                <img
                  src={video.cover}
                  alt="Video Placeholder"
                  className="position-absolute w-100 h-100"
                />
                <Link
                  to={getRoute("course-watching")
                    ?.link.replace(":category", activeCategory)
                    ?.replace(":course", lastDuration.courseSlug)
                    ?.replace(":chapter", lastDuration.activeSectionSlug)
                    ?.replace(":slug", lastDuration.activeLessonSlug)}
                  className="stretched-link"
                >
                  <svg
                    width="67"
                    height="67"
                    viewBox="0 0 67 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="33.5"
                      cy="33.5"
                      r="30.5"
                      stroke="white"
                      strokeWidth="6"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M42.1451 31.6595C43.3524 32.4489 43.3524 34.218 42.1451 35.0074L29.7997 43.0793C28.4695 43.9491 26.7052 42.9947 26.7052 41.4054L26.7052 25.2615C26.7052 23.6722 28.4695 22.7178 29.7997 23.5875L42.1451 31.6595Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <h6 className="course-about-title">Eğitim Hakkında</h6>
          <p
            className="course-about-text"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        </div>
      </div>
    </section>
  );
};

export default CourseIntro;
