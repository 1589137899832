import React from "react";
import Items from "./Items";
import Sidebar from "./Sidebar";
import API, { headers } from "../../utils/API";
import { useNavigate } from "react-router";
import { showInfoBanner } from "../../App/appActions";
import {
  getRoute,
  getUserCart,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import { connect } from "react-redux";
import { resetCart, setCart } from "../../Auth/authActions";
import { createSearchParams } from "react-router-dom";

const FilledCart = ({ user, setCart }) => {
  const navigate = useNavigate();

  const handleCompleteShopping = () => {
    API.post(
      "Payment/MakePayment",
      {},
      {
        headers: { ...headers, Authorization: `Bearer ${user.token}` },
      }
    )
      .then(({ data: r }) => {
        if (r.status) {
          navigate({
            pathname: getRoute("payment-complete")?.link,
            search: createSearchParams({
              order: r.data,
            }).toString(),
          });

          sendGAEvent("event", "purchase", {
            transaction_id: r.data, //TODO: get from response
            currency: "TRY",
            value: user?.cart?.totalPrice,
            items: [
              ...user?.cart?.items.map((item) => ({
                item_id: item.id,
                item_name: item.title,
              })),
            ],
          });

          sendMetaEvent("track", "Purchase", {
            value: user?.cart?.totalPrice,
            currency: "TRY",
            content_ids: [...user?.cart?.items.map((item) => item.id)],
            content_type: "product",
          });

          resetCart();

          setTimeout(() => {
            getUserCart(user, setCart);
            navigate(getRoute("payment-complete")?.link);
          }, 100);
        } else {
          if (`friendlyMessage` in r.info)
            showInfoBanner(r.info.friendlyMessage, "error");
        }
      })
      .catch((err) => {
        handleErrors(err);
      });
  };

  return (
    <div className="filled-cart">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-8">
            <Items items={user?.cart?.items} user={user} />
          </div>
          <div className="col col-md-4">
            <Sidebar
              type={1}
              handleCompleteShoppingWithCoupon={handleCompleteShopping}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
  setCart: (cart) => dispatch(setCart(cart)),
});

export default connect(null, mapDispatchToProps)(FilledCart);
