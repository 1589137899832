import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SITE_URL = process.env.REACT_APP_SITE_URL;

const CorporateTestimonialCard = ({ testimonial }) => {
  const { title, description, imageUrl, imageAlt, link } = testimonial;

  const [linkIsExternal, setLinkIsExternal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    if (link.startsWith(SITE_URL)) {
      setLinkIsExternal(false);
      setRedirectUrl(link.replace(SITE_URL, ""));
    } else {
      const redirectLink = link.startsWith("http") ? link : `https://${link}`;
      setLinkIsExternal(true);
      setRedirectUrl(redirectLink);
    }
  }, [link]);

  return (
    <div className="CorporateTestimonialCard d-flex flex-column justify-content-between align-items-center">
      <div className="testimonial-card">
        <div className="card-overlay"></div>
        <img src={imageUrl} alt={imageAlt} className="w-100 object-cover" />
      </div>
      <div className="card-text d-flex flex-column gap-2 text-white pe-5">
        {!linkIsExternal ? (
          <Link to={redirectUrl}>
            <h6 className="card-title">{title}</h6>
            <small
              className="card-subtitle"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Link>
        ) : (
          <a
            href={redirectUrl}
            className="stretched-link"
            target="_blank"
            rel="noreferrer"
          >
            <h6 className="card-title">{title}</h6>
            <small
              className="card-subtitle"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </a>
        )}
      </div>

      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.04827 13.4C5.53735 13.4 5.16429 13.0286 5.16429 12.5252C5.16429 12.2611 5.26161 12.0383 5.43192 11.8732L7.25666 9.99159L9.60855 7.80462L7.71893 7.92016H2.57515C2.03989 7.92016 1.66683 7.54053 1.66683 7.0041C1.66683 6.45942 2.03989 6.07979 2.57515 6.07979H7.71893L9.60044 6.19533L7.25666 4.00836L5.43192 2.12673C5.26161 1.96168 5.16429 1.73885 5.16429 1.47477C5.16429 0.971349 5.53735 0.599976 6.04827 0.599976C6.28346 0.599976 6.50243 0.690756 6.71329 0.897074L12.0415 6.31912C12.2281 6.50068 12.3335 6.74827 12.3335 7.0041C12.3335 7.25168 12.2281 7.49927 12.0415 7.68083L6.68896 13.1194C6.49432 13.3092 6.28346 13.4 6.04827 13.4Z"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default CorporateTestimonialCard;
