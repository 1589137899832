import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API, { headers } from "../../utils/API";
import { HAButton } from "../../utils/components/HAButton";
import HACheckbox from "../../utils/components/HACheckbox";
import {
  calculateCartTotal,
  formatCurrency,
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import { connect } from "react-redux";

import { setCart } from "../../Auth/authActions";
import { Loader } from "../../utils/components/Loader";
import { showInfoBanner } from "../../App/appActions";

const Sidebar = ({
  applyCode,
  type,
  isButtonActive,
  agreement,
  setAgreement,
  user,
  cart,
  setCart,
  handlePay,
  isPaying,
  showInfoBanner,
  paymentInfo,
  setPaymentInfo,
  resetCart,
  handleCompleteShoppingWithCoupon,
  ...rest
}) => {
  const [code, setCode] = useState(user.cart?.coupon?.code || "");
  const [isApplyingCode, setIsApplyingCode] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [installations, setInstallations] = useState([]);
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [parsedInstallationNumber, setParsedInstallationNumber] = useState("");
  const [isParsingInstallations, setIsParsingInstallations] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user?.cart?.coupon && code) setCode("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.cart.coupon]);

  React.useEffect(() => {
    if (isParsingInstallations) return false;

    let number = paymentInfo?.number;

    if (
      (!number ||
        !parseInt(number) ||
        number.replace(/\D/g, "").length < 6 ||
        (paymentInfo.type === 1 && paymentInfo.id === 0)) &&
      installations.length &&
      parsedInstallationNumber
    ) {
      setInstallations([]);
      setSelectedInstallation(null);
      setParsedInstallationNumber("");
      setIsParsingInstallations(false);
      return;
    }

    if (
      number &&
      parseInt(number) &&
      number.replace(/\D/g, "").length >= 6 &&
      parseInt(number.replace(/\D/g, "").substr(0, 6)) !==
        parsedInstallationNumber
    ) {
      handleGetInstallations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo]);

  React.useEffect(() => {
    if (selectedInstallation) {
      setPaymentInfo({
        ...paymentInfo,
        installment: selectedInstallation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstallation]);

  const handleApplyCode = (e) => {
    e.preventDefault();

    if (code) {
      setIsApplyingCode(true);
      setCodeError(null);

      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      const url = user?.cart?.coupon
        ? `Subscription/removeCouponCodeToCart?code=${code}`
        : `Subscription/addCouponCodeToCart?code=${code}`;

      API.post(
        url,
        { code },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then(({ data: r }) => {
          if (r.status) {
            const cart = { ...r.data };
            const { totalPrice, discount, amountToBePaid } =
              calculateCartTotal(cart);

            setCart({ ...cart, totalPrice, discount, amountToBePaid });
          } else {
            if (`friendlyMessage` in r.info)
              setCodeError(r.info.friendlyMessage);
          }

          setIsApplyingCode(false);
        })
        .catch((err) => {
          handleErrors(err);
          setIsApplyingCode(false);
        });
    }
  };

  const handleGetInstallations = () => {
    setIsParsingInstallations(true);
    const number = parseInt(paymentInfo.number.replace(/\D/g, "").substr(0, 6));
    API.post(
      "Payment/CheckInstallment",
      {
        cardBinNumber: String(number),
        price: String(user.cart.totalPrice - user.cart.discount),
      },
      {
        headers: { ...headers, Authorization: `Bearer ${user.token}` },
      }
    )
      .then(({ data: r }) => {
        if (r.status) {
          const installations = r.data.installmentPrices.map((i) => ({
            price: parseFloat(i.price).toFixed(2),
            totalPrice: parseFloat(i.totalPrice).toFixed(2),
            installmentNumber: i.installmentNumber,
          }));

          setInstallations(installations);
          setParsedInstallationNumber(number);
          setPaymentInfo({ ...paymentInfo, installment: installations[0] });
        }

        setIsParsingInstallations(false);
      })
      .catch((err) => {
        setIsParsingInstallations(false);
        handleErrors(err);
      });
  };

  const completeShopping = (e) => {
    if (!user.isActivated) {
      e.preventDefault();
      showInfoBanner(
        "Devam etmeden önce lütfen e-posta adresinizi onaylayın.",
        "error"
      );
    }

    const cartPrice = user.cart.totalPrice - user.cart.discount;
    const cartIsFree = cart && cartPrice === 0;
    if (!cartIsFree) navigate(getRoute("payment")?.link);
    else handleCompleteShoppingWithCoupon();
  };

  return (
    <div className="cart-sidebar position-sticky">
      <div className="row">
        <div className="col">
          {type === 1 ? (
            <div className="voucher-code-area d-flex flex-column align-items-start position-relative">
              <h6 className="title">HEDİYE ÇEKİ</h6>
              <span className="desc">
                Hediye çeki kodunuz var ise lütfen giriniz
              </span>
              <form
                action="#!"
                onSubmit={(e) => handleApplyCode(e, code)}
                className="input-area d-flex justify-content-start align-items-center"
              >
                <input
                  type="text"
                  name="code"
                  value={code}
                  onChange={({ target }) => setCode(target.value.toUpperCase())}
                  placeholder="Çek Kodunu Giriniz"
                  disabled={user?.cart?.coupon}
                />
                <button type="submit">
                  {user?.cart?.coupon
                    ? isApplyingCode
                      ? `Kaldırılıyor`
                      : `Kaldır`
                    : isApplyingCode
                    ? `Uygulanıyor`
                    : `Uygula`}
                </button>
              </form>
              {user?.cart?.coupon && (
                <span className="code-applied position-absolute d-flex align-items-center">
                  <svg
                    className="icon-theming checkmark"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.00413 16C12.3775 16 16 12.3775 16 7.99587C16 3.62249 12.3693 0 7.99587 0C3.62249 0 0 3.62249 0 7.99587C0 12.3775 3.63074 16 8.00413 16ZM6.93141 12.0309C6.6591 12.0309 6.43631 11.9072 6.21351 11.6349L3.96906 8.87055C3.83703 8.72202 3.77102 8.53223 3.77102 8.3507C3.77102 7.97112 4.06808 7.67406 4.4394 7.67406C4.6622 7.67406 4.86024 7.75658 5.05003 8.00413L6.8984 10.3806L10.4879 4.62919C10.6529 4.37339 10.8592 4.24961 11.082 4.24961C11.4368 4.24961 11.7834 4.48891 11.7834 4.87674C11.7834 5.05828 11.6844 5.24807 11.5854 5.4131L7.6163 11.6266C7.44301 11.8989 7.21197 12.0309 6.93141 12.0309Z"
                    />
                  </svg>
                  {code} kupon kodu başarıyla uygulandı.
                </span>
              )}
              {codeError && (
                <span className="code-error position-absolute d-flex align-items-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.8 8.8H7.2V4H8.8V8.8ZM8.8 12H7.2V10.4H8.8V12ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C15.9952 12.4163 12.4163 15.9952 8 16C3.58172 16 0 12.4183 0 8Z"
                      fill="#FF2929"
                    />
                  </svg>{" "}
                  {codeError}
                </span>
              )}
            </div>
          ) : (
            <h5 className="sidebar-title">Ödemeyi Gerçekleştir</h5>
          )}
        </div>
      </div>
      {type === 2 && (
        <div className="row">
          <div className="col">
            <ul className="installations-list">
              {installations.map((installation, index) => (
                <li key={index} className="installation-option">
                  <HACheckbox
                    name="installation"
                    onChange={() =>
                      setPaymentInfo({
                        ...paymentInfo,
                        installment: installation,
                      })
                    }
                    checked={
                      installation.installmentNumber ===
                      paymentInfo?.installment?.installmentNumber
                    }
                  >
                    {installation.installmentNumber === 1 ? (
                      `Tek Çekim`
                    ) : (
                      <>
                        <span className="installation-name">
                          {installation.installmentNumber} taksit
                        </span>
                        <span className="installation-price ml-2">
                          ({installation.installmentNumber} x{" "}
                          {formatCurrency(installation.price)}₺)
                        </span>
                      </>
                    )}
                  </HACheckbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <div className="price-area d-flex flex-column align-items-start">
            {user.cart.discount > 0 && (
              <div className="discount-area d-flex align-items-center text-uppercase">
                İndirim tutarı: {formatCurrency(user.cart.discount)} ₺
              </div>
            )}
            <div className="price d-flex justify-content-start align-items-end">
              {user.cart.discount > 0 && (
                <span className="old-price">
                  {formatCurrency(user.cart.totalPrice)} ₺
                </span>
              )}
              {paymentInfo?.installment
                ? formatCurrency(paymentInfo.installment.totalPrice)
                : formatCurrency(user.cart.totalPrice - user.cart.discount)}
              <svg
                className="try-sign"
                width="18"
                height="24"
                viewBox="0 0 18 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.30956 24C13.6844 24 18 18.4512 18 13.8721C18 12.7946 17.3445 12.0943 16.3612 12.0943C15.4871 12.0943 14.8862 12.6061 14.695 13.7912C14.2307 17.3468 11.39 20.5791 7.32018 20.5791V12.633L12.6464 10.4512C13.2473 10.1818 13.4932 9.83165 13.4932 9.26599C13.4932 8.56566 13.0288 8.05387 12.2914 8.05387C12.1002 8.05387 11.8543 8.10774 11.6358 8.18855L7.32018 9.96633V7.59596L12.6464 5.41414C13.2473 5.17172 13.4932 4.79461 13.4932 4.22896C13.4932 3.52862 13.0288 3.01683 12.2914 3.01683C12.1002 3.01683 11.8543 3.07071 11.6358 3.17845L7.32018 4.95623V1.80471C7.32018 0.700337 6.63733 0 5.62671 0C4.61609 0 3.93323 0.700337 3.93323 1.80471V6.30303L0.874052 7.56902C0.273141 7.81145 0 8.18855 0 8.75421C0 9.45455 0.491654 9.96633 1.22914 9.96633C1.42033 9.96633 1.63885 9.91246 1.88467 9.80471L3.93323 8.94276V11.3401L0.874052 12.6061C0.273141 12.8485 0 13.1987 0 13.7643C0 14.4916 0.491654 14.9764 1.22914 14.9764C1.42033 14.9764 1.63885 14.9226 1.88467 14.8418L3.93323 13.9798V21.8182C3.93323 23.0842 4.72534 24 6.30956 24Z"
                  fill="#081720"
                />
              </svg>
            </div>
            <span className="desc">Toplam ödenecek tutar</span>
          </div>
        </div>
      </div>
      {type === 2 ? (
        <div className="row">
          <div className="col">
            <HACheckbox
              name="agreement"
              checked={agreement}
              onChange={setAgreement}
            >
              <Link
                to={getRoute("agreement-page")?.link?.replace(
                  ":name",
                  "mesafeli-satis-sozlesmesi"
                )}
                target="_blank"
              >
                Mesafeli Satış Sözleşmesi
              </Link>
              ’ni okudum ve onaylıyorum.
            </HACheckbox>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="col">
          {type === 1 ? (
            <HAButton className="w-100" onClick={(e) => completeShopping(e)}>
              Alışverişi Tamamla
            </HAButton>
          ) : (
            <HAButton
              className="w-100"
              onClick={handlePay}
              disabled={!isButtonActive || isPaying}
            >
              {isPaying ? <Loader /> : `Ödeme Yap`}
            </HAButton>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cart: state.auth.user.cart,
});

const mapDispatchToProps = (dispatch) => ({
  setCart: (cart) => dispatch(setCart(cart)),
  showInfoBanner: (message, type, timeout) =>
    dispatch(showInfoBanner(message, type, timeout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
