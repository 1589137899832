import React from "react";
import { HAButton } from "../../utils/components/HAButton";

const DeleteAccountModal = ({ closeModal, deleteAccount }) => {
  return (
    <div className="d-flex flex-column align-items-center gap-2 info-modal-container">
      <h2 className="title py-3">Uyarı</h2>

      <div className="d-flex flex-column gap-2">
        <p>Hesabınız silinecektir. Onalıyor musunuz?</p>
        <p>
          Tüm bilgileriniz silinecek ve satın aldığınız paketlere erişiminiz
          durdurulacaktır.
        </p>
        <p className="text-danger">Bu işlem geri alınamaz.</p>
      </div>

      <div className="d-flex flex-column w-100 mt-2">
        <HAButton type="pri" className="w-100" onClick={closeModal}>
          Hayır
        </HAButton>

        <HAButton
          type="only-text"
          onClick={deleteAccount}
          className="w-100 mt-2"
        >
          Evet, Hesabımı Sil
        </HAButton>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
