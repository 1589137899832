import React, { useEffect, useState } from "react";
import API, { headers } from "../../utils/API";
import { HAButton } from "../../utils/components/HAButton";
import {
  calculateCartTotal,
  formatCurrency,
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import Subscription from "./Subscription";

import axios from "axios";
import { connect } from "react-redux";
import { showInfoBanner } from "../../App/appActions";
import { setCart, showAuthBox } from "../../Auth/authActions";
import { useLocation, useNavigate } from "react-router";

const Subscriptions = ({
  user,
  activeRoute,
  setCart,
  showInfoBanner,
  showAuthBox,
}) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [description, setDescription] = useState("");

  const cancelSource = axios.CancelToken.source();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const checkHeights = () => {
    const els = document.querySelectorAll(".subs-model-col .list");

    if (els?.length) {
      let max = 0;

      els.forEach((el) => {
        const { offsetHeight } = el;
        max = offsetHeight > max ? offsetHeight : max;
      });

      els.forEach((el) => {
        if (window.innerWidth < 768) el.style.height = `auto`;
        else el.style.height = `${max}px`;
      });
    }
  };

  const handleAddToCart = (item) => {
    if (user.isLoggedIn) {
      const url =
        item.itemType === 1
          ? `Subscription/addCourseToCart?courseId=${item.id}`
          : `Subscription/addPackageToCart?packageId=${item.id}`;

      API.post(
        url,
        {},
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then(({ data: r }) => {
          const { data, status, info } = r;

          if (status) {
            const cart = { ...user.cart };

            cart.items = data.items.map((item) => ({
              cartId: item.id,
              id: item.itemId,
              title: item.title,
              type: item.itemType === 0 ? 2 : 1,
              instructor: item.teacherName,
              image: item.imageUrl,
              price: item.price,
              status: item.purchaseStatus,
            }));

            const { totalPrice, discount, amountToBePaid } =
              calculateCartTotal(cart);

            setCart({ ...cart, totalPrice, discount, amountToBePaid });

            showInfoBanner(
              `${item.title} paketi sepete eklendi.`,
              "success",
              1200
            );

            const addedSubscription = cart.items.find(
              (sub) => sub.id === item.id
            );

            if (addedSubscription?.packageType === 1) {
              navigate(getRoute("cart")?.link);
            }

            sendGAEvent("event", "add_to_cart", {
              value: addedSubscription.price,
              currency: "TRY",
              items: [
                ...cart?.items.map((item) => ({
                  item_id: item.id,
                  item_name: item.title,
                })),
              ],
            });

            sendMetaEvent("track", "AddToCart", {
              value: addedSubscription.price,
              currency: "TRY",
              content_ids: [...cart?.items.map((item) => item.id)],
              content_type: "product",
            });
          } else {
            if (info && `friendlyMessage` in info)
              showInfoBanner(info?.friendlyMessage, "error", 1200);
          }
        })
        .catch((err) => handleErrors(err));
    } else {
      showAuthBox("register");
    }
  };

  const handleGetSubscriptions = () => {
    API.get("SubscriptionPackages/SubscripionPackages")
      .then(({ data: res }) => {
        const { data } = res;

        const currentPlan = data?.packages?.find((sub) => sub.isPurchased);

        setDescription(data.description);

        const subscriptions = data.packages.map((sub) => ({
          id: sub.id,
          title: sub.name,
          price: `${formatCurrency(
            sub.price
          )}<span class="currency-sign">₺</span>`,
          list: sub.description,
          button:
            !currentPlan || sub.packageType !== 1 ? (
              <HAButton
                type="pri"
                className="w-100"
                onClick={() => handleAddToCart({ id: sub.id, title: sub.name })}
              >
                {sub.packageType === 1 ? `Satın Al` : `Abone Ol`}
              </HAButton>
            ) : (
              currentPlan?.id === sub.id && (
                <HAButton type="pri" className="w-100" onClick={() => false}>
                  Mevcut Planım
                </HAButton>
              )
            ),
          packageType: sub.packageType,
          isCurrentPlan: currentPlan?.id === sub.id,
        }));

        setSubscriptions(subscriptions);
        checkHeights();
        window.addEventListener("resize", checkHeights, false);
      })
      .catch((err) => handleErrors(err));
  };



  useEffect(() => {
    handleGetSubscriptions();

    return () => {
      document.removeEventListener("resize", checkHeights, false);
      cancelSource.cancel("Operation canceled by the user.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeRoute, pathname]);

  return  (
    <div className="Subscriptions">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="sec-title">Fiyatlandırma</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <p
              className="sec-desc text-center mb-2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        {
          subscriptions.length > 0 && <div className="row">
            <div className="col-12 packages">
              {subscriptions.map((sub) => (
                <Subscription key={sub.id} {...sub} />
              ))}
            </div>
          </div>
        }

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  activeRoute: state.app.app.activeRoute,
});

const mapDispatchToProps = (dispatch) => ({
  setCart: (cart) => dispatch(setCart(cart)),
  showInfoBanner: (message, type, duration) =>
    dispatch(showInfoBanner(message, type, duration)),
  showAuthBox: (type) => dispatch(showAuthBox(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
