import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import InView from "react-intersection-observer";
import { HAButton } from "../../utils/components/HAButton";
import IntroVideoArea from "../../utils/components/IntroVideoArea";
import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import { connect } from "react-redux";
import { useLocation } from "react-router";

const HowItWorks = ({ activeRoute, user }) => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [video, setVideo] = useState("");
  const [cover, setCover] = useState("");
  const [numbers, setNumbers] = useState([]);
  const [button, setButton] = useState(null);

  const cancelSource = axios.CancelToken.source();
  const { pathname } = useLocation();

  const getModuleData = () => {
    API.get("ModuleData")
      .then(({ data: res }) => {
        const {
          title,
          description: desc,
          videoUrl,
          imageUrl: cover,
          statistics,
        } = res.data;

        const video = videoUrl.split("vimeo.com/")[1];

        const numbers = statistics
          .splice(0, 2)
          .map(({ id, imageUrl: icon, text: desc, value: number }) => ({
            id,
            icon,
            desc,
            number,
          }));

        setTitle(title);
        setDesc(desc);
        setVideo(video);
        setCover(cover);
        setNumbers(numbers);
      })
      .catch((err) => handleErrors(err));
  };

  const getConfigsBySection = () => {
    API.get("SiteSocialConfig/GetConfigsBySection?configSection=3", {
      headers: { ...headers },
    })
      .then(({ data: res }) => {
        const { configItems } = res.data;

        const config = configItems.find(
          ({ configName }) =>
            configName.trim().toLowerCase() === "how to work button"
        );

        if (config) {
          if (!config.configValue || !config.customName) return false;

          const button = {
            title: config.customName,
            link: "/" + activeRoute + config.configValue,
          };

          setButton(button);
        }
      })
      .catch((err) => handleErrors(err));
  };

  useEffect(() => {
    getModuleData();
    getConfigsBySection();

    return () => cancelSource.cancel("Operation canceled by the user.");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoute, user, pathname]);


  return (
    <section className="HowItWorks">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 d-flex flex-column align-items-start">
                <h5 className="sec-title">{title}</h5>
                <p className="sec-desc">{desc}</p>
              </div>
            </div>
            <div className="row info-row">
              {numbers &&
                numbers.length > 0 &&
                numbers.map((number) => (
                  <div
                    className="col-6 col-sm-4 col-md-3 col-lg-6"
                    key={number.id}
                  >
                    <div className="number-area d-flex justify-content-start align-items-center cursor--default">
                      <div className="icon">
                        <img
                          src={number.icon}
                          alt={`${number.desc} İkonu`}
                          className="w-100"
                        />
                      </div>
                      <div className="texts d-flex flex-column align-items-start">
                        <div className="top">
                          <CountUp
                            end={number.number}
                            redraw={true}
                            suffix={number.suffix}
                          >
                            {({ countUpRef, start }) => (
                              <InView
                                as="span"
                                onChange={start}
                                triggerOnce={true}
                              >
                                <span ref={countUpRef} />
                              </InView>
                            )}
                          </CountUp>
                        </div>
                        <div className="bot">{number.desc}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {button && (
              <div className="row">
                <div className="col-12 d-flex justify-content-start">
                  <HAButton isLink={true} action={button.link}>
                    {button.title}
                  </HAButton>
                </div>
              </div>
            )}
          </div>
          {video && (
            <div className="col-12 col-lg-6">
              <IntroVideoArea
                id={video}
                cover={cover}
                icon={
                  <svg
                    width="68"
                    height="67"
                    viewBox="0 0 68 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="34"
                      cy="33.5"
                      r="30.5"
                      stroke="white"
                      strokeWidth="6"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M42.6449 31.6596C43.8523 32.449 43.8523 34.218 42.6449 35.0074L30.2996 43.0794C28.9694 43.9492 27.2051 42.9948 27.2051 41.4055L27.2051 25.2615C27.2051 23.6722 28.9694 22.7178 30.2996 23.5876L42.6449 31.6596Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(HowItWorks);
