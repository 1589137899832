import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import { getCurrentRouteName, getRoute } from "../utils/helper";
import InstructorsList from "./components/InstructorsList";

const Instructors = () => {
  const [routeName, setRouteName] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const name = getCurrentRouteName(location.pathname);

    if (name) setRouteName(name);
    else navigate(getRoute("explore")?.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="InstructorsPage">
      <Helmet>
        <title>{routeName}</title>
      </Helmet>
      <div className="container-fluid">
        {/* uncomment to show tabs */}
        {/* <div className="row">
          <div className="col-12">
            <InstructorsNav />
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <InstructorsList type={routeName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructors;
