import React, { Component } from "react";
import { Auth } from "../Auth";
import RegisterBox from "../components/RegisterBox";

export default class Register extends Component {
  render() {
    return (
      <Auth prevLocation={this.props.prevLocation}>
        <RegisterBox />
      </Auth>
    );
  }
}
