import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import API, { headers } from "../../utils/API";
import { useLocation, useNavigate, useParams } from "react-router";
import { handleErrors } from "../../utils/helper";
import { auth } from "../../App/routes.v2";
import useAppContext from "../../utils/hooks/useAppContext";
import { Link } from "react-router-dom";
import { HAButton } from "../../utils/components/HAButton";
import Vimeo from "@u-wave/react-vimeo";
import Chapters from "./components/Chapters";
import { useInterval } from "../../utils/hooks/useInterval";

const Watching = ({ user, showInfoBanner }) => {
  const [course, setCourse] = useState({ chapters: [] });
  const [videoUrl, setVideoUrl] = useState("");
  const [isEnded, setIsEnded] = useState(false);
  const [videoEndControl, setVideoEndControl] = useState(false);
  const [resetVideo, setResetVideo] = useState(false);
  const [closingVideo, setClosingVideo] = useState(false);
  const [isEndVideo, setIsEndVideo] = useState(false);
  const [nextVideo, setNextVideo] = useState({
    title: "",
    courseSlug: "",
    chapterSlug: "",
    lessonSlug: "",
    thumbnail: "",
  });
  const [videoInterval, setVideoInterval] = useState("");
  const [currentDuration, setCurrentDuration] = useState();
  const [lessonSlug, setLessonSlug] = useState("");

  const { activeCategory } = useAppContext();
  const { course: courseParam, chapter: chapterParam, slug } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let timeoutDuration = 10 * 1000;

  const getVideoUrl = () => {
    if (course) {
      const el = document.querySelector(".WatchingState");
      if (el) el.scrollTo({ top: 0, behavior: "smooth" });
      setResetVideo(true);

      const chapter = course?.chapters.find(
        (chapter) => String(chapter.slug) === String(chapterParam)
      );

      if (chapter) {
        const video = chapter.sectionLessons.find(
          (video) => String(video.slug) === String(slug)
        );

        if (video) {
          setLessonSlug(video.slug);
          setVideoUrl(video.videoUrl);
          setResetVideo(false);
          setCurrentDuration(video.userLessonDuration || 0);
        }
      }
    }
  };

  const removeDurations = () => {
    API.get(
      `Course/CourseBySlug/${course.lastDuration?.slug}/removeDurations`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  };

  const getCourse = () => {
    API.get(`Course/CourseBySlug/${courseParam}`, {
      headers: { ...headers, Authorization: `Bearer ${user.token}` },
    })
      .then(({ data: res }) => {
        const { data } = res;
        setCourse({
          isAvailableToUseForUser: data.isAvailableToUseForUser,
          chapters: data.courseSections,
          lastDuration: data.lastDuration,
        });
      })
      .then(() => {
        if (
          course?.lastDuration?.totalUserWatchTime ===
          course?.lastDuration?.totalDurationOfCourse
        ) {
          removeDurations();
        }
      })
      .catch((err) => handleErrors(err));
  };

  const setDuration = (lessonSlug = slug, closePlayer = false) => {
    if (videoEndControl && !closePlayer) return;
    if (!currentDuration) return;

    setClosingVideo(closePlayer);

    API.post(
      "Lesson/SetDuration",
      {
        lessonSlug: lessonSlug,
        duration: parseInt(currentDuration),
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then(({ data: res }) => {
        if (isEnded) {
          setVideoEndControl(true);
        }

        if (closePlayer) {
          clearInterval(videoInterval);
          setVideoInterval("");
          setTimeout(
            () =>
              navigate(
                auth.course.link
                  .replace(":category", activeCategory)
                  .replace(":slug", courseParam)
              ),
            50
          );
        }
      })
      .catch((err) => handleErrors(err));
  };

  const getNextLesson = () => {
    let nextVideoChapter = null;
    let nextVideo = null;
    let nextVideoThumbnail = null;
    let endVideoControl = false;

    const chapter = course?.chapters.find(
      (chapter) => String(chapter.slug) === String(chapterParam)
    );

    if (chapter) {
      const video = chapter.sectionLessons.find(
        (video) => String(video.slug) === String(slug)
      );

      if (video) {
        const index = chapter.sectionLessons.indexOf(video); //0

        if (index + 1 < chapter.sectionLessons.length) {
          nextVideoChapter = chapterParam;
          nextVideo = chapter.sectionLessons[index + 1];
          nextVideoThumbnail = chapter.sectionLessons[index + 1].videoThumbnail;
        } else {
          nextVideoChapter = course?.chapters.find(
            (chapter) => String(chapter.slug) === String(chapterParam)
          );

          if (nextVideoChapter) {
            const nextChapterIndex = course?.chapters.indexOf(nextVideoChapter);

            if (nextChapterIndex + 1 < course?.chapters.length) {
              nextVideoChapter = course?.chapters[nextChapterIndex + 1].slug;
              nextVideo =
                course?.chapters[nextChapterIndex + 1].sectionLessons[0]?.slug;
              nextVideoThumbnail =
                course?.chapters[nextChapterIndex + 1].sectionLessons[0]
                  ?.videoThumbnail;
            } else {
              endVideoControl = true;
            }
          }
        }
      }
    }

    setNextVideo({
      title: nextVideo?.title,
      courseSlug: courseParam,
      chapterSlug: nextVideoChapter,
      lessonSlug: nextVideo?.slug,
      thumbnail: nextVideoThumbnail,
    });

    setIsEndVideo(endVideoControl);
  };

  const newVideoInterval = useInterval(setDuration, timeoutDuration);
  const handleStartSettingDuration = () => {
    setVideoInterval(newVideoInterval);
    getNextLesson();
    setIsEnded(false);
  };

  useEffect(() => {
    if (!closingVideo) {
      clearInterval(videoInterval);
      setVideoInterval("");
      setVideoEndControl(false);
      setDuration(slug);
      setIsEnded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseParam, closingVideo]);

  useEffect(() => {
    getCourse();
    return () => {
      clearInterval(videoInterval);
      setVideoInterval("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDuration(lessonSlug);
    getCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (course && course.chapters.length > 0) {
      getVideoUrl();
      getNextLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  return (
    course && (
      <div className="CoursePage WatchingState position-fixed overflow-auto">
        <div
          role="button"
          onClick={() => setDuration(slug, true)}
          className="close-button position-absolute"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.1987 11.9999L23.5437 2.6545C24.1521 2.0467 24.1521 1.06365 23.5437 0.455851C22.9359 -0.15195 21.9531 -0.15195 21.3453 0.455851L12 9.80113L2.65472 0.455851C2.04663 -0.15195 1.06409 -0.15195 0.456289 0.455851C-0.152096 1.06365 -0.152096 2.0467 0.456289 2.6545L9.80128 11.9999L0.456289 21.3451C-0.152096 21.9529 -0.152096 22.9361 0.456289 23.5439C0.759169 23.847 1.15754 23.9992 1.55561 23.9992C1.9534 23.9992 2.35148 23.847 2.65472 23.5439L12 14.1985L21.3453 23.5439C21.6485 23.847 22.0466 23.9992 22.4447 23.9992C22.8425 23.9992 23.2405 23.847 23.5437 23.5439C24.1521 22.9361 24.1521 21.9529 23.5437 21.3451L14.1987 11.9999Z"
              fill="#303030"
            />
          </svg>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              {isEnded && !isEndVideo && nextVideo.lessonSlug && (
                <div className="next-video d-flex flex-column align-items-center">
                  <div className="col-12 col-sm-6 col-md-7 col-lg-6 d-flex flex-column">
                    <Link
                      to={auth.find(link => link.id === "course-watching")?.link
                        .replace(":category", activeCategory)
                        .replace(":course", nextVideo.courseSlug)
                        .replace(":chapter", nextVideo.chapterSlug)
                        .replace(":slug", nextVideo.lessonSlug)}
                      className="stretched-link d-flex flex-column align-items-center"
                    >
                      <img
                        src={nextVideo?.thumbnail}
                        alt="next video"
                        className="d-none d-sm-block col-12"
                      />
                    </Link>
                    <div className=" col-12 next-video-btn px-0 py-0">
                      <div className="next-video-title d-none d-sm-block">
                        <p className="my-3 mx-3 text-white text-center">
                          {nextVideo.title}
                        </p>
                      </div>
                      <HAButton
                        isLink
                        action={auth.find(link => link.id === "course-watching")?.link
                          .replace(":category", activeCategory)
                          .replace(":course", nextVideo.courseSlug)
                          .replace(":chapter", nextVideo.chapterSlug)
                          .replace(":slug", nextVideo.lessonSlug)}
                        className="my-4 mx-3"
                      >
                        Sonraki Videoya Geç
                      </HAButton>
                    </div>
                  </div>
                </div>
              )}

              <div className="video-area w-100">
                {!resetVideo && videoUrl && (
                  <Vimeo
                    video={videoUrl}
                    autoplay={true}
                    color="c0f700"
                    showByline={false}
                    showPortrait={false}
                    showTitle={false}
                    speed={true}
                    start={
                      course?.lastDuration?.totalUserWatchTime <
                        course?.lastDuration?.totalDurationOfCourse &&
                      currentDuration
                        ? currentDuration
                        : 0
                    }
                    className="w-100 h-100 position-absolute video"
                    onPlay={() => handleStartSettingDuration()}
                    onPause={() => {
                      clearInterval(videoInterval);
                      setVideoInterval("");
                    }}
                    onError={(err) => showInfoBanner(err.message, "error")}
                    onEnd={() => setIsEnded(true)}
                    onTimeUpdate={(e) => setCurrentDuration(e.seconds)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Chapters
                chapters={course?.chapters}
                activeChapter={chapterParam}
                activeCourse={slug}
                course={courseParam}
                isAvailableToUseForUser={course?.isAvailableToUseForUser}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Watching;
