import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CoursesNav from "../components/CoursesNav";
import CoursesList from "../components/CoursesList";
import SubCoursesIntro from "./components/SubCoursesIntro";
import API, { headers } from "../../utils/API";
import {
  getCurrentRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import { useLocation, useNavigate, useParams } from "react-router";
import useAppContext from "../../utils/hooks/useAppContext";
import { connect } from "react-redux";
import { setActiveSubRoute } from "../../App/appActions";

const SubCourses = ({ setActiveSubRoute: setActiveSubRouteWithRedux }) => {
  // const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const { activeCategory } = useAppContext();
  const [courses, setCourses] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [currentTab, setCurrentTab] = useState();
  const [activeSubRoute, setActiveSubRoute] = useState("");

  const getSubCategoriesBySlug = () => {
    if (slug)
      API.get(`Course/GetCoursesBySubCategorySlug/${slug}`, {
        headers: { ...headers },
      })
        .then(({ data: res }) => {
          const { data } = res;

          const selectedCourse = {
            id: data.id,
            slug: data.slug,
            title: data.name,
            desc: data.description,
            courseCount: data.totalCourses,
            courseLength: data.totalVideoLength,
            video: {
              id: data.videoUrl,
              cover: data.videoThumbnail,
            },
            courses: data.coursesOfSubCategory.map((course) => ({
              id: course.id,
              slug: course.slug,
              title: course.title,
              cover: course.imageUrl,
              courseCount: course.totalLesson,
              courseLength: course.videoLength,
              instructor: {
                id: course.teacher.id,
                slug: course.teacher.slug,
                name: course.teacher.fullName,
              },
            })),
          };

          setSelectedCourse(selectedCourse);
        })
        .catch((err) => handleErrors(err));
  };

  const handlePathname = () => {
    let defaultSlug = "";

    if (slug && courses.find((c) => c.slug === slug)) defaultSlug = slug;
    else defaultSlug = courses[0]?.slug;

    const currentRoute = getCurrentRoute(location?.pathname);

    if (currentRoute?.route?.link)
      navigate(
        currentRoute?.route?.link
          ?.replace(":category", activeCategory)
          ?.replace(":page", activeSubRoute)
          ?.replace(":slug", defaultSlug)
      );
  };

  useEffect(() => {
    if (courses.length > 0) setCurrentTab(courses.find((c) => c.slug === slug));
  }, [slug, courses]);

  useEffect(() => {
    if (courses && activeSubRoute && courses.length > 0) handlePathname();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  useEffect(() => {
    const currentRoute = getCurrentRoute(location?.pathname);
    if (currentRoute?.route?.id) {
      setActiveSubRoute(currentRoute?.route?.id);
      setActiveSubRouteWithRedux(currentRoute?.route?.id);
    }
  }, [location?.pathname, setActiveSubRouteWithRedux]);

  useEffect(() => {
    if (currentTab) getSubCategoriesBySlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab(null);
    setCourses([]);
  }, [activeSubRoute]);

  useEffect(() => {
    if (currentTab) {
      sendGAEvent("page_view", "view_item", {
        items: [
          {
            item_id: currentTab?.id,
            item_name: currentTab?.name,
          },
        ],
      });

      sendMetaEvent("track", "ViewContent", {
        content_ids: [currentTab?.id],
        content_type: "product",
      });
    }
  }, [currentTab]);

  return (
    <div className="SubCoursesPage CoursesPage">
      <Helmet>
        <title>{selectedCourse?.title}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <CoursesNav
              course={slug}
              setCourses={setCourses}
              page={activeSubRoute}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SubCoursesIntro
              title={selectedCourse?.title || ""}
              duration={
                selectedCourse?.courseCount
                  ? `${selectedCourse?.courseCount} Eğitim`
                  : ``
              }
              desc={selectedCourse?.desc}
              video={{
                id: selectedCourse?.video?.id || "",
                cover: selectedCourse?.video?.cover || "",
              }}
              activeCategory={activeCategory}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CoursesList
              items={selectedCourse?.courses || []}
              course={selectedCourse?.slug || ""}
              isDetail
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setActiveSubRoute: (route) => dispatch(setActiveSubRoute(route)),
});

export default connect(null, mapDispatchToProps)(SubCourses);
