import React from "react";
import { Corporate } from "../../Profile/pages/BillingInfo/Corporate";
import { Personal } from "../../Profile/pages/BillingInfo/Personal";
import HAInput from "../../utils/components/HAInput";

const BillingInfo = ({
  handleUpdateBillingInfo,
  handleSetBillingType,
  billingInfo,
  handleInputBlur,
  billingInfoErrors,
}) => {
  return (
    <section className="BillingInfo">
      <div className="row">
        <div className="col">
          <div className="selection-area d-flex justify-content-start align-items-center">
            <div
              className="selection d-flex justify-content-center align-items-center cursor--pointer"
              data-is-selected={billingInfo.type === 1}
              onClick={() => handleSetBillingType(1)}
            >
              Bireysel
            </div>
            <div
              className="selection d-flex justify-content-center align-items-center cursor--pointer"
              data-is-selected={billingInfo.type === 2}
              onClick={() => handleSetBillingType(2)}
            >
              Kurumsal
            </div>
          </div>
        </div>
      </div>
      {billingInfo.type === 1 ? (
        <Personal
          input={{
            name: "idNo",
            value: billingInfo.idNo,
            label: "TC Kimlik Numarası",
          }}
          onChange={handleUpdateBillingInfo}
          handleInputBlur={handleInputBlur}
          errors={billingInfoErrors}
        />
      ) : (
        <Corporate
          inputs={{
            companyName: {
              name: "companyName",
              value: billingInfo.companyName,
              label: "Firma Ünvanı",
            },
            taxLocation: {
              name: "taxLocation",
              value: billingInfo.taxLocation,
              label: "Vergi Dairesi",
            },
            taxNumber: {
              name: "taxNumber",
              value: billingInfo.taxNumber,
              label: "Vergi Kimlik No",
            },
          }}
          onChange={handleUpdateBillingInfo}
          handleInputBlur={handleInputBlur}
          errors={billingInfoErrors}
        />
      )}
      <div className="row">
        <div className="col">
          <HAInput
            name="city"
            value={billingInfo.city}
            onChange={handleUpdateBillingInfo}
            onBlur={handleInputBlur}
            label="Şehir"
            inputProps={{
              onKeyDown: (e) => {
                return /[a-zığüşöçİ]/i.test(e.key) ? null : e.preventDefault();
              },
            }}
            error={billingInfoErrors?.includes("city")}
          />
        </div>
        <div className="col">
          <HAInput
            name="zipcode"
            value={billingInfo.zipcode}
            onChange={handleUpdateBillingInfo}
            onBlur={handleInputBlur}
            label="Posta Kodu"
            formatNumber
            format="#####"
            error={billingInfoErrors?.includes("zipcode")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <HAInput
            type="textarea"
            name="address"
            value={billingInfo.address}
            onChange={handleUpdateBillingInfo}
            onBlur={handleInputBlur}
            label="Adres"
            error={billingInfoErrors?.includes("address")}
          />
        </div>
      </div>
    </section>
  );
};

export default BillingInfo;
