import React from "react";

export const Card = ({
  id,
  number,
  isDefault,
  issuerLogo,
  handleDeleteCard,
  handleSelectDefault,
}) => {
  return (
    <div
      className="payment-card d-flex flex-column align-items-center justify-content-between position-relative"
      data-is-default={isDefault}
    >
      <div className="top d-flex justify-content-between align-items-start w-100">
        <div className="card-number-area d-flex flex-column align-items-start">
          <div className="title">Kredi Kartı Numarası</div>
          <div className="number">{number}</div>
        </div>
        <div className="options-area">
          <div
            className="delete cursor--pointer"
            onClick={(e) => handleDeleteCard(e, id)}
          >
            Kartı Sil
          </div>
        </div>
      </div>
      <div className="bottom d-flex justify-content-between align-items-end w-100">
        <div className="default-selector">
          <input
            type="radio"
            name="card-default"
            id={`card-${id}`}
            checked={isDefault}
            className="d-none"
            onChange={() => handleSelectDefault(id)}
          />
          <label
            htmlFor={`card-${id}`}
            className="d-flex justify-content-start align-items-center stretched-link"
          >
            <div className="check-icon rounded-circle">
              <svg
                className="icon-theming"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 6.99639C14 10.8303 10.8303 14 7.00361 14C3.1769 14 0 10.8303 0 6.99639C0 3.16968 3.16968 0 6.99639 0C10.8231 0 14 3.16968 14 6.99639ZM5.43682 10.1805C5.63177 10.4188 5.82671 10.5271 6.06498 10.5271C6.31047 10.5271 6.51264 10.4116 6.66426 10.1733L10.1372 4.73646C10.2238 4.59206 10.3105 4.42599 10.3105 4.26715C10.3105 3.9278 10.0072 3.71841 9.69675 3.71841C9.50181 3.71841 9.3213 3.82671 9.1769 4.05054L6.0361 9.08303L4.41877 7.00361C4.25271 6.787 4.07942 6.7148 3.88448 6.7148C3.55957 6.7148 3.29964 6.97473 3.29964 7.30686C3.29964 7.4657 3.3574 7.63177 3.47292 7.76173L5.43682 10.1805Z"
                />
              </svg>
            </div>
            {isDefault ? `Seçili Ödeme Yönteminiz` : `Ödeme Yöntemi Olarak Seç`}
          </label>
        </div>
        <div className="issuer-logo">
          {issuerLogo && <img src={issuerLogo} alt="Card issuer logo" />}
        </div>
      </div>
    </div>
  );
};
