import axios from "axios";
import { getBaseCategoryCode, getLoginData, refreshAuthToken } from "./helper";
import store from "../store";
import { login, logout } from "../Auth/authActions";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let cancelSource = axios.CancelToken.source();

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

const cancelRequest = () => {
  cancelSource.cancel("Operation canceled by the user.");
};

API.interceptors.request.use(
  (config) => {
    cancelSource = axios.CancelToken.source();
    config.cancelToken = cancelSource.token;

    const user = cookies.get("user");

    if (user?.isLoggedIn && user?.token) {
      config["headers"]["Authorization"] = `Bearer ${user.token}`;
    }

    config.headers.baseCategory = getBaseCategoryCode();
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const user = cookies.get("user");
      cancelRequest();

      if (user?.isLoggedIn && user?.refreshToken) {
        refreshAuthToken(user.refreshToken)
          .then((data) => {
            if (!data) return;
            const newUser = getLoginData({ ...user, ...data });
            store.dispatch(login(newUser));
          })
          .then(() => {
            window.location.reload(false);
          })
          .catch(() => {
            store.dispatch(logout());
          });
        return;
      }
    }

    return Promise.reject(error);
  }
);

export const headers = {
  "Content-Type": "application/json",
};

export default API;
