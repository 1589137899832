import React from "react";

export const PageTitle = ({ title, className, id, ...rest }) => {
  return (
    <div className={`PageTitle ${className}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="page-title">{title}</div>
          </div>
          {`children` in rest && <div className="col">{rest.children}</div>}
        </div>
      </div>
    </div>
  );
};
