import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import appReducer from "./App/appReducer";
import authReducer from "./Auth/authReducer";

const rootReducer = combineReducers({
  routing: routerReducer,
  app: appReducer,
  auth: authReducer,
});

export default rootReducer;
