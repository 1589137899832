import React, { useEffect, useState } from "react";
import {
  getLoginData,
  getRoute,
  sendGAEvent,
  sendMetaEvent,
  validate,
} from "../../utils/helper";
import API from "../../utils/API";
import { connect } from "react-redux";
import { login } from "../authActions";
import { useLocation, useNavigate } from "react-router";
import { Auth } from "../Auth";
import { InfoArea } from "../../utils/components/InfoArea";
import { HAButton } from "../../utils/components/HAButton";
import HAInput from "../../utils/components/HAInput";

const ResetPassword = ({
  showInfoBanner,
  prevLocation,
  login,
  activeCategory,
}) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate(password, "password")) {
      setError("Lütfen şifre gereksinimlerini kontrol edin.");
      setIsSending(false);
      return false;
    }

    if (code && email && password) {
      setIsSending(true);

      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      const data = {
        email,
        code,
        newPassword: password,
      };

      API.post("Account/ForgetPasswordConfirmation", data)
        .then((res) => {
          const { status, info, data } = res.data;
          if (status) {
            showInfoBanner("Şifreniz başarıyla değiştirildi.", "success");
            const user = getLoginData(data);
            login(user);

            navigate(
              getRoute("explore")?.link.replace(":category", activeCategory),
              {
                replace: true,
              }
            );
          } else if (info && info.friendlyMessage) {
            setIsSending(false);
            setError(info.friendlyMessage);
          }
        })
        .catch((err) => {
          setIsSending(false);
        });
    }
  };

  useEffect(() => {
    if (location && location.search) {
      const valuesArr = location.search.replace("?", "").split("&");

      if (valuesArr.length < 2) {
        navigate(getRoute("explore")?.link);
        return false;
      }

      const keys = valuesArr.map((value) => value.split("="));

      keys.forEach((_) => {
        if (_[0] === "email") setEmail(_[1]);
        if (_[0] === "code") setCode(_[1]);
      });
    } else navigate(getRoute("explore")?.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Auth prevLocation={prevLocation}>
      <div className="AuthBox page-fix">
        <h6 className="title">Şifrenizi Oluşturun</h6>
        <p className="desc">Yeni şifrenizi aşağıdaki alana giriniz</p>
        {error && <InfoArea type="error">{error}</InfoArea>}
        <form action="#!" onSubmit={handleSubmit}>
          <HAInput
            type="password"
            name="password"
            value={password}
            label="Yeni Şifrenizi Giriniz"
            onChange={({ target }) => setPassword(target.value)}
            showRules
          />
          <HAButton
            className="w-100 login-button"
            disabled={!password}
            action="submit"
            isLoading={isSending}
          >
            Şifreni Güncelle
          </HAButton>
        </form>
      </div>
    </Auth>
  );
};

const mapStateToProps = ({ app }) => ({
  activeCategory: app.app.activeRoute,
});

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
