import React from "react";

const Subscription = ({ title, list, desc, price, button, ...rest }) => {
  return (
    <div className="Subscription d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        {`topExtra` in rest && (
          <div className="row">
            <div className="col-12">
              <div className="top-extra d-flex justify-content-start align-items-center">
                {rest.topExtra}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="title text-center">{title}</div>
          </div>
        </div>
        {desc && (
          <div className="row">
            <div className="col-12">
              <div className="desc">{desc}</div>
            </div>
          </div>
        )}
        {list && (
          <div className="row">
            <div className="col-12">
              <ul className="list">
                {list.map((content, i) => (
                  <li
                    key={i}
                    className="item d-flex justify-content-start align-items-start"
                  >
                    <svg
                      className="icon-theming checkmark"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.6513 24C19.2114 24 24.6451 18.5663 24.6451 11.9938C24.6451 5.43373 19.199 0 12.639 0C6.07887 0 0.645142 5.43373 0.645142 11.9938C0.645142 18.5663 6.09125 24 12.6513 24ZM11.0423 18.0464C10.6338 18.0464 10.2996 17.8608 9.96541 17.4523L6.59873 13.3058C6.40069 13.083 6.30167 12.7983 6.30167 12.526C6.30167 11.9567 6.74726 11.5111 7.30424 11.5111C7.63844 11.5111 7.9355 11.6349 8.22018 12.0062L10.9927 15.5709L16.377 6.94379C16.6245 6.56008 16.934 6.37442 17.2681 6.37442C17.8004 6.37442 18.3202 6.73337 18.3202 7.31511C18.3202 7.58742 18.1717 7.8721 18.0232 8.11965L12.0696 17.4399C11.8097 17.8484 11.4631 18.0464 11.0423 18.0464Z"
                      />
                    </svg>
                    <p>{content}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-column price-container">
        <div className="row">
          <div className="col-12 text-md-center">
            <div
              className="price"
              dangerouslySetInnerHTML={{ __html: price }}
            ></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{button}</div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
