import React from "react";
import HAInput from "../../utils/components/HAInput";
import { cardExpiryFormat, getIcon } from "../../utils/helper";

const AddNewCC = ({ paymentInfo, handleUpdatePaymentInfo, theme }) => {
  return (
    <div className="add-payment-method p-0">
      <div className="row">
        <div className="col">
          <HAInput
            name="number"
            value={paymentInfo.number}
            label="Kart Numarası"
            onChange={handleUpdatePaymentInfo}
            formatNumber
            format="#### #### #### ####"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <HAInput
            name="name"
            value={paymentInfo.name}
            label="Kart Üzerindeki İsim"
            onChange={handleUpdatePaymentInfo}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <HAInput
            name="expiry"
            value={paymentInfo.expiry}
            label="Son Kullanma Tarihi"
            onChange={handleUpdatePaymentInfo}
            format={cardExpiryFormat}
          />
        </div>
        <div className="col-12 col-md-6">
          <HAInput
            name="cvv"
            value={paymentInfo.cvv}
            label="CVV (Güvenlik) Kodu"
            onChange={handleUpdatePaymentInfo}
            maxLength={4}
            format="####"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="add-card-checkbox d-flex justify-content-start align-items-start">
            <input
              type="checkbox"
              name="accept"
              id="checkbox-accept"
              checked={paymentInfo.accept}
              className="d-none"
              onChange={handleUpdatePaymentInfo}
            />
            <label
              htmlFor="checkbox-accept"
              className="checkbox-area d-flex justify-content-center align-items-center p-0"
            >
              <svg
                className="icon-theming"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.0062 24C18.5663 24 24 18.5663 24 11.9938C24 5.43373 18.5539 0 11.9938 0C5.43373 0 0 5.43373 0 11.9938C0 18.5663 5.44611 24 12.0062 24ZM10.3971 18.0464C9.98865 18.0464 9.65446 17.8608 9.32027 17.4523L5.95358 13.3058C5.75554 13.083 5.65652 12.7983 5.65652 12.526C5.65652 11.9567 6.10211 11.5111 6.6591 11.5111C6.9933 11.5111 7.29036 11.6349 7.57504 12.0062L10.3476 15.5709L15.7318 6.94379C15.9794 6.56008 16.2888 6.37442 16.623 6.37442C17.1552 6.37442 17.6751 6.73337 17.6751 7.31511C17.6751 7.58742 17.5266 7.8721 17.378 8.11965L11.4244 17.4399C11.1645 17.8484 10.8179 18.0464 10.3971 18.0464Z"
                />
              </svg>
            </label>
            <div className="info-area d-flex flex-column align-items-start">
              <label
                htmlFor="checkbox-accept"
                className="d-flex align-items-center"
              >
                Kart bilgilerinizi{" "}
                <img
                  src={getIcon("iyzicoColored", theme)}
                  alt="Iyzico Colored Icon"
                  className="mx-2"
                />{" "}
                güvencesi ile kaydedin.
              </label>
              <p className="disclaimer m-0">
                Onay vermeniz durumunda kart bilgileriniz sonraki siparişleriniz
                için saklanacaktır. Abonelik satın alımlarında kart bilgilerinin
                saklanması gerekmektedir.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCC;
