import React, { useEffect, useState } from "react";
import { HAInstructor } from "../../utils/components/HAInstructor";
import API from "../../utils/API";

import { handleErrors } from "../../utils/helper";
import { Loader } from "../../utils/components/Loader";
import useAppContext from "../../utils/hooks/useAppContext";
import SubCoursesIntro from "../../Courses/SubCourses/components/SubCoursesIntro";

const InstructorsList = ({ type }) => {
  const [instructors, setInstructors] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);
  const [corporateInstructorsIntroData, setCorporateInstructorsIntroData] =
    useState();

  const { activeCategory } = useAppContext();

  React.useEffect(() => {
    if (type) {
      setIsLoading(true);

      const apiUrl = `Teacher?TeacherType=${
        activeCategory === "kurumsal" ? 4 : 0
      }`;

      API.get(apiUrl)
        .then(({ data: r }) => {
          const { data } = r;

          const instructors = data.map((instructor) => ({
            id: instructor.id,
            slug: instructor.slug,
            fullName: instructor.fullName,
            branch: instructor.title,
            avatarUrl: instructor.avatarUrl,
          }));

          setInstructors(instructors);
          setTimeout(() => setIsLoading(false), 500);
        })
        .catch((err) => handleErrors(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type]);

  // eslint-disable-next-line no-unused-vars
  const loadMore = () => setPage(page + 1);

  const getCorporateInstructorsIntroData = () => {
    API.get("CorporateCategory/MainCategories?corporateCategoryType=2")
      .then(({ data: res }) => {
        const { data } = res;

        setCorporateInstructorsIntroData({
          id: data.id,
          title: data.longTitle,
          description: data.longDescription,
          playerEmbedUrl: data.playerEmbedUrl,
          videoThumbnail: data.videoThumbnail,
        });

        setTimeout(() => setIsLoading(false), 500);
      })
      .catch((err) => handleErrors(err));
  };

  useEffect(() => {
    if (activeCategory === "kurumsal") getCorporateInstructorsIntroData();
  }, [activeCategory]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {activeCategory === "kurumsal" && corporateInstructorsIntroData && (
        <div className="my-5">
          <SubCoursesIntro
            title={corporateInstructorsIntroData.title}
            desc={corporateInstructorsIntroData.description}
            video={{
              id: corporateInstructorsIntroData.playerEmbedUrl,
              cover: corporateInstructorsIntroData.videoThumbnail,
            }}
            activeCategory={activeCategory}
          />
        </div>
      )}
      <div className="InstructorsList">
        {instructors.map((instructor) => (
          <HAInstructor
            key={instructor.id}
            avatarUrl={instructor.avatarUrl}
            fullName={instructor.fullName}
            branch={instructor.branch}
            slug={instructor.slug}
            activeCategory={activeCategory}
          />
        ))}
      </div>
    </>
  );
};

export default InstructorsList;
