import React from "react";
import IntroVideoArea from "../../../utils/components/IntroVideoArea";
import { HAButton } from "../../../utils/components/HAButton";
import { Link } from "react-router-dom";
import useAppContext from "../../../utils/hooks/useAppContext";
import { handleImageRedirect } from "../../../utils/helper";

const SubCoursesIntro = ({
  title,
  duration,
  instructor,
  desc,
  video,
  image,
  activeCategory,
}) => {
  const { createModuleTree } = useAppContext();
  return (
    <section className="CoursesIntro">
      <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
        <div className="col-12 col-lg-5">
          <div className="sub-course-info d-flex flex-column align-items-start">
            <h2 className="title">{title}</h2>
            {duration && (
              <span className="duration text-uppercase">{duration}</span>
            )}

            <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
            {activeCategory === "kurumsal" && (
              <Link to="iletisime-gec">
                <HAButton
                  className="btn btn-primary"
                  onClick={() => createModuleTree()}
                >
                  İletişime Geç
                </HAButton>
              </Link>
            )}
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-6 offset-lg-1">
          {video && video.id && (
            <IntroVideoArea id={video.id} cover={video.cover} />
          )}
          {image &&
            image.imageUrl &&
            handleImageRedirect({
              imageUrl: image.imageUrl,
              redirectLink: image.redirectUrl,
            })}
        </div>
      </div>

      <script src="https://player.vimeo.com/api/player.js"></script>
    </section>
  );
};

export default SubCoursesIntro;
