import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Testimonial } from "./Testimonial";
import API from "../../utils/API";

import { SliderButtons } from "./SliderButtons";
import { handleErrors } from "../../utils/helper";
import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";

const Testimonials = ({ activeRoute, user }) => {
  const [prevButtonActive, setPrevButtonActive] = useState(false);
  const [nextButtonActive, setNextButtonActive] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [swiper, setSwiper] = useState({});

  const cancelSource = axios.CancelToken.source();

  const getTestimonials = () => {
    API.get("Testimonials")
      .then((res) => {
        const { data } = res.data;

        setTestimonials(data);
        setPrevButtonActive(!swiper.isBeginning);
        setNextButtonActive(!swiper.isEnd);
      })
      .catch((err) => handleErrors(err));
  };

  const handleSlideChange = (info) => {
    setPrevButtonActive(!info.isBeginning);
    setNextButtonActive(!info.isEnd);
  };


  useEffect(() => {
    getTestimonials();

    return () => cancelSource.cancel("Operation is canceled by the user.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoute, user]);

  return (
    testimonials?.length > 0 && (
      <section className="Testimonials">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={24}
                onSwiper={(swiper) => setSwiper(swiper)}
                pagination={{
                  el: ".testimanials-pagination",
                  clickable: true,
                }}
                speed={300}
                id="testimonials-slider"
                className="testimonials-slider"
                grabCursor
                onSlideChange={handleSlideChange}
              >
                {testimonials
                  .sort((a, b) => a.order - b.order)
                  .map((data, i) => (
                    <SwiperSlide key={i}>
                      <Testimonial {...data} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <SliderButtons
                states={{
                  prev: prevButtonActive,
                  next: nextButtonActive,
                }}
                next={() => swiper.slideNext()}
                prev={() => swiper.slidePrev()}
              />
            </div>
          </div>
        </div>
      </section>
    )
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Testimonials);
