import React from "react";
import HAItem from "../../utils/components/HAItem";
import useAppContext from "../../utils/hooks/useAppContext";

const CoursesList = ({ items, course, ...rest }) => {
  const { activeCategory } = useAppContext();

  return (
    <div className="CoursesList">
      {items.map((item) => (
        <HAItem
          key={item.id}
          {...item}
          course={course}
          currentPath={activeCategory}
          {...rest}
          isDetail
        />
      ))}
    </div>
  );
};

export default CoursesList;
