import React from "react";
import { HAButton } from "../../utils/components/HAButton";

export const EmptyArea = ({
  icon,
  content,
  buttonText,
  buttonLink,
  ...rest
}) => {
  return (
    <div className="empty-area d-flex flex-column align-items-center">
      <img src={icon} alt="Empty area icon" className="icon" />
      <h6 className="text">{content}</h6>
      <HAButton
        type="gray"
        className="add-button"
        onClick={typeof buttonLink === "function" ? buttonLink : () => {}}
        isLink={typeof buttonLink === "function" ? false : true}
        action={typeof buttonLink === "function" ? "button" : buttonLink}
      >
        {buttonText}
      </HAButton>
    </div>
  );
};
