import React from "react";

export const Loader = ({ type }) => {
  return (
    <div className="loader d-flex justify-content-center align-items-center w-100">
      {type === "dots" && (
        <div className="dots d-flex w-100 justify-content-center align-items-center">
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      )}
    </div>
  );
};

Loader.defaultProps = {
  type: "dots",
};
