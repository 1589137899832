export const toggleModal = (status = null) => ({
  type: "TOGGLE_MODAL",
  status,
});

export const toggleTheme = (theme) => ({
  type: "TOGGLE_THEME",
  theme,
});

export const showInfoBanner = (message, infoType = null, timeout = 3000) => ({
  type: "SHOW_INFO_BANNER",
  message,
  infoType,
  timeout,
});

export const hideInfoBanner = () => ({ type: "HIDE_INFO_BANNER" });

export const setActiveRoute = (route) => ({
  type: "SET_ACTIVE_ROUTE",
  route,
});

export const setActiveSubRoute = (route) => ({
  type: "SET_ACTIVE_SUB_ROUTE",
  route,
});
