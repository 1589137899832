import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { HAButton } from "../../utils/components/HAButton";
import HAItem from "../../utils/components/HAItem";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { SliderButtons } from "./SliderButtons";
import { getRoute } from "../../utils/helper";
import { connect } from "react-redux";

class Category extends Component {
  state = {
    prevButtonActive: false,
    nextButtonActive: true,
  };

  componentDidMount = () => {
    if (this.swiper) {
      this.setState({
        prevButtonActive: !this.swiper.isBeginning,
        nextButtonActive: !this.swiper.isEnd,
      });
    }
  };

  handleSlideChange = (info) => {
    this.setState({
      prevButtonActive: !info.isBeginning,
      nextButtonActive: !info.isEnd,
    });
  };

  swiper = {};

  render() {
    return (
      <section className="Category position-relative" id={this.props.data.id}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6 d-flex justify-content-start">
              <h3 className="cat-title">{this.props.data.title}</h3>
            </div>
            <div className="col-6 d-none d-md-flex justify-content-end">
              {this.props.data.canBeSubscribed && (
                <HAButton
                  type="pri"
                  action={getRoute("subscription")?.link}
                  isLink
                  className="subscribe-now-button"
                >
                  Abone Ol
                </HAButton>
              )}
              <HAButton
                type="gray"
                isLink
                action={
                  this.props.data.id === 117
                    ? getRoute("additional-courses")?.link
                    : getRoute("courses")
                        ?.link?.replace(":slug", this.props.data.slug)
                        ?.replace(":category", this.props.activeRoute)
                }
                className="view-all-button"
              >
                Tümünü Gör
                </HAButton>
            </div>
            <div className="col-6 d-flex d-md-none justify-content-end">
              <HAButton
                type="pri"
                isLink
                action={
                  this.props.data.id === 117
                    ? getRoute("additional-courses")?.link
                    : getRoute("courses")
                        ?.link?.replace(":slug", this.props.data.slug)
                        .replace(":category", this.props.activeRoute)
                }
                className="view-all-button"
              >
                Tümünü Gör
                 </HAButton>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Swiper
                slidesPerView="auto"
                spaceBetween={0}
                onSwiper={(swiper) => {
                  this.swiper = swiper;
                }}
                speed={300}
                id={`cat-${this.props.data.id}-slider`}
                className="cat-slider"
                onSlideChange={this.handleSlideChange}
                breakpoints={{
                  320: {
                    slidesPerView: `auto`,
                    spaceBetween: 0,
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 0,
                  },
                  768: {
                    slidesPerView: `auto`,
                    slidesPerGroup: 1,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                    spaceBetween: 23,
                  },
                }}
              >
                {this.props.data.courses.map((item, i) => (
                  <SwiperSlide key={item.id}>
                    <HAItem {...item} course={this.props.data.slug} isDetail />
                  </SwiperSlide>
                ))}
              </Swiper>
              <SliderButtons
                states={{
                  prev: this.state.prevButtonActive,
                  next: this.state.nextButtonActive,
                }}
                next={() => this.swiper.slideNext()}
                prev={() => this.swiper.slidePrev()}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  activeRoute: app.app.activeRoute,
});

export default connect(mapStateToProps)(Category);
