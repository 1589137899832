import React from "react";
import Chapter from "./Chapter";

const Chapters = ({
  chapters,
  course,
  activeChapter,
  activeCourse,
  isAvailableToUseForUser,
}) => {
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const handleResize = () => {
    const el = document.querySelectorAll(`.Chapters .chapter-holder`);

    if (el && el.length)
      el.forEach((data) => {
        const height = data.scrollHeight;
        data.style.setProperty("--max-height", height + "px");
      });
  };

  const handleShowDetails = (el) => {
    const show = el.classList.contains("active") ? false : true;
    document
      .querySelectorAll(".chapter-holder")
      .forEach((el) => el.classList.remove("active"));

    if (show) el.classList.add("active");
  };

  return (
    <section className="Chapters">
      {chapters.map(({ slug, title, sectionLessons: courses }) => (
        <Chapter
          key={slug}
          slug={slug}
          title={title}
          courses={courses}
          handleShowDetails={handleShowDetails}
          course={course}
          activeChapter={activeChapter}
          activeCourse={activeCourse}
          isAvailableToUseForUser={isAvailableToUseForUser}
        />
      ))}
    </section>
  );
};

export default Chapters;
