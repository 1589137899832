const CorporateContactBar = () => {
  return (
    <div className="corporate-contact-bar position-relative w-100 d-flex justify-content-center align-items-center">
      <a
        href="mailto:info@hypersacademy.co"
        className="stretched-link d-flex justify-content-center align-items-center"
      >
        Kurumsal eğitimler için bizimle iletişime geçebilirsiniz
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.62986 16C6.9912 16 6.52488 15.5358 6.52488 14.9065C6.52488 14.5764 6.64653 14.2979 6.85941 14.0916L9.14034 11.7395L12.0802 9.0058L9.71818 9.15023H3.28845C2.61938 9.15023 2.15306 8.67569 2.15306 8.00516C2.15306 7.32431 2.61938 6.84977 3.28845 6.84977H9.71818L12.0701 6.9942L9.14034 4.26048L6.85941 1.90845C6.64653 1.70213 6.52488 1.4236 6.52488 1.09349C6.52488 0.464217 6.9912 0 7.62986 0C7.92385 0 8.19756 0.113475 8.46113 0.371373L15.1214 7.14894C15.3546 7.37589 15.4864 7.68536 15.4864 8.00516C15.4864 8.31464 15.3546 8.62411 15.1214 8.85106L8.43072 15.6493C8.18742 15.8865 7.92385 16 7.62986 16Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
};

export default CorporateContactBar;
