import React, { Component } from "react";
import API, { headers } from "../../utils/API";
import HASwitch from "../../utils/components/HASwitch";
import { handleErrors } from "../../utils/helper";
import Profile from "../Profile";

export default class ContactPermission extends Component {
  state = { permission: false, isLoading: true };

  componentDidMount = () => {
    API.get("Account/GetUserInfo", {
      headers: { ...headers, Authorization: `Bearer ${this.props.user.token}` },
    }).then(({ data: res }) => {
      const { isEmailSubscribed: permission } = res.data;

      this.setState({ permission }, () => this.setState({ isLoading: false }));
    });
  };

  handleSetEmail = () => {
    const req = !this.state.permission
      ? `EmailSubscription/Subscribe`
      : `EmailSubscription/UnSubscribe`;

    API.post(
      req,
      { email: this.props.user.email },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${this.props.user.token}`,
        },
      }
    )
      .then(({ data: res }) => {
        const { status } = res;
        if (status) {
          this.setState({ permission: !this.state.permission });
        }
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    return (
      <Profile isLoading={this.state.isLoading}>
        <div className="page page--contact-permission">
          <section className="page-section">
            <div className="row">
              <div className="col">
                <h6 className="section-title">İletişim İzinlerim</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <p className="disclaimer">
                      İletişim bilgilerim kullanılarak{" "}
                      <strong>hypersacademy.co</strong> tarafından pazarlama
                      faaliyetleri ve benzeri amaçlarla yasal sınırlamalar
                      dahilinde, aşağıda belirttiğim kanallar üzerinden
                      haberleşme yapılmasına izin veriyorum.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <HASwitch
                      name="permission"
                      checked={this.state.permission}
                      onChange={this.handleSetEmail}
                    >
                      E-mail ile Bilgilendirme
                    </HASwitch>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Profile>
    );
  }
}
