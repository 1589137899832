import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { setCart } from "../Auth/authActions";
import { getUserCart } from "../utils/helper";
import { EmptyCart } from "./components/EmptyCart";
import FilledCart from "./components/FilledCart";

class Cart extends Component {
  componentDidMount = () => this.handleGetCart();

  componentDidUpdate = (prevProps) => {
    if (!prevProps.user.isLoggedIn && this.props.user.isLoggedIn)
      this.handleGetCart();
  };

  handleGetCart = async () => {
    await getUserCart(this.props.user, this.props.setCart);
  };

  render() {
    return (
      <div className="Cart position-relative">
        <Helmet>
          <title>Alışveriş Çantam</title>
        </Helmet>
        {!Boolean(this.props.user?.cart?.items?.length) && (
          <EmptyCart theme={this.props.theme} />
        )}
        {Boolean(this.props.user?.cart?.items?.length) && (
          <FilledCart user={this.props.user} />
        )}
      </div>
    );
  }
}

const mapPropsToState = ({ app }) => ({
  theme:
    app.app.theme === process.env.REACT_APP_DARK_THEME_NAME ? "dark" : "light",
});

const mapDispatchToProps = (dispatch) => ({
  setCart: (cart) => dispatch(setCart(cart)),
});

export default connect(mapPropsToState, mapDispatchToProps)(Cart);
