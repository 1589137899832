import React from "react";
import { HAButton } from "../../utils/components/HAButton";
import { getIconByCategory, getRoute } from "../../utils/helper";
import useAppContext from "../../utils/hooks/useAppContext";

export const EmptyCart = () => {
  const { activeCategory } = useAppContext();

  return (
    <div className="empty-cart position-relative">
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex flex-column align-items-center">
            {
              getIconByCategory({
                iconMainKey: "empty-cart-icon",
                className: "icon",
              })?.["imageNode"]
            }
            <h6 className="title text-center">
              Sepetinize Henüz Paket Eklemediniz.
            </h6>
            <p className="desc text-center">
              Eğitimlerden faydalanmak için; paket seçeneklerimizi
              inceleyebilirsin.
            </p>
            <HAButton
              type="pri"
              action={getRoute("subscription")?.link.replace(
                ":category",
                activeCategory
              )}
              isLink
            >
              {activeCategory === "kurumsal"
                ? "Eğitimleri İncele"
                : "Paketleri İncele"}
            </HAButton>
          </div>
        </div>
      </div>
    </div>
  );
};
