import React from "react";

export const InfoArea = ({ type, children }) => {
  return (
    <div
      className="InfoArea d-flex justify-content-start align-items-center"
      data-type={type}
    >
      <div className="icon">
        {type === "error" && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 11H9V5H11V11ZM11 15H9V13H11V15ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20C4.47715 20 0 15.5228 0 10Z"
              fill="#FF2929"
            />
          </svg>
        )}
      </div>
      <div className="content">{children}</div>
    </div>
  );
};
