const contact = [
  {
    id: 1,
    title: "Harbiye",
    address: "Harbiye Mah. Kadırgalar Cad. G-MALL No:6 K:1 Şişli / İstanbul",
    email: "info@hypersacademy.co",
    phone: "+90 (212) 280 10 16",
  },
];

export default contact;
