import React from "react";

export const Banner = ({ q, handleSearch, handleChange, ...rest }) => {
  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col">
            <h6 className="page-title text-center">
              Size Nasıl Yardımcı Olabiliriz?
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <form
              onSubmit={handleSearch}
              className="search-area d-flex justify-content-between align-items-center"
            >
              <input type="text" name="q" value={q} onChange={handleChange} />
              <button type="submit" className="search-button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.3536 24.2276C22.6748 24.2276 22.9604 24.1562 23.2103 24.0134C23.4601 23.8706 23.6542 23.6721 23.7925 23.4177C23.9308 23.1634 24 22.8801 24 22.5678C24 22.3536 23.9621 22.1439 23.8862 21.9387C23.8104 21.7334 23.6921 21.5549 23.5315 21.4032L17.811 15.6614L17.9087 15.5299C18.2329 15.0707 18.5177 14.5842 18.7634 14.0704L18.9403 13.6799C19.449 12.493 19.7033 11.2214 19.7033 9.86503C19.7033 8.50864 19.449 7.2348 18.9403 6.0435C18.4317 4.8522 17.7245 3.80368 16.8187 2.89794C15.913 1.99219 14.8645 1.28277 13.6732 0.76966C12.4819 0.256553 11.208 0 9.85165 0C8.49526 0 7.22365 0.256553 6.03681 0.76966C4.84997 1.28277 3.80145 1.99219 2.89124 2.89794C1.98104 3.80368 1.27161 4.84997 0.762967 6.03681C0.254322 7.22365 0 8.49972 0 9.86503C0 11.2214 0.254322 12.493 0.762967 13.6799C1.27161 14.8667 1.97881 15.9152 2.88455 16.8254C3.7903 17.7356 4.83882 18.4451 6.03012 18.9537C7.22142 19.4624 8.49526 19.7167 9.85165 19.7167C11.208 19.7167 12.4819 19.4624 13.6732 18.9537C14.2026 18.7276 14.7039 18.4619 15.177 18.1565L15.4135 17.9961L21.1757 23.7457C21.2962 23.8728 21.4292 23.9736 21.5747 24.0481L21.7245 24.1138C21.9297 24.1896 22.1394 24.2276 22.3536 24.2276ZM9.85165 17.3207C8.83436 17.3207 7.87284 17.1266 6.96709 16.7384C6.06135 16.3503 5.26715 15.8126 4.5845 15.1255C3.90184 14.4384 3.36643 13.6464 2.97825 12.7496C2.59007 11.8528 2.39598 10.8912 2.39598 9.86503C2.39598 8.84774 2.59007 7.88846 2.97825 6.98717C3.36643 6.08589 3.90184 5.29169 4.5845 4.60457C5.26715 3.91746 6.06135 3.37758 6.96709 2.98494C7.87284 2.5923 8.83436 2.39598 9.85165 2.39598C10.8779 2.39598 11.8416 2.5923 12.7429 2.98494C13.6442 3.37758 14.4361 3.91746 15.1188 4.60457C15.8014 5.29169 16.3369 6.08589 16.725 6.98717C17.1132 7.88846 17.3073 8.84774 17.3073 9.86503C17.3073 10.8912 17.1132 11.8528 16.725 12.7496C16.3369 13.6464 15.8014 14.4384 15.1188 15.1255C14.4361 15.8126 13.6442 16.3503 12.7429 16.7384C11.8416 17.1266 10.8779 17.3207 9.85165 17.3207Z"
                    fill="#303030"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
