import React from "react";
import { Link } from "react-router-dom";
import themeColors from "../db/themeColors";
import { getRoute } from "../helper";

export const HAInstructor = ({
  id,
  slug,
  fullName,
  branch,
  avatarUrl,
  activeCategory,
}) => {
  return (
    <div className="HAInstructor position-relative">
      <div className="top-area position-relative h-100">
        <div
          className="image-area w-100 overflow-hidden d-flex justify-content-center align-items-center"
          style={{ backgroundImage: `url("${avatarUrl}")` }}
        >
          {activeCategory === "workshop"
            ? <img src={avatarUrl} alt="Item Cover" className="w-100" />
            : <Link
            to={
              slug
                ? getRoute("instructor")
                  ?.link.replace(":slug", slug)
                  ?.replace(":category", activeCategory)
                : "#!"
            }
            className="stretched-link"
            onClick={!slug ? (e) => e.preventDefault() : () => {}}
          >
            <img src={avatarUrl} alt="Item Cover" className="w-100" />
          </Link>
          }

        </div>
      </div>
      <div className="info-area d-flex flex-column align-items-center w-100 position-absolute">
        <h2 className="instructor-name text-center">
          {activeCategory === "workshop"
            ? fullName
            : <Link
            to={
              slug
                ? getRoute("instructor")
                    ?.link.replace(":slug", slug)
                    ?.replace(":category", activeCategory)
                : "#!"
            }
            className="stretched-link"
            onClick={!slug ? (e) => e.preventDefault() : () => {}}
          >
            {fullName}
          </Link>}
        </h2>
        <p className="occupation text-center">{branch}</p>
        <svg
          width="102"
          height="6"
          viewBox="0 0 102 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="bottom-pattern position-absolute"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.556427 6H101.444C86.334 2.16558 69.1822 0 51 0C32.8178 0 15.666 2.16558 0.556427 6Z"
            fill={themeColors[activeCategory] || "#fff"}
          />
        </svg>
      </div>
    </div>
  );
};
