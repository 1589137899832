import React from "react";
import HACardSlider from "../../../utils/components/HACardSlider";

const CorporateCarouselArea = ({ carouselList }) => {
  return (
    <div className="w-100">
      {carouselList && carouselList.length > 0 && (
        <HACardSlider carousels={carouselList} />
      )}
    </div>
  );
};

export default CorporateCarouselArea;
