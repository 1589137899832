import Cookies from "universal-cookie/es6";

const cookies = new Cookies();
let theme = cookies.get("theme");

if (
  !theme ||
  (theme !== process.env.REACT_APP_LIGHT_THEME_NAME &&
    theme !== process.env.REACT_APP_DARK_THEME_NAME)
) {
  theme = process.env.REACT_APP_DEFAULT_THEME;
  cookies.set("theme", theme, { sameSite: "strict", path: "/" });
}

const initialState = {
  app: {
    isModalOn: false,
    theme,
    infoBanner: {
      isIn: false,
      message: null,
      type: null,
      timeout: 3000,
      timestamp: null,
    },
    activeRoute: cookies.get("activeRoute") || "icerik-ureticiligi",
    activeSubRoute: cookies.get("activeSubRoute") || "",
  },
};

const appReducer = (state = initialState, action) => {
  const cookie = new Cookies();

  switch (action.type) {
    case "TOGGLE_MODAL": {
      const app = { ...state.app };

      app.isModalOn = action.status !== null ? action.status : !state.isModalOn;

      return { app };
    }
    case "TOGGLE_THEME": {
      const app = { ...state.app };

      if (action.theme) {
        app.theme = action.theme;
        cookie.set("theme", action.theme, { sameSite: "strict", path: "/" });
      }

      return { app };
    }
    case "SHOW_INFO_BANNER": {
      const app = { ...state.app };

      app.infoBanner = {
        isIn: true,
        message: action.message,
        type: action.infoType || "error",
        timeout: action.timeout,
      };

      return { app };
    }
    case "HIDE_INFO_BANNER": {
      const app = { ...state.app };

      app.infoBanner = { ...app.infoBanner, isIn: false };

      return { app };
    }
    case "SET_ACTIVE_ROUTE": {
      const app = { ...state.app };

      app.activeRoute = action.route;
      cookies.set("activeRoute", action.route, {
        sameSite: "strict",
        path: "/",
      });

      return { app };
    }

    case "SET_ACTIVE_SUB_ROUTE": {
      const app = { ...state.app };

      app.activeSubRoute = action.route;
      cookies.set("activeSubRoute", action.route, {
        sameSite: "strict",
        path: "/",
      });

      return { app };
    }
    default:
      return state;
  }
};

export default appReducer;
