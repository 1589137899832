import React from "react";
import HAInput from "../../../utils/components/HAInput";

export const Corporate = ({
  inputs,
  onChange,
  handleInputBlur,
  errors,
  ...rest
}) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <HAInput
            name={inputs.companyName.name}
            value={inputs.companyName.value}
            label={inputs.companyName.label}
            onChange={onChange}
            onBlur={handleInputBlur}
            error={errors?.includes(inputs.companyName.name)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <HAInput
            name={inputs.taxLocation.name}
            value={inputs.taxLocation.value}
            label={inputs.taxLocation.label}
            onChange={onChange}
            onBlur={handleInputBlur}
            inputProps={{
              onKeyDown: (e) => {
                return /[a-zığüşöçİ0-9-.]/i.test(e.key)
                  ? null
                  : e.preventDefault();
              },
            }}
            error={errors?.includes(inputs.taxLocation.name)}
          />
        </div>
        <div className="col">
          <HAInput
            name={inputs.taxNumber.name}
            value={inputs.taxNumber.value}
            label={inputs.taxNumber.label}
            onChange={onChange}
            onBlur={handleInputBlur}
            formatNumber
            format="##########"
            error={errors?.includes(inputs.taxNumber.name)}
          />
        </div>
      </div>
    </>
  );
};
