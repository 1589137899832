import React from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../App/routes.v2";

export const Sidebar = () => {
  const menuItems = auth.filter((route) => route.onProfileMenu);
  return (
    <div className="sidebar d-flex flex-row flex-lg-column overflow-auto">
      {menuItems.map((route) => (
        <div key={route?.id} className="row menu-item-holder">
          <div className="col d-flex justify-content-start">
            <NavLink
              to={route.link}
              className="menu-item d-flex justify-content-start align-items-center"
            >
              <span className="icon">{route.icon}</span>
              <span className="text">{route.name}</span>
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  );
};
