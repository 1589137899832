import React, { Component } from "react";
import API, { headers } from "../../../utils/API";
import { HAButton } from "../../../utils/components/HAButton";
import HAInput from "../../../utils/components/HAInput";
import {
  checkIdentityNo,
  checkTaxNumber,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../../utils/helper";
import { Corporate } from "./Corporate";
import { Personal } from "./Personal";

export default class Filled extends Component {
  state = {
    type: 1,
    idNo: "",
    companyName: "",
    taxLocation: "",
    taxNumber: "",
    city: "",
    zipcode: "",
    address: "",
    defValues: {},
    errors: [],
    isButtonActive: false,
    isSending: false,
  };

  componentDidMount = () => {
    if (this.props.invoiceInfo) {
      this.setState({
        ...this.props.invoiceInfo,
        defValues: { ...this.props.invoiceInfo },
      });
    }
  };

  handleSetType = (type) => this.setState({ type }, this.checkChanges);

  handleChange = ({ target }) =>
    this.setState(
      {
        [target.name]: target.value,
      },
      () => {
        if (this.state.errors.includes(target.name))
          this.setState({
            errors: this.state.errors.filter((error) => error !== target.name),
          });

        this.checkChanges();
      }
    );

  checkChanges = (e = null) => {
    let isButtonActive = false;

    let keys = ["city", "zipcode", "address"];
    keys =
      this.state.type === 1
        ? [...keys, "idNo"]
        : [...keys, "companyName", "taxLocation", "taxNumber"];

    const emptyCheck = keys.every((key) => {
      if (String(this.state[key]).trim() === "") return false;

      if (
        key === "idNo" &&
        this.state.type === 1 &&
        !checkIdentityNo(this.state[key])
      )
        return false;

      if (
        key === "taxNumber" &&
        this.state.type === 2 &&
        !checkTaxNumber(this.state[key])
      )
        return false;
      return true;
    });

    if (emptyCheck) {
      isButtonActive = keys.some((key) => {
        return (
          String(this.state[key]).trim() !==
          String(this.state.defValues[key]).trim()
        );
      });
    }

    this.setState({ isButtonActive });
  };

  handleInputBlur = (e) => {
    const { name, value } = e.target;
    const errors = [...this.state.errors];

    const updateError = (state, name) => {
      if (state) {
        errors.splice(errors.indexOf(name), 1);
      } else {
        if (!errors.includes(name)) errors.push(name);
      }
    };

    if (!value.trim() && !errors.includes(name)) {
      errors.push(name);
    }

    if (name === "idNo" && this.state.type === 1) {
      updateError(checkIdentityNo(value), "idNo");
    } else if (name === "taxNumber" && this.state.type === 2) {
      updateError(checkTaxNumber(value), "taxNumber");
    }

    this.setState({ errors });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSending: true }, () => {
      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      const {
        type,
        idNo: identityNo,
        companyName: title,
        taxLocation: taxAdministration,
        taxNumber,
        city,
        zipcode: postCode,
        address,
      } = this.state;
      const data = {
        type: String(type),
        postCode,
        city,
        district: "",
        address,
        country: "Turkey",
      };

      if (type === 1) {
        data.identityNo = String(identityNo);
        data.title = this.props.user.fullName;
        data.taxAdministration = "";
      } else {
        data.identityNo = String(taxNumber);
        data.title = title;
        data.taxAdministration = taxAdministration;
      }

      API.post("User/SetInvoiceAddress", data, {
        headers: {
          ...headers,
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
        .then(({ data: res }) => {
          const { status, info } = res;

          if (status) {
            const defValues = {
              type: data.type ? parseInt(data.type) : 1,
              taxLocation: data.taxAdministration || "",
              city: data.city || "",
              zipcode: data.postCode || "",
              address: data.address || "",
            };

            if (defValues.type === 1) {
              defValues.idNo = data.identityNo ? parseInt(data.identityNo) : "";
              defValues.companyName = "";
              defValues.taxNumber = "";
            } else {
              defValues.idNo = "";
              defValues.companyName = data.title || "";
              defValues.taxNumber = data.identityNo || "";
            }

            if (this.state.type === 1) {
              this.setState({
                companyName: "",
                taxNumber: "",
                taxAdministration: "",
              });
            } else {
              this.setState({
                idNo: "",
              });
            }

            this.props.showInfoBanner(
              info?.friendlyMessage || "Fatura bilgileri güncellendi.",
              "success"
            );

            this.setState({ defValues, isSending: false }, () => {
              this.checkChanges();
            });
          } else {
            this.props.showInfoBanner(
              info?.friendlyMessage || `Bir hata oluştu.`,
              "error"
            );
            this.setState({ isSending: false });
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isSending: false });
        });
    });
  };

  render() {
    return (
      <div className="filled-area">
        <div className="row">
          <div className="col">
            <div className="selection-area d-flex justify-content-start align-items-center">
              <div
                className="selection d-flex justify-content-center align-items-center cursor--pointer"
                role="button"
                data-is-selected={this.state.type === 1}
                onClick={() => this.handleSetType(1)}
              >
                Bireysel
              </div>
              <div
                className="selection d-flex justify-content-center align-items-center cursor--pointer"
                role="button"
                data-is-selected={this.state.type === 2}
                onClick={() => this.handleSetType(2)}
              >
                Kurumsal
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={this.handleSubmit}>
              {this.state.type === 1 ? (
                <Personal
                  input={{
                    name: "idNo",
                    value: this.state.idNo,
                    label: "TC Kimlik Numarası",
                  }}
                  onChange={this.handleChange}
                  handleInputBlur={this.handleInputBlur}
                  errors={this.state.errors}
                />
              ) : (
                <Corporate
                  inputs={{
                    companyName: {
                      name: "companyName",
                      value: this.state.companyName,
                      label: "Firma Ünvanı",
                    },
                    taxLocation: {
                      name: "taxLocation",
                      value: this.state.taxLocation,
                      label: "Vergi Dairesi",
                    },
                    taxNumber: {
                      name: "taxNumber",
                      value: this.state.taxNumber,
                      label: "Vergi Kimlik No",
                    },
                  }}
                  onChange={this.handleChange}
                  handleInputBlur={this.handleInputBlur}
                  errors={this.state.errors}
                />
              )}
              <div className="row">
                <div className="col">
                  <HAInput
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    onBlur={this.handleInputBlur}
                    label="Şehir"
                    inputProps={{
                      onKeyDown: (e) => {
                        return /[a-zığüşöçİ]/i.test(e.key)
                          ? null
                          : e.preventDefault();
                      },
                    }}
                    error={this.state.errors?.includes("city")}
                  />
                </div>
                <div className="col">
                  <HAInput
                    name="zipcode"
                    value={this.state.zipcode}
                    onChange={this.handleChange}
                    onBlur={this.handleInputBlur}
                    label="Posta Kodu"
                    formatNumber
                    format="#####"
                    error={this.state.errors?.includes("zipcode")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <HAInput
                    type="textarea"
                    name="address"
                    value={this.state.address}
                    onChange={this.handleChange}
                    onBlur={this.handleInputBlur}
                    label="Adres"
                    error={this.state.errors?.includes("address")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <HAButton
                    className="w-100"
                    disabled={
                      !this.state.isButtonActive || this.state.isSending
                    }
                    action="submit"
                  >
                    Adresi Kaydet
                  </HAButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
