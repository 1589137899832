/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import HAInput from "../../utils/components/HAInput";
import { HAButton } from "../../utils/components/HAButton";
import { InfoArea } from "../../utils/components/InfoArea";
import { Link, redirect } from "react-router-dom";
import API, { headers } from "../../utils/API";
import {
  getCurrentRouteName,
  getLoginData,
  getPrevLocation,
  getRoute,
  getUserCart,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import { guest } from "../../App/routes.v2";
import { hideAuthBox, login, setCart } from "../authActions";
import { connect } from "react-redux";
import { showInfoBanner } from "../../App/appActions";

class LoginBox extends Component {
  state = {
    email: "",
    password: "",
    isButtonEnabled: false,
    isSending: false,
    error: "",
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, () =>
      this.setState({
        isButtonEnabled: this.state.email && this.state.password,
      })
    );

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.isSending)
      this.setState({ isSending: true }, () => {
        sendGAEvent("event", "generate_lead");
        sendMetaEvent("track", "Lead");

        API.post("Account/Login", {
          email: this.state.email,
          password: this.state.password,
        })
          .then((res) => {
            const { status, info, data } = res.data;

            if (status) {
              let redirectTo = null;

              const currentRoute = getCurrentRouteName();

              if (currentRoute in guest) {
                redirectTo = getPrevLocation();
              }

              API.get("Account/GetUserInfo", {
                headers: { ...headers, Authorization: `Bearer ${data.token}` },
              })
                .then(({ data: res }) => {
                  const { status } = res;

                  if (status) {
                    const user = getLoginData({ ...data, ...res.data });
                    this.props.login(user);

                    setTimeout(
                      async () => await getUserCart(user, this.props.setCart),
                      20
                    );

                    localStorage.removeItem("prevLocation");
                    // if (redirect) this.props.history.push(redirect);

                    redirect(getRoute("explore")?.link);

                    this.props.hideAuthBox();
                  } else if (info && "friendlyMessage" in info) {
                    this.setState({
                      isSending: false,
                      error: info.friendlyMessage,
                    });
                  }
                })
                .catch((err) => {
                  this.setState({ isSending: false });
                  handleErrors(err);
                });
            } else if (info && "friendlyMessage" in info) {
              this.setState({ isSending: false, error: info.friendlyMessage });
            }
          })
          .catch((err) => {
            this.setState({ isSending: false });
            handleErrors(err);
          });
      });
  };

  render() {
    return (
      <div className="AuthBox">
        <h6 className="title">Giriş Yap</h6>
        {this.state.error && (
          <InfoArea type="error">{this.state.error}</InfoArea>
        )}
        <form action="#!" onSubmit={this.handleSubmit}>
          <HAInput
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            label="E-Posta Adresiniz"
          />
          <HAInput
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            label="Şifreniz"
          />
          <HAButton
            className="w-100 login-button"
            disabled={!this.state.isButtonEnabled}
            action="submit"
            isLoading={this.state.isSending}
          >
            Giriş Yap
          </HAButton>
        </form>
        <div className="forgot-password-link d-flex justify-content-center">
          <Link to={getRoute("forgotpass")?.link}>Şifremi unuttum</Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
  hideAuthBox: () => dispatch(hideAuthBox()),
  showInfoBanner: (message, type, timeout) =>
    dispatch(showInfoBanner(message, type, timeout)),
  setCart: (cart) => dispatch(setCart(cart)),
});

export default connect(null, mapDispatchToProps)(LoginBox);
