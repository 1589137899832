import React, { useState } from "react";
import API from "../../utils/API";
import { useNavigate } from "react-router";
import { Auth } from "../Auth";
import { InfoArea } from "../../utils/components/InfoArea";
import HAInput from "../../utils/components/HAInput";
import { HAButton } from "../../utils/components/HAButton";
import { getRoute, sendGAEvent, sendMetaEvent } from "../../utils/helper";

const ForgotPassword = ({ showInfoBanner, prevLocation }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email) {
      setIsSending(true);

      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      API.post("Account/ForgetPassword", { email })
        .then((res) => {
          const { status, info } = res.data;
          if (status) {
            showInfoBanner(info.friendlyMessage, "success");
            navigate(getRoute("confirm")?.link + `?email=${email}`);
          } else if (info && info.friendlyMessage) {
            setIsSending(false);
            setError(info.friendlyMessage);
          }
        })
        .catch((err) => {
          setIsSending(false);
        });
    }
  };

  return (
    <Auth prevLocation={prevLocation}>
      <div className="AuthBox page-fix">
        <h6 className="title">Şifremi Unuttum</h6>
        <p className="desc">
          Sisteme kayıtlı e-posta adresinizi iletip yeni şifrenizi
          oluşturabilirsiniz.
        </p>
        {error && <InfoArea type="error">{error}</InfoArea>}

        <form action="#!" onSubmit={handleSubmit}>
          <HAInput
            type="email"
            name="email"
            value={email}
            label="E-Posta Adresiniz"
            onChange={({ target }) => setEmail(target.value)}
          />
          <HAButton
            className="w-100 login-button"
            disabled={!email}
            action="submit"
            isLoading={isSending}
          >
            Gönder
          </HAButton>
        </form>
      </div>
    </Auth>
  );
};

export default ForgotPassword;
