import { useEffect } from "react";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { getAllRoutes, getRoute } from "../utils/helper";
import { topMenu } from "../App/routes.v2";

const Index = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { category } = useParams();

  useEffect(() => {
    const combinedRoutes = getAllRoutes();

    const currentRoute = combinedRoutes.find((route) =>
      matchPath(route.link, pathname)
    );

    const isCategoryRoute = topMenu
      .filter((menuItem) => menuItem.visible)
      .find((route) => route.link === category);

    if (currentRoute?.id === "index") {
      if (isCategoryRoute) navigate(getRoute("explore", { category })?.link);
      else navigate(getRoute("home").link);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <Outlet />;
};

export default Index;
