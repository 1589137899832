import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { topMenu } from "../../App/routes.v2";
import CoursesBreadcrumbs from "../components/CoursesBreadcrumbs";

import CourseIntro from "./components/CourseIntro";
import Chapters from "./components/Chapters";
import API, { headers } from "../../utils/API";
import {
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import { Link, useParams } from "react-router-dom";
import useAppContext from "../../utils/hooks/useAppContext";

const Course = ({ user }) => {
  const { activeCategory } = useAppContext();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [course, setCourse] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const titleRoute = topMenu.find((route) => route.link === activeCategory);
    const breadcrumbs = [
      {
        id: 0,
        title: titleRoute.name || "",
        link: getRoute("explore", { category: activeCategory })?.link,
      },
      {
        id: 1,
        title: activeCategory === "kurumsal" ? "Online Eğitimler" : "Eğitimler",
        link:
          course?.categorySlug === "ek-egitimler-117"
            ? getRoute("additionalCourses")?.link
            : getRoute(
                activeCategory === "kurumsal" ? "online-courses" : "courses"
              )
                ?.link.replace(":category", activeCategory)
                .replace(":slug?", course?.categorySlug)
                .replace(
                  ":page",
                  activeCategory === "kurumsal"
                    ? getRoute("online-courses")?.query
                    : getRoute("courses")?.query
                ),
      },
      {
        id: 2,
        title: course?.categoryName || "",
        link:
          course?.categorySlug === "ek-egitimler-117"
            ? getRoute("additional-courses")?.link
            : getRoute("courses")
                ?.link.replace(":slug", course?.categorySlug || "")
                ?.replace(":category", activeCategory),
      },
    ];

    setBreadcrumbs(breadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, course]);

  useEffect(() => {
    API.get(`Course/CourseBySlug/${slug}`, {
      headers: { ...headers, Authorization: `Bearer ${user.token}` },
    })
      .then(({ data: res }) => {
        const { data } = res;

        let video = {
          id: data.videoUrl || "",
          cover: data.videoThumbnail || "",
        };

        if (
          data.lastDuration &&
          data.lastDuration.totalUserWatchTime <
            data.lastDuration.totalDurationOfCourse
        ) {
          const section = data.courseSections.find(
            (section) => section.slug === data.lastDuration.activeSectionSlug
          );

          const lesson = section?.sectionLessons.find(
            (lesson) => lesson.slug === data.lastDuration.activeLessonSlug
          );

          video.cover = lesson?.videoThumbnail || data.videoThumbnail;
        }

        setCourse({
          ...data,
          video,
          chapters: data.courseSections || [],
          instructor: {
            id: data.courseTeacher.id,
            slug: data.courseTeacher.slug,
            name: data.courseTeacher.fullName,
          },
          chapterCount: data.numberOfSections || 0,
          courseCount: data.numberOfCourses || 0,
          desc: data.description,
          categoryId: data.categoryId,
          categorySlug: data.categorySlug,
          categoryName: data.categoryName,
          lastDuration: data.lastDuration
            ? { ...data.lastDuration, courseId: data.id, courseSlug: data.slug }
            : null,
          isAvailableToUseForUser: data.isAvailableToUseForUser,
        });
      })
      .catch((err) => handleErrors(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, user.token]);

  useEffect(() => {
    if (course) {
      sendGAEvent("page_view", "view_item", {
        items: [
          {
            item_id: course?.id,
            item_name: course?.title,
          },
        ],
      });

      sendMetaEvent("track", "ViewContent", {
        content_ids: [course?.id],
        content_type: "product",
      });
    }
  }, [course]);

  return (
    course && (
      <div className="CoursesPage CoursePage">
        <Helmet>
          <title>{course?.title}</title>
        </Helmet>
        <div className="container">
          <div className="row d-none d-lg-flex">
            <div className="col-12">
              <CoursesBreadcrumbs items={breadcrumbs} />
            </div>
          </div>
          <div className="row d-lg-none">
            <div className="col-12">
              <div className="course-info">
                <h1 className="course-title">{course?.title}</h1>
                <h3 className="course-instructor">
                  <Link
                    to={getRoute("instructor")?.link?.replace(
                      ":id",
                      course?.instructor?.slug
                    )}
                  >
                    {course?.instructor?.name}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <CourseIntro
                title={course?.title}
                duration={{
                  chapters: course?.chapterCount,
                  courses: course?.courseCount,
                }}
                isAvailableToUseForUser={course?.isAvailableToUseForUser}
                startDate={course?.startDate}
                courseSlug={course?.slug}
                instructor={course?.instructor}
                desc={course?.desc}
                video={course?.video}
                lastDuration={course?.lastDuration}
                firstVideo={course?.chapters?.[0]?.sectionLessons?.[0]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Chapters
                chapters={course?.chapters}
                course={slug}
                isAvailableToUseForUser={course?.isAvailableToUseForUser}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Course;
