import React from "react";
import { HAButton } from "../../utils/components/HAButton";
import { formatCurrency } from "../../utils/helper";

export const Item = ({ item, removeFromCart }) => {
  return (
    <div className="row">
      <div className="col">
        <div className="item d-flex justify-content-between align-items-center">
          <div className="info-area d-flex justify-content-start align-items-center">
            {item.cover && (
              <div className="item-cover d-flex justify-content-center align-items-center overflow-hidden">
                <img src={item.cover} alt="Item Cover" className="mw-100" />
              </div>
            )}
            <div className="info d-flex flex-column">
              <h4 className="title">{item.title}</h4>
              <span className="desc">
                {item.type === 1 ? item.instructor : item.name}
              </span>
              <span className="price d-flex justify-content-start align-items-end">
                {formatCurrency(item.price)}
                <svg
                  className="try-sign"
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.15478 12C6.84219 12 9 9.22559 9 6.93603C9 6.39731 8.67223 6.04714 8.18058 6.04714C7.74355 6.04714 7.4431 6.30303 7.3475 6.89562C7.11533 8.6734 5.69499 10.2896 3.66009 10.2896V6.3165L6.32322 5.22559C6.62367 5.09091 6.74659 4.91582 6.74659 4.633C6.74659 4.28283 6.51442 4.02694 6.14568 4.02694C6.05008 4.02694 5.92716 4.05387 5.81791 4.09428L3.66009 4.98316V3.79798L6.32322 2.70707C6.62367 2.58586 6.74659 2.39731 6.74659 2.11448C6.74659 1.76431 6.51442 1.50842 6.14568 1.50842C6.05008 1.50842 5.92716 1.53535 5.81791 1.58923L3.66009 2.47811V0.902357C3.66009 0.350168 3.31866 0 2.81335 0C2.30804 0 1.96662 0.350168 1.96662 0.902357V3.15152L0.437026 3.78451C0.136571 3.90572 0 4.09428 0 4.3771C0 4.72727 0.245827 4.98316 0.614568 4.98316C0.710167 4.98316 0.819423 4.95623 0.942337 4.90236L1.96662 4.47138V5.67003L0.437026 6.30303C0.136571 6.42424 0 6.59933 0 6.88215C0 7.24579 0.245827 7.48822 0.614568 7.48822C0.710167 7.48822 0.819423 7.46128 0.942337 7.42088L1.96662 6.9899V10.9091C1.96662 11.5421 2.36267 12 3.15478 12Z"
                    fill="#081720"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="options d-flex justify-content-end align-items-center">
            <HAButton
              type="gray-empty"
              className="remove-button"
              onClick={() => removeFromCart(item)}
            >
              Sil
            </HAButton>
          </div>
        </div>
      </div>
    </div>
  );
};
