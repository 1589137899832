//brand logos
import creator from "../../assets/images/home/creator.svg";
import music from "../../assets/images/home/home-muzik-az.svg";
import acting from "../../assets/images/home/oyunculuk.svg";
import corporate from "../../assets/images/home/corparate.svg";
import workshop from "../../assets/images/home/workshop.svg";

//images
import creatorImage from "../../assets/images/home/02.jpg";
import musicImage from "../../assets/images/home/03.jpg";
import actingImage from "../../assets/images/home/07.jpg";
import corporateImage from "../../assets/images/home/05.jpg";
import workshopImage from "../../assets/images/home/06.jpg";

export const brands = [
  {
    id: 1,
    class: "one",
    slug: "icerik-ureticiligi",
    title: "İçerik Üreticiliği",
    logo: creator,
    image: creatorImage,
    description:
      "Hypers Academy yeni medya ve içerik üreticiliği eğitim sistemi ile içerik üreticiliğini profesyonelliğe taşıyabileceğin ileri düzey bir geliştirme programına hazır mısın?",
    visible: true,
  },

  {
    id: 2,
    class: "two",
    slug: "muzik",
    title: "Müzik",
    logo: music,
    image: musicImage,
    description:
      "Müzik tutkusunun peşinden gidenler, Hypers Music Academy’de buluşuyor!",
    visible: true,
  },

  {
    id: 3,
    class: "three",
    slug: "oyunculuk",
    title: "Oyunculuk",
    logo: acting,
    image: actingImage,
    description: "Oyunculuk hayalini Dijitalde gerçekleştir! Hypers Acting Academy oyunculuk modülü ile yeteneklerini keşfedeceğin,sahne önü ve sahne arkası kariyer alanları ve endüstri dinamiklerini öğrenerek fark yaratacağın bir eğitim sistemi seni bekliyor!",
    visible: true,
  },

  {
    id: 4,
    class: "four",
    slug: "workshop",
    title: "Workshop",
    logo: workshop,
    image: workshopImage,
    description:
      "Yeni medya alanında düzenlediğimiz workshoplarla startejik düşünmeyi ve içerik üretimini ve dijital çağın gereksinimlerini öğrenerek, kurumunuzu, markanızı ve kendinizi bir adım ileriye taşıyabilirsiniz!",
    visible: true,
  },


  {
    id: 5,
    class: "five",
    slug: "kurumsal",
    title: "Kurumsal",
    logo: corporate,
    image: corporateImage,
    description:
      "Hypers Academy kurumsal eğitimleri ile markanızı & kurumunuzu dijital geleceğe hazırlayabilirsiniz.",
    visible: false,
  },
];
