import React, { Component } from "react";
import Profile from "../Profile";
import { Suspense } from "react";

const CurrentSubscriptionPlan = React.lazy(() =>
  import("./Payments/CurrentSubscriptionPlan")
);

const PaymentInfo = React.lazy(() => import("./Payments/PaymentInfo"));
const PaymentHistory = React.lazy(() => import("./Payments/PaymentHistory"));

export default class Payments extends Component {
  state = {
    hasPaymentMethods: true,
    isAddingPaymentMethod: false,
    isLoading: true,
  };

  render() {
    return (
      <Profile isLoading={this.state.isLoading}>
        <div className="page page--payments">
          <Suspense fallback="Loading...">
            <CurrentSubscriptionPlan
              user={this.props.user}
              theme={this.props.app.theme}
            />
            <PaymentInfo
              user={this.props.user}
              theme={this.props.app.theme}
              newCardArea={true}
              showInfoBanner={this.props.showInfoBanner}
            />
            <PaymentHistory
              user={this.props.user}
              theme={this.props.app.theme}
            />
          </Suspense>
        </div>
      </Profile>
    );
  }
}
