import Vimeo from "@u-wave/react-vimeo";
import React from "react";

const IntroVideoArea = ({
  cover,
  id,
  title,
  icon,
  videoPaused,
  setVideoPaused,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);
  return (
    <>
      {!isVideoPlaying && (
        <div className="video-placeholder position-relative">
          <div
            className="play-button position-absolute w-100 h-100 d-flex justify-content-center align-items-center cursor--pointer"
            onClick={() => setIsVideoPlaying(true)}
          >
            <img
              src={cover || "//via.placeholder.com/647x364"}
              alt="Video Placeholder"
              className="position-absolute w-100"
            />
            {icon || (
              <svg
                width="67"
                height="67"
                viewBox="0 0 67 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="33.5"
                  cy="33.5"
                  r="30.5"
                  stroke="white"
                  strokeWidth="6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M42.1451 31.6595C43.3524 32.4489 43.3524 34.218 42.1451 35.0074L29.7997 43.0793C28.4695 43.9491 26.7052 42.9947 26.7052 41.4054L26.7052 25.2615C26.7052 23.6722 28.4695 22.7178 29.7997 23.5875L42.1451 31.6595Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        </div>
      )}
      {isVideoPlaying && (
        <>
          <div className="video-placeholder position-relative">
            <Vimeo
              video={id}
              autoplay={true}
              speed={true}
              color="c0f700"
              showByline={false}
              showPortrait={false}
              showTitle={false}
              className="w-100 h-100 position-absolute video"
              paused={videoPaused || false}
              onPlay={() => setVideoPaused(false)}
              onPause={() => setVideoPaused(true)}
              onEnd={() => {
                setIsVideoPlaying(false);
                if (setVideoPaused && typeof setVideoPaused === "function")
                  setVideoPaused(true);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IntroVideoArea;
