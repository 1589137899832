import React, { Component } from "react";
import { uniqueId } from "lodash";
export default class List extends Component {
  id = uniqueId();

  componentDidMount = () => {
    const el = document.querySelectorAll(`#help-list-${this.id} .item-holder`);

    if (el && el.length)
      el.forEach((data) => {
        const contentEl = data.lastElementChild;

        if (contentEl.classList.contains("content-area")) {
          const height = contentEl.scrollHeight + 32;
          contentEl.style.setProperty("--max-height", height + "px");
        }
      });
  };

  handleShowDetails = (el) => {
    const show = el.classList.contains("active") ? false : true;
    document
      .querySelectorAll(".item-holder")
      .forEach((el) => el.classList.remove("active"));

    if (show) el.classList.add("active");
  };

  render() {
    return (
      <div className="help-list" id={`help-list-${this.id}`}>
        <div className="row">
          <div className="col">
            <h3 className="cat-title">{this.props.title}</h3>
          </div>
        </div>
        {this.props.items.map((item) => (
          <div key={item.id} className="row">
            <div className="col">
              <div
                className="item-holder"
                id={`list-${this.id}-item-${item.id}`}
              >
                <button
                  className="item-trigger w-100 d-flex justify-content-between align-items-center"
                  onClick={() =>
                    this.handleShowDetails(
                      document.getElementById(`list-${this.id}-item-${item.id}`)
                    )
                  }
                >
                  <span className="item-title text-left text-md-center">
                    {item.title}
                  </span>
                  <svg
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="trigger-icon flex-shrink-0"
                  >
                    <path
                      d="M11.2793 23.7285C11.9492 23.7285 12.5098 23.1953 12.5098 22.5391V13.4473H21.3418C21.998 13.4473 22.5586 12.8867 22.5586 12.2168C22.5586 11.5469 21.998 11 21.3418 11H12.5098V1.89453C12.5098 1.23828 11.9492 0.705078 11.2793 0.705078C10.6094 0.705078 10.0625 1.23828 10.0625 1.89453V11H1.2168C0.560547 11 0 11.5469 0 12.2168C0 12.8867 0.560547 13.4473 1.2168 13.4473H10.0625V22.5391C10.0625 23.1953 10.6094 23.7285 11.2793 23.7285Z"
                      fill="#081720"
                    />
                  </svg>
                </button>

                <div
                  className="content-area w-100"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
