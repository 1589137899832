import React from "react";
import SingleCourseList from "./SingleCourseList";

const CourseList = ({
  chapter,
  courses,
  course,
  activeChapter,
  activeCourse,
  isAvailableToUseForUser,
}) => {
  return (
    <div className="CourseList">
      {courses.map(({ title, videoLength, slug, ...rest }, i) => (
        <SingleCourseList
          key={i}
          order={i + 1}
          title={title}
          duration={videoLength}
          course={course}
          isActive={String(activeCourse) === String(slug)}
          chapter={chapter}
          slug={slug}
          {...rest}
          isAvailableToUseForUser={isAvailableToUseForUser}
        />
      ))}
    </div>
  );
};

export default CourseList;
