import React from "react";
import { Link } from "react-router-dom";

import { HAButton } from "../../utils/components/HAButton";

export const SingleSlide = ({
  title,
  desc,
  image,
  isOnlyImage,
  mobileImage,
  link,
  text,
}) => {
  return (
    <div
      className="single-slide d-flex justify-content-center position-absolute w-100 h-100"
      data-image-only={isOnlyImage}
    >
      <div
        className="slide-image light d-none d-md-flex position-absolute w-100 h-100"
        style={{ "--background-image": `url(${image.light})` }}
      />
      <div
        className="slide-image dark d-none d-md-flex position-absolute w-100 h-100"
        style={{ "--background-image": `url(${image.dark})` }}
      />
      <div
        className="slide-image light d-flex d-md-none position-absolute w-100 h-100"
        style={{ "--background-image": `url(${mobileImage.light})` }}
      />
      <div
        className="slide-image dark d-flex d-md-none position-absolute w-100 h-100"
        style={{ "--background-image": `url(${mobileImage.dark})` }}
      />
      {isOnlyImage && link ? (
        link.startsWith("http") ? (
          <a
            href={link}
            target="_blank"
            className="stretched-link image-only-link"
            rel="noopener noreferrer"
          >
            &nbsp;
          </a>
        ) : (
          <Link to={link} className="stretched-link image-only-link">
            &nbsp;
          </Link>
        )
      ) : (
        ``
      )}
      {!isOnlyImage && (
        <div className="content-holder d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
          <div className="content-area d-flex flex-column align-items-center align-items-md-start">
            <h2 className="title">{title}</h2>
            <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
            {text && link && (
              <HAButton
                type="sec"
                isLink
                action={link}
                target={link.startsWith("http") ? `_blank` : `_top`}
              >
                {text}
              </HAButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
