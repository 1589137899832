import Cookies from "universal-cookie/es6";
import { calculateCartTotal } from "../utils/helper";

const initState = {
  user: {
    isLoggedIn: false,
    authBox: { is: false, type: null },
    settings: {
      seenCookieDisclaimer: false,
    },
    cart: { items: [] },
  },
};

const authReducer = (state = initState, action) => {
  const cookie = new Cookies();

  switch (action.type) {
    case "LOGIN": {
      const user = {
        ...state.user,
        ...action.user,
        isLoggedIn: true,
        authBox: { is: false, type: null },
      };

      cookie.set("user", user, { sameSite: "strict", path: "/" });

      return { user };
    }
    case "LOGOUT": {
      const user = { ...initState };

      cookie.remove("user", { sameSite: "strict", path: "/" });
      localStorage.removeItem("cart");

      return { user };
    }
    case "UPDATE": {
      const user = { ...state.user, ...action.user };

      const cookieUser = { ...state.user, ...action.user };
      delete cookieUser.settings;
      delete cookieUser.isLoggedIn;

      cookie.set("user", cookieUser, { sameSite: "strict", path: "/" });

      return { user };
    }
    case "SET": {
      const user = { ...action.user };

      const cookieUser = { ...action.user };
      delete cookieUser.settings;
      delete cookieUser.isLoggedIn;

      cookie.set("user", cookieUser, { sameSite: "strict", path: "/" });

      return { user };
    }
    case "SHOW_AUTH_BOX": {
      const authBoxType = action.authBoxType || "login";
      const user = {
        ...state.user,
        authBox: { is: true, type: authBoxType.toLowerCase() },
      };

      return { user };
    }
    case "HIDE_AUTH_BOX": {
      const user = {
        ...state.user,
        authBox: { is: false, type: null },
      };

      return { user };
    }
    case "SET_CART": {
      const user = { ...state.user, cart: action.cart };

      localStorage.setItem("cart", JSON.stringify(action.cart));

      return { user };
    }
    case "RESET_CART": {
      const user = { ...state.user, cart: { items: [] } };

      localStorage.removeItem("cart");

      return { user };
    }
    case "ADD_TO_CART": {
      const user = { ...state.user };

      const items = action.item.length ? [...action.item] : [action.item];

      user.cart.items = state.user.cart.items.concat(items);

      localStorage.setItem("cart", JSON.stringify(user.cart));

      return { user };
    }
    case "REMOVE_FROM_CART": {
      const user = { ...state.user };

      user.cart.items = state.user.cart.items.filter(
        (item) => item.cartId !== action.item.cartId
      );

      const { totalPrice, discount } = calculateCartTotal(user.cart);

      user.cart = { ...user.cart, totalPrice, discount };

      localStorage.setItem("cart", JSON.stringify(user.cart));

      return { user };
    }
    default:
      return state;
  }
};

export default authReducer;
