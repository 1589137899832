import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import API from "../utils/API";
import { Loader } from "../utils/components/Loader";
import {
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../utils/helper";
import { Banner } from "./components/Banner";
import List from "./components/List";

export default class Help extends Component {
  state = {
    q: "",
    isSearching: false,
    searchResults: [],
    list: [],
    isLoading: true,
  };

  componentDidMount = () => {
    API.get("QandA")
      .then(({ data: res }) => {
        const { data } = res;

        const list = data
          .sort((a, b) => a.order - b.order)
          .map(({ name, dataList }, i) => ({
            id: `list-${i}`,
            title: name,
            items: dataList
              .sort((a, b) => a.order - b.order)
              .map(({ question, answer }, j) => ({
                id: `list-${i}-item-${j}`,
                title: question,
                content: answer,
              })),
          }));

        this.setState({ list }, () => this.setState({ isLoading: false }));
      })
      .catch((err) => handleErrors(err));
  };

  searchTimeout = "";
  handleChange = ({ target }) =>
    this.setState({ q: target.value }, () => {
      clearTimeout(this.searchTimeout);
      if (this.state.q)
        this.setState({ isSearching: true }, () => {
          this.searchTimeout = setTimeout(this.handleSearch, 650);
        });
      else this.setState({ isSearching: false, searchResults: [] });
    });

  handleSearch = (e) => {
    if (e) e.preventDefault();

    sendGAEvent("event", "generate_lead");
    sendMetaEvent("track", "Lead");

    if (this.state.q) {
      const { q } = this.state;
      let searchResults = [];
      this.state.list.forEach((list) => {
        const listResult = list.items.filter(
          (item) =>
            item.title.toLowerCase().includes(q.toLowerCase().trim()) ||
            item.content.toLowerCase().includes(q.toLowerCase().trim())
        );

        if (listResult.length)
          searchResults = [
            ...searchResults,
            { ...list, items: [...listResult] },
          ];
      });

      this.setState(
        {
          searchResults: searchResults.length ? searchResults : [],
        },
        () => this.setState({ isSearching: false })
      );
    }
  };

  render() {
    return (
      <div className="Help position-relative">
        <Helmet>
          <title>{getRoute("help")?.name}</title>
        </Helmet>
        <Banner
          handleSearch={this.handleSearch}
          handleChange={this.handleChange}
        />
        <div className="container-fluid">
          {this.state.isSearching ? (
            <Loader />
          ) : this.state.searchResults.length ? (
            this.state.searchResults.map((data) => (
              <div key={data.id} className="row">
                <div className="col">
                  <List title={data.title} items={data.items} />
                </div>
              </div>
            ))
          ) : this.state.q ? (
            <div className="no-result text-center">
              <strong>"{this.state.q}"</strong> ile ilgili bir sonuç bulunamadı.
            </div>
          ) : (
            this.state.list.map((data) => (
              <div key={data.id} className="row">
                <div className="col">
                  <List title={data.title} items={data.items} />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}
