import React, { useEffect, useState } from "react";
import Category from "./components/Category";
import HowItWorks from "./components/HowItWorks";
import Slider from "./components/Slider";
import Subscriptions from "./components/Subscriptions";

import Testimonials from "./components/Testimonials";
import API from "../utils/API";
import { handleErrors } from "../utils/helper";
import axios from "axios";
import CorporateContactBar from "./components/CorporateContactBar";
import { connect } from "react-redux";
import { setActiveRoute } from "../App/appActions";
import { useLocation } from "react-router";
import CorporateTestimonials from "./components/CorporateTestimonials";

const Explore = ({ activeRoute, setActiveRoute, user }) => {
  const [categories, setCategories] = useState([]);
  const [corporateTestimonials, setCorporateTestimonials] = useState([]);

  const cancelSource = axios.CancelToken.source();
  const { pathname } = useLocation();

  const checkActiveCategory = () => {
    const currentCategory = pathname.split("/")[1];

    if (activeRoute !== currentCategory) {
      setActiveRoute(currentCategory);
    }
  };

  const handleGetCourses = () => {
    API.get("Category/GetExploreCoursesBySubCategories", {
      cancelToken: cancelSource.token,
    })
      .then(({ data: res }) => {
        const { data } = res;

        const categories = data.map((cat, i) => ({
          id: cat.categoryId,
          slug: cat.categorySlug,
          title: cat.categoryName,
          canBeSubscribed: cat.canBeSubscribed,
          courses: cat.coursesOfCategory.map((course) => ({
            id: course.id,
            slug: course.slug,
            categoryName: course.categoryName,
            categorySlug: course.categorySlug,
            title: course.title,
            basePrice: course.basePrice,
            price: course.price,
            cover: course.imageUrl,
            courseCount: course.totalLesson,
            courseLength: course.videoLength,
            instructor: {
              id: course.teacher?.id,
              slug: course.teacher?.slug,
              name: course.teacher?.fullName,
            },
          })),
        }));

        setCategories(categories);
      })
      .catch((err) => handleErrors(err));
  };

  const getDiscoverCards = () => {
    API.get("CorporateCategory/DiscoverCards").then(({ data: res }) => {
      if (!res?.data?.data) return;

      const { data } = res;

      setCorporateTestimonials(
        data?.map((card) => ({
          title: card.title,
          description: card.description,
          imageUrl: card.imageUrl,
          imageAlt: card.title,
          link: card.redirectURL,
        }))
      );
    });
  };

  useEffect(() => {
    handleGetCourses();
    if (activeRoute === "kurumsal") getDiscoverCards();

    return () => cancelSource.cancel("Operation canceled by the user.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoute, user]);

  useEffect(() => {
    checkActiveCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user]);

  return (
    <div className="Explore">
      <Slider activeRoute={activeRoute} />
      <HowItWorks activeRoute={activeRoute} />

      {activeRoute === "kurumsal" && corporateTestimonials.length > 0 && (
        <CorporateTestimonials
          activeRoute={activeRoute}
          corporateTestimonials={corporateTestimonials}
        />
      )}

      {categories
        .sort((a, b) => a.id - b.id)
        .map((cat) => (
          <Category
            key={cat.id}
            id={`cat-${cat.id}`}
            data={cat}
            activeRoute={activeRoute}
          />
        ))}

      {activeRoute !== "kurumsal" && (
        <Subscriptions activeRoute={activeRoute} />
      )}

      <Testimonials activeRoute={activeRoute} />

      {activeRoute === "kurumsal" && <CorporateContactBar />}
    </div>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  activeRoute: app.app.activeRoute,
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveRoute: (route) => dispatch(setActiveRoute(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
