import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class HAModal extends Component {
  componentDidMount = () => {
    document.body.setAttribute("data-modal", "true");
    window.addEventListener("keydown", this.keyDetection, false);
    document.querySelector("html").classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");
  };

  componentWillUnmount = () => {
    if (!this.props.user?.authBox?.is) {
      document.body.removeAttribute("data-modal");
      window.removeEventListener("keydown", this.keyDetection, false);
      document.querySelector("html").classList.remove("overflow-hidden");
      document.body.classList.remove("overflow-hidden");
    }
  };

  keyDetection = (e) => {
    if (e.keyCode === 27) this.props.closeModal();
  };

  render() {
    return (
      <div
        className={`HAModal vw-100 vh-100 position-fixed d-flex justify-content-center align-items-start ${this.props.classNames}`}
        id={this.props.id}
        ref={this.props.nodeProp}
      >
        <div
          className="overlay position-fixed vw-100 vh-100"
          onClick={this.props.closeModal}
        ></div>
        <div className="content position-relative d-flex overflow-hidden">
          <button
            className="close-button bg-transparent border-0 p-0 position-absolute d-flex justify-content-start align-items-end"
            onClick={this.props.closeModal}
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.56591 7.99964L15.7959 1.76961C16.2015 1.36442 16.2015 0.709078 15.7959 0.303891C15.3907 -0.101297 14.7355 -0.101297 14.3303 0.303891L8.1001 6.53387L1.86991 0.303891C1.46451 -0.101297 0.809492 -0.101297 0.40429 0.303891C-0.00129992 0.709078 -0.00129992 1.36442 0.40429 1.76961L6.63428 7.99964L0.40429 14.2296C-0.00129992 14.6348 -0.00129992 15.2902 0.40429 15.6954C0.60621 15.8975 0.871789 15.9989 1.13717 15.9989C1.40236 15.9989 1.66775 15.8975 1.86991 15.6954L8.1001 9.46535L14.3303 15.6954C14.5324 15.8975 14.7978 15.9989 15.0632 15.9989C15.3284 15.9989 15.5938 15.8975 15.7959 15.6954C16.2015 15.2902 16.2015 14.6348 15.7959 14.2296L9.56591 7.99964Z"
                fill="#303030"
              />
            </svg>
          </button>
          <div className="inner">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

HAModal.defaultModals = {
  classNames: "",
  id: "",
  children: <></>,
};

HAModal.propTypes = {
  classNames: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(HAModal);
