import React from "react";
import { HAButton } from "../../../utils/components/HAButton";
import PropTypes from "prop-types";
import IntroVideoArea from "../../../utils/components/IntroVideoArea";

const CoursesIntro = ({ title, desc, button, video }) => {
  return (
    <section className="CoursesIntro">
      <div className="row flex-column-reverse flex-lg-row align-items-center">
        <div className="col-12 col-lg-5">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-start">
              <h5 className="sec-title">{title}</h5>
              <p
                className="sec-desc"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
          </div>

          {button && (
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <HAButton isLink={true} action={button.link}>
                  {button.title}
                </HAButton>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-6 offset-lg-1">
          {video.id && <IntroVideoArea id={video.id} cover={video.cover} />}
        </div>
      </div>

      <script src="https://player.vimeo.com/api/player.js"></script>
    </section>
  );
};

CoursesIntro.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    cover: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default CoursesIntro;
