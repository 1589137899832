import React, { Component } from "react";
import { HAButton } from "../../../../utils/components/HAButton";
import HAInput from "../../../../utils/components/HAInput";
import {
  cardExpiryFormat,
  sendGAEvent,
  sendMetaEvent,
} from "../../../../utils/helper";

export default class AddPaymentMethod extends Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    isSending: false,
    isButtonActive: false,
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, this.checkValues);

  handleChangeFormatted = ({ formattedValue }, name) =>
    this.setState({ [name]: formattedValue }, this.checkValues);

  checkValues = () => {
    const keys = ["number", "name", "expiry", "cvv"];

    const empty = keys.filter(
      (key) => !this.state[key] || this.state[key] === ""
    );

    this.setState({ isButtonActive: !Boolean(empty.length) });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    sendGAEvent("event", "generate_lead");
    sendMetaEvent("track", "Lead");

    const { name, number, expiry } = this.state;

    const [month, year] = expiry.split("/");

    this.props.handleAddCard({
      cardHolderName: name,
      cardNumber: number.replace(/\s/g, ""),
      expireMonth: month,
      expireYear: year,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="add-payment-method">
        <div className="row">
          <div className="col">
            <HAInput
              name="number"
              value={this.state.number}
              label="Kart Numarası"
              onChange={this.handleChange}
              formatNumber
              format="#### #### #### ####"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <HAInput
              name="name"
              value={this.state.name}
              label="Kart Üzerindeki İsim"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <HAInput
              name="expiry"
              value={this.state.expiry}
              label="Son Kullanma Tarihi"
              onChange={this.handleChange}
              format={cardExpiryFormat}
            />
          </div>
          <div className="col-12 col-md-6">
            <HAInput
              name="cvv"
              value={this.state.cvv}
              label="CVV (Güvenlik) Kodu"
              onChange={this.handleChange}
              maxLength={4}
              format="####"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <HAButton
              disabled={!this.state.isButtonActive || this.state.isSending}
              className="w-100 add-button"
              action="submit"
            >
              Kart Bilgilerimi Kaydet
            </HAButton>
          </div>
        </div>
      </form>
    );
  }
}
