import is from "is_js";
import moment from "moment";
import TagManager from "react-gtm-module";
import { matchRoutes } from "react-router";
import { auth, global, guest, topMenu } from "../App/routes.v2";
import API, { headers } from "./API";
import icons from "./db/icons";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";

const cookies = new Cookies();

const SITE_URL = process.env.REACT_APP_SITE_URL;

export const handleImageRedirect = ({ imageUrl, redirectLink }) => {
  if (!redirectLink) return <img src={imageUrl} alt="" />;

  if (redirectLink.startsWith(SITE_URL)) {
    return (
      <Link to={redirectLink.replace(SITE_URL, "")}>
        <img src={imageUrl} alt="" className="course-detail-img" />
      </Link>
    );
  } else {
    const redirect = redirectLink.startsWith("http")
      ? redirectLink
      : `https://${redirectLink}`;

    return (
      <a
        href={redirect}
        className="stretched-link"
        target="_blank"
        rel="noreferrer"
      >
        <img src={imageUrl} alt="" className="course-detail-img" />
      </a>
    );
  }
};

export const getMenuItems = (type = 1) => {
  let keyToCheck = "inMenu";

  if (type === 2) {
    keyToCheck = "inFooterMenu";
  } else if (type === 3) {
    keyToCheck = "inCorporateMenu";
  }

  const routes = getAllRoutes();

  const filteredRoutes = routes
    .filter((route) => route?.[keyToCheck])
    .sort((a, b) => a?.order - b?.order);

  return filteredRoutes;
};

export const handleErrors = (err, user = null) => {
  if (err.response) {
    if (err.response.status === 401) {
    } else if (String(err.response.status)?.startsWith("50")) {
      return `${err.response.status} - ${err.response.statusText}`;
    } else {
      return err.response.status;
    }
  } else {
    console.error(err);
  }
};

export const getLoginData = (user) => ({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  phone: user.phone || "",
  isActivated: user.isEmailConfirmed,
  avatar: user.photoUrl,
  token: user.token,
  tokenExpire: user.tokenExpireTime,
  refreshToken: user.refreshToken,
  refreshTokenExpire: user.refreshTokenExpireTime,
});

export const refreshAuthToken = async (token) => {
  if (!token) return false;

  const { data: res } = await API.get(
    `Account/RefreshToken?refreshToken=${token}`
  );

  return res;
};

export const splitName = (name) => name.split(" ");

export const cardExpiryLimit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "01";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

export const cardExpiryFormat = (val) => {
  let month = cardExpiryLimit(val.substring(0, 2), "12");
  let year = val.substring(2, 4);

  return month + (year.length ? "/" + year : "");
};

export const getSubscriptionRemaining = (start, end) => {
  start = moment(start);
  end = moment(end);
  const today = moment();
  const totalDays = moment.duration(end.diff(start)).asDays();
  const remainingDays = moment.duration(end.diff(today)).asDays();

  return {
    percentage: 100 - (remainingDays / totalDays).toFixed(2) * 100,
    days: parseInt(remainingDays),
    total: parseInt(totalDays),
  };
};

export const getCurrentRoute = (link) => {
  const allRoutes = getAllRoutes();

  const match = matchRoutes(
    allRoutes.map((route) => ({
      ...route,
      path: route.link,
    })),
    window.location?.pathname
  );

  if (match?.length) {
    return match[0];
  }

  return "";
};

export const getCurrentRouteName = (link) => {
  const allRoutes = getAllRoutes();

  const match = matchRoutes(
    allRoutes.map((route) => ({
      ...route,
      path: route.link,
    })),
    link || window?.location?.pathname
  );

  if (match?.length) {
    return match[0].route.name;
  }

  return "";
};

export const getIcon = (name, theme) => {
  if (name in icons) {
    switch (theme) {
      case process.env.REACT_APP_DARK_THEME_NAME:
        return icons[name].dark;
      default:
        return icons[name].light;
    }
  } else return false;
};

export const getPrevLocation = () => {
  const prevLocation = localStorage.getItem("prevLocation");
  return prevLocation ? JSON.parse(prevLocation) : null;
};

export const formatCurrency = (value, currency = "try") => {
  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    currencyDisplay: "code",
  });

  return formatter
    .format(value)
    .replace(/[a-z]{3}/i, "")
    .trim()
    .replace(",00", "");
};

export const str_pad_left = (string, pad, length) =>
  (new Array(length + 1).join(pad) + string).slice(-length);

export const calculateDurationFromSeconds = (seconds) => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  return `${str_pad_left(minutes, "0", 2)}:${str_pad_left(
    secondsLeft,
    "0",
    2
  )}`;
};

export const validate = (string, type) => {
  let check = false;

  switch (type) {
    case "email":
      check = is.email(string);
      break;
    case "phone":
      check = /^[5][\d]{9}$/.test(string.replace(/\D/g, ""));
      break;
    case "password":
      check =
        /\d+/g.test(string) &&
        /[a-z]+/g.test(string) &&
        /[A-Z]+/g.test(string) &&
        string.length >= 6;
      break;
    case "identity_no":
      check = checkIdentityNo(string);
      break;
    default:
      break;
  }

  return check;
};

export const getAccountDetails = async (token) => {
  return await API.get("Account/GetUserInfo", {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const calculateCartTotal = (cart) => {
  const totalPrice = cart.items.reduce((acc, item) => acc + item.price, 0);
  let discountAmount = 0;

  if (cart.coupon) {
    if (cart.coupon.price) {
      discountAmount = cart.coupon.price;
    } else if (cart.coupon.percent) {
      discountAmount = (totalPrice / 100) * cart.coupon.percent;
    }
  }

  const discount = discountAmount;
  const amountToBePaid = totalPrice - discount;
  return { totalPrice, discount, amountToBePaid };
};

export const getUserCart = async (user, setCart) => {
  if (!user || !user.token || !setCart || typeof setCart !== "function")
    return false;

  return await API.get("Subscription/cartItems", {
    headers: { ...headers, Authorization: `Bearer ${user.token}` },
  })
    .then(({ data: r }) => {
      const { data } = r;

      const cart = { ...data };

      cart.items = data.items.map((item) => ({
        cartId: item.id,
        id: item.itemId,
        title: item.title,
        type: item.itemType === 0 ? 2 : 1,
        instructor: item.teacherName,
        image: item.imageUrl,
        price: item.price,
        status: item.purchaseStatus,
      }));

      const { totalPrice, discount, amountToBePaid } = calculateCartTotal(cart);

      setCart({ ...cart, totalPrice, discount, amountToBePaid });
    })
    .catch((err) => handleErrors(err));
};

const incorrectIdNo = [
  "11111111110",
  "22222222220",
  "33333333330",
  "44444444440",
  "55555555550",
  "66666666660",
  "7777777770",
  "88888888880",
  "99999999990",
];

export const checkIdentityNo = (idNo) => {
  idNo = String(idNo).trim();
  if (
    !idNo ||
    idNo.length < 11 ||
    idNo.startsWith("0") ||
    incorrectIdNo.includes(idNo)
  )
    return false;

  let odd = 0,
    even = 0,
    init9Total = 0;

  idNo
    .substr(0, 9)
    .split("")
    .forEach((number, i) => {
      const num = parseInt(number);
      if ((i + 1) % 2 === 0) {
        even += num;
      } else {
        odd += num;
      }
      init9Total += num;
    });

  if ((odd * 7 - even) % 10 !== parseInt(idNo.charAt(9))) {
    return false;
  }

  if (
    (init9Total + parseInt(idNo.charAt(9))) % 10 !==
    parseInt(idNo.charAt(10))
  ) {
    return false;
  }

  return true;
};

export const checkTaxNumber = (taxNumber) => {
  taxNumber = String(taxNumber).trim();

  if (taxNumber.length < 10) return false;

  let v = [];

  taxNumber
    .substr(0, 9)
    .split("")
    .forEach((number, i) => {
      let tmp = (parseInt(number) + (9 - i)) % 10;

      v[i] = (tmp * 2 ** (9 - i)) % 9;
      if (tmp !== 0 && v[i] === 0) v[i] = 9;
    });

  let sum = v.reduce((a, b) => a + b, 0) % 10;

  return (10 - (sum % 10)) % 10 === parseInt(taxNumber.charAt(9));
};

export const parseInternalUrl = (url) => {
  let link = url;

  if (!link) return link;

  if (
    link?.includes("hypersacademy.co") ||
    link?.includes(window.location.origin)
  ) {
    if (link.startsWith("http"))
      link = link.replace("http://", "").replace("https://", "");

    link = link.substr(link.indexOf("/"), link.length);
  }

  return link;
};

export const sendGAEvent = (event, ...configs) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      ...configs,
    },
  };

  TagManager.dataLayer(tagManagerArgs);

  return true;
};

export const sendMetaEvent = (type, ...configs) => {
  if (!("fbq" in window)) return false;

  window.fbq(type, ...configs);

  return true;
};

export const hexToRgb = (hex) => {
  if (!hex || !hex.startsWith("#") || hex.length !== 7) return false;

  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);

  return `${r}, ${g}, ${b}`;
};

export const getBaseCategoryCode = () => {
  const activeCategory = window.location.pathname.split("/")[1];
  if (!activeCategory) return false;

  let categoryCode = 0;

  switch (activeCategory) {
    case "icerik-ureticiligi":
      categoryCode = 0;
      break;
    case "muzik":
      categoryCode = 1;
      break;
    case "oyunculuk":
      categoryCode = 2;
      break;
    case "kurumsal":
      categoryCode = 3;
      break;
    case "workshop":
      categoryCode = 4;
      break;
    default:
      break;
  }

  return categoryCode;
};

export const getModulePath = (insetPath) => {
  const paths = window.location.pathname.split("/").slice(1);
  const modulePath = insetPath ? [...paths, insetPath] : paths;

  return modulePath.join(" > ");
};

export const getRoute = (route, params) => {
  const allRoutes = getAllRoutes();

  const foundRoute = allRoutes.find((r) => r.id === route);

  if (!foundRoute) return null;

  if (params) foundRoute.link = formatLink(foundRoute.link, params);

  return foundRoute || null;
};

export const getAllRoutes = () => {
  const allRoutes = [
    ...global,
    ...guest,
    ...auth,
    ...topMenu,
    ...global
      ?.filter((route) => route.pages)
      ?.flatMap((route) =>
        route?.pages?.flatMap((page) => ({
          ...page,
          link: `${route.link}/${page.link}`,
        }))
      ),
    ...auth
      ?.filter((route) => route.pages)
      ?.flatMap((route) =>
        route?.pages?.flatMap((page) => ({
          ...page,
          link: `${route.link}/${page.link}`,
        }))
      ),
    ...guest
      ?.filter((route) => route.pages)
      ?.flatMap((route) =>
        route?.pages?.flatMap((page) => ({
          ...page,
          link: `${route.link}/${page.link}`,
        }))
      ),
  ];

  return allRoutes;
};

export const getRouteByQuery = (query) =>
  getAllRoutes().find((r) => r.query === query);

export const formatLink = (link, params) =>
  link
    ?.replace(":category", params["category"] || cookies?.get("activeRoute"))
    .replace(/:(\w+)[?]?/g, (match, p1) => params[p1]);

export const getIconByCategory = ({ iconMainKey, className }) => {
  const cookies = new Cookies();
  const activeCategory = window.location.pathname.split("/")[1];
  const activeCategoryIsCorrect = topMenu.includes(activeCategory);

  const theme = cookies.get("theme") || process.env.REACT_APP_DEFAULT_THEME;
  const activeRoute =
    cookies.get("activeRoute") ||
    (activeCategoryIsCorrect && activeCategory) ||
    "icerik-ureticiligi";

  const currentTheme =
    theme === process.env.REACT_APP_DARK_THEME_NAME ? "dark" : "light";

  const imageUrl = icons[iconMainKey]?.[activeRoute]?.[currentTheme];

  return {
    imageUrl,
    imageNode: (
      <img
        src={imageUrl}
        alt={iconMainKey.replace("-", " ")}
        className={className}
      />
    ),
  };
};
