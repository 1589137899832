// add context here
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import Cookies from "universal-cookie";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState();
  const [moduleTree, setModuleTree] = useState();
  const [pathname, setPathname] = useState();

  const cookies = new Cookies();

  const createModuleTree = (insetPath = null) => {
    const paths = localStorage.getItem("module-path");
    let moduleTreeList = [];

    if (paths.includes("/iletisime-gec"))
      moduleTreeList = paths.split("/").slice(1, -1);
    else moduleTreeList = paths.split("/").slice(1);

    const modulePath = insetPath
      ? [...moduleTreeList, insetPath].join(" > ")
      : moduleTreeList.join(" > ");

    setModuleTree(modulePath);
  };

  useEffect(() => {
    const currentRoute = cookies.get("activeRoute") || "icerik-ureticiligi";
    setActiveCategory(currentRoute);

    localStorage.setItem("module-path", pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const formatLink = (link) => link?.replace(":category", activeCategory);

  return (
    <AppContext.Provider
      value={{
        activeCategory,
        setActiveCategory,
        moduleTree,
        createModuleTree,
        setPathname,
        formatLink,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
