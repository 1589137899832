import React from "react";
import { Card } from "./Card";
import { getIconByCategory } from "../../../../utils/helper";

export const PaymentMethods = ({
  cards,
  handleSelectDefault,
  handleDeleteCard,
  toggleAddingPaymentMethod,
  newCardArea,
}) => {
  return (
    <div className="payment-methods d-flex flex-wrap justify-content-between align-items-center">
      {cards.map((card) => (
        <Card
          key={card.id}
          {...card}
          handleSelectDefault={handleSelectDefault}
          handleDeleteCard={handleDeleteCard}
        />
      ))}
      {newCardArea && (
        <div className="add-new-card-button d-flex flex-column justify-content-center align-items-center position-relative">
          {
            getIconByCategory({
              iconMainKey: "new-payment-cart-icon",
            })?.["imageNode"]
          }
          <div
            className="text stretched-link cursor--pointer"
            onClick={toggleAddingPaymentMethod}
          >
            Yeni Kredi Kartı Ekle
          </div>
        </div>
      )}
    </div>
  );
};
