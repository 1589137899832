import React, { Component } from "react";
import { PageTitle } from "../utils/components/PageTitle";
import { Sidebar } from "./components/Sidebar";

export default class Profile extends Component {
  render() {
    return (
      <div className="Profile position-relative">
        <PageTitle title="Profilim" className="d-none d-lg-block" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3">
              <Sidebar />
            </div>
            <div className="col-12 col-md-10 col-lg-7 offset-md-1 offset-lg-0">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
