import emptySubsIcon from "../../assets/images/empty-subscription-icon.svg";
import emptySubsIconDark from "../../assets/images/empty-subscription-icon-dark.svg";

import emptyPaymentInfoIcon from "../../assets/images/empty-payment-info-icon.svg";
import emptyPaymentInfoIconDark from "../../assets/images/empty-payment-info-icon-dark.svg";

import emptyPaymentHistoryIcon from "../../assets/images/empty-payment-history-icon.svg";
import emptyPaymentHistoryIconDark from "../../assets/images/empty-payment-history-icon-dark.svg";

import newCardIcon from "../../assets/images/add-new-card-icon.svg";
import newCardIconDark from "../../assets/images/add-new-card-icon-dark.svg";

import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo-dark.svg";
import logoGray from "../../assets/images/logo-gray.svg";
import logoGrayDark from "../../assets/images/logo-gray-dark.svg";
import logoAZLight from "../../assets/images/logo-az-dark.svg";
import logoAZDark from "../../assets/images/logo-az-light.svg";

import mediaLogo from "../../assets/images/media-logo-gray.svg";
import mediaLogoDark from "../../assets/images/media-logo-gray-dark.svg";

import emptyBillingIcon from "../../assets/images/empty-billing-info-icon.svg";
import emptyBillingIconDark from "../../assets/images/empty-billing-info-icon-dark.svg";

import iyzicoIcon from "../../assets/images/iyzico.svg";
import iyzicoIconDark from "../../assets/images/iyzico-dark.svg";

import cartCompleteIcon from "../../assets/images/cart-complete-icon.svg";
import cartCompleteIconDark from "../../assets/images/cart-complete-icon-dark.svg";

import iyzicoColoredIcon from "../../assets/images/iyzico_colored.svg";
import iyzicoColoredIconDark from "../../assets/images/iyzico_colored.svg";

import checkIcon from "../../assets/images/check.svg";

// Category Icons
import icerikIconLight from "../../assets/images/creator-light.svg";
import icerikIconDark from "../../assets/images/creator-dark.svg";
import kurumsalIconLight from "../../assets/images/kurumsal-light.svg";
import kurumsalIconDark from "../../assets/images/kurumsal-dark.svg";
import muzikIconLight from "../../assets/images/muzik-az-light.svg";
import muzikIconDark from "../../assets/images/muzik-az-dark.svg";
import oyunculukIconLight from "../../assets/images/oyunculuk-light.svg";
import oyunculukIconDark from "../../assets/images/oyunculuk-dark.svg";
import workshopIconLight from "../../assets/images/workshop-light.svg";
import workshopIconDark from "../../assets/images/workshop-dark.svg";

// ContactWith Sent Icons
import sentIconCreatorLight from "../../assets/images/sent-info-icon/light-theme/creator.svg";
import sentIconCreatorDark from "../../assets/images/sent-info-icon/dark-theme/creator.svg";
import sentIconMusicLight from "../../assets/images/sent-info-icon/light-theme/music.svg";
import sentIconMusicDark from "../../assets/images/sent-info-icon/dark-theme/music.svg";
import sentIconCorporateLight from "../../assets/images/sent-info-icon/light-theme/corporate.svg";
import sentIconCorporateDark from "../../assets/images/sent-info-icon/dark-theme/corporate.svg";
import sentIconActingLight from "../../assets/images/sent-info-icon/light-theme/acting.svg";
import sentIconActingDark from "../../assets/images/sent-info-icon/dark-theme/acting.svg";
import sentIconWorkshopLight from "../../assets/images/sent-info-icon/light-theme/workshop.svg";
import sentIconWorkshopDark from "../../assets/images/sent-info-icon/dark-theme/workshop.svg";

//Empty Cart Icons
import emptyCartCreatorLight from "../../assets/images/empty-cart/light-theme/creator.svg";
import emptyCartCreatorDark from "../../assets/images/empty-cart/dark-theme/creator.svg";
import emptyCartMusicLight from "../../assets/images/empty-cart/light-theme/music.svg";
import emptyCartMusicDark from "../../assets/images/empty-cart/dark-theme/music.svg";
import emptyCartCorporateLight from "../../assets/images/empty-cart/light-theme/corporate.svg";
import emptyCartCorporateDark from "../../assets/images/empty-cart/dark-theme/corporate.svg";
import emptyCartActingLight from "../../assets/images/empty-cart/light-theme/acting.svg";
import emptyCartActingDark from "../../assets/images/empty-cart/dark-theme/acting.svg";
import emptyCartWorkshopLight from "../../assets/images/empty-cart/light-theme/workshop.svg";
import emptyCartWorkshopDark from "../../assets/images/empty-cart/dark-theme/workshop.svg";

//Empty Payment Info Icons
import emptyPaymentInfoCreatorLight from "../../assets/images/empty-payment-info/light-theme/creator.svg";
import emptyPaymentInfoCreatorDark from "../../assets/images/empty-payment-info/dark-theme/creator.svg";
import emptyPaymentInfoMusicLight from "../../assets/images/empty-payment-info/light-theme/music.svg";
import emptyPaymentInfoMusicDark from "../../assets/images/empty-payment-info/dark-theme/music.svg";
import emptyPaymentInfoCorporateLight from "../../assets/images/empty-payment-info/light-theme/corporate.svg";
import emptyPaymentInfoCorporateDark from "../../assets/images/empty-payment-info/dark-theme/corporate.svg";
import emptyPaymentInfoActingLight from "../../assets/images/empty-payment-info/light-theme/acting.svg";
import emptyPaymentInfoActingDark from "../../assets/images/empty-payment-info/dark-theme/acting.svg";
import emptyPaymentInfoWorkshopLight from "../../assets/images/empty-payment-info/light-theme/workshop.svg";
import emptyPaymentInfoWorkshopDark from "../../assets/images/empty-payment-info/dark-theme/workshop.svg";

//Empty Billing Info Icons
import emptyBillingInfoCreatorLight from "../../assets/images/empty-billing-info/light-theme/creator.svg";
import emptyBillingInfoCreatorDark from "../../assets/images/empty-billing-info/dark-theme/creator.svg";
import emptyBillingInfoMusicLight from "../../assets/images/empty-billing-info/light-theme/music.svg";
import emptyBillingInfoMusicDark from "../../assets/images/empty-billing-info/dark-theme/music.svg";
import emptyBillingInfoCorporateLight from "../../assets/images/empty-billing-info/light-theme/corporate.svg";
import emptyBillingInfoCorporateDark from "../../assets/images/empty-billing-info/dark-theme/corporate.svg";
import emptyBillingInfoActingLight from "../../assets/images/empty-billing-info/light-theme/acting.svg";
import emptyBillingInfoActingDark from "../../assets/images/empty-billing-info/dark-theme/acting.svg";
import emptyBillingInfoWorkshopLight from "../../assets/images/empty-billing-info/light-theme/workshop.svg";
import emptyBillingInfoWorkshopDark from "../../assets/images/empty-billing-info/dark-theme/workshop.svg";

// New Payment Card Icons
import newPaymentCartCreatorLight from "../../assets/images/new-payment-cart/light-theme/creator.svg";
import newPaymentCartCreatorDark from "../../assets/images/new-payment-cart/dark-theme/creator.svg";
import newPaymentCartMusicLight from "../../assets/images/new-payment-cart/light-theme/music.svg";
import newPaymentCartMusicDark from "../../assets/images/new-payment-cart/dark-theme/music.svg";
import newPaymentCartCorporateLight from "../../assets/images/new-payment-cart/light-theme/corporate.svg";
import newPaymentCartCorporateDark from "../../assets/images/new-payment-cart/dark-theme/corporate.svg";
import newPaymentCartActingLight from "../../assets/images/new-payment-cart/light-theme/acting.svg";
import newPaymentCartActingDark from "../../assets/images/new-payment-cart/dark-theme/acting.svg";
import newPaymentCartWorkshopLight from "../../assets/images/new-payment-cart/light-theme/workshop.svg";
import newPaymentCartWorkshopDark from "../../assets/images/new-payment-cart/dark-theme/workshop.svg";

// Empty Subscription Plan Icons
import emptySubscriptionPlanCreatorLight from "../../assets/images/empty-subscription-plan/light-theme/creator.svg";
import emptySubscriptionPlanCreatorDark from "../../assets/images/empty-subscription-plan/dark-theme/creator.svg";
import emptySubscriptionPlanMusicLight from "../../assets/images/empty-subscription-plan/light-theme/music.svg";
import emptySubscriptionPlanMusicDark from "../../assets/images/empty-subscription-plan/dark-theme/music.svg";
import emptySubscriptionPlanCorporateLight from "../../assets/images/empty-subscription-plan/light-theme/corporate.svg";
import emptySubscriptionPlanCorporateDark from "../../assets/images/empty-subscription-plan/dark-theme/corporate.svg";
import emptySubscriptionPlanActingLight from "../../assets/images/empty-subscription-plan/light-theme/acting.svg";
import emptySubscriptionPlanActingDark from "../../assets/images/empty-subscription-plan/dark-theme/acting.svg";
import emptySubscriptionPlanWorkshopLight from "../../assets/images/empty-subscription-plan/light-theme/workshop.svg";
import emptySubscriptionPlanWorkshopDark from "../../assets/images/empty-subscription-plan/dark-theme/workshop.svg";

//Empty Payment History Icons
import emptyPaymentHistoryCreatorLight from "../../assets/images/empty-payment-history/light-theme/creator.svg";
import emptyPaymentHistoryCreatorDark from "../../assets/images/empty-payment-history/dark-theme/creator.svg";
import emptyPaymentHistoryMusicLight from "../../assets/images/empty-payment-history/light-theme/music.svg";
import emptyPaymentHistoryMusicDark from "../../assets/images/empty-payment-history/dark-theme/music.svg";
import emptyPaymentHistoryCorporateLight from "../../assets/images/empty-payment-history/light-theme/corporate.svg";
import emptyPaymentHistoryCorporateDark from "../../assets/images/empty-payment-history/dark-theme/corporate.svg";
import emptyPaymentHistoryActingLight from "../../assets/images/empty-payment-history/light-theme/acting.svg";
import emptyPaymentHistoryActingDark from "../../assets/images/empty-payment-history/dark-theme/acting.svg";
import emptyPaymentHistoryWorkshopLight from "../../assets/images/empty-payment-history/light-theme/workshop.svg";
import emptyPaymentHistoryWorkshopDark from "../../assets/images/empty-payment-history/dark-theme/workshop.svg";

// Payment Complete Icons
import paymentCompleteCreatorLight from "../../assets/images/payment-complete/light-theme/creator.svg";
import paymentCompleteCreatorDark from "../../assets/images/payment-complete/dark-theme/creator.svg";
import paymentCompleteMusicLight from "../../assets/images/payment-complete/light-theme/music.svg";
import paymentCompleteMusicDark from "../../assets/images/payment-complete/dark-theme/music.svg";
import paymentCompleteCorporateLight from "../../assets/images/payment-complete/light-theme/corporate.svg";
import paymentCompleteCorporateDark from "../../assets/images/payment-complete/dark-theme/corporate.svg";
import paymentCompleteActingLight from "../../assets/images/payment-complete/light-theme/acting.svg";
import paymentCompleteActingDark from "../../assets/images/payment-complete/dark-theme/acting.svg";
import paymentCompleteWorkshopLight from "../../assets/images/payment-complete/light-theme/workshop.svg";
import paymentCompleteWorkshopDark from "../../assets/images/payment-complete/dark-theme/workshop.svg";

const icons = {
  emptySubscriptionPlan: { light: emptySubsIcon, dark: emptySubsIconDark },
  emptyPaymentInfo: {
    light: emptyPaymentInfoIcon,
    dark: emptyPaymentInfoIconDark,
  },
  emptyPaymentHistory: {
    light: emptyPaymentHistoryIcon,
    dark: emptyPaymentHistoryIconDark,
  },
  newPaymentCard: {
    light: newCardIcon,
    dark: newCardIconDark,
  },
  logo: { light: logo, dark: logoDark },
  logoGray: { light: logoGray, dark: logoGrayDark },
  logoAZ: { light: logoAZLight, dark: logoAZDark },
  mediaLogo: { light: mediaLogo, dark: mediaLogoDark },
  emptyBillingIcon: {
    light: emptyBillingIcon,
    dark: emptyBillingIconDark,
  },
  iyzico: {
    light: iyzicoIcon,
    dark: iyzicoIconDark,
  },
  cartComplete: {
    light: cartCompleteIcon,
    dark: cartCompleteIconDark,
  },
  iyzicoColored: {
    light: iyzicoColoredIcon,
    dark: iyzicoColoredIconDark,
  },
  check: {
    light: checkIcon,
    dark: checkIcon,
  },
  "icerik-ureticiligi": {
    light: icerikIconLight,
    dark: icerikIconDark,
  },
  kurumsal: {
    light: kurumsalIconLight,
    dark: kurumsalIconDark,
  },
  muzik: {
    light: muzikIconLight,
    dark: muzikIconDark,
  },
  oyunculuk: {
    light: oyunculukIconLight,
    dark: oyunculukIconDark,
  },
  workshop: {
    light: workshopIconLight,
    dark: workshopIconDark,
  },
  "contact-form-sent-icon": {
    "icerik-ureticiligi": {
      light: sentIconCreatorLight,
      dark: sentIconCreatorDark,
    },
    kurumsal: {
      light: sentIconCorporateLight,
      dark: sentIconCorporateDark,
    },
    muzik: {
      light: sentIconMusicLight,
      dark: sentIconMusicDark,
    },
    oyunculuk: {
      light: sentIconActingLight,
      dark: sentIconActingDark,
    },
    workshop: {
      light: sentIconWorkshopLight,
      dark: sentIconWorkshopDark,
    },
  },

  "empty-cart-icon": {
    "icerik-ureticiligi": {
      light: emptyCartCreatorLight,
      dark: emptyCartCreatorDark,
    },
    kurumsal: {
      light: emptyCartCorporateLight,
      dark: emptyCartCorporateDark,
    },
    muzik: {
      light: emptyCartMusicLight,
      dark: emptyCartMusicDark,
    },
    oyunculuk: {
      light: emptyCartActingLight,
      dark: emptyCartActingDark,
    },
    workshop: {
      light: emptyCartWorkshopLight,
      dark: emptyCartWorkshopDark,
    },
  },

  "empty-payment-info-icon": {
    "icerik-ureticiligi": {
      light: emptyPaymentInfoCreatorLight,
      dark: emptyPaymentInfoCreatorDark,
    },
    kurumsal: {
      light: emptyPaymentInfoCorporateLight,
      dark: emptyPaymentInfoCorporateDark,
    },
    muzik: {
      light: emptyPaymentInfoMusicLight,
      dark: emptyPaymentInfoMusicDark,
    },
    oyunculuk: {
      light: emptyPaymentInfoActingLight,
      dark: emptyPaymentInfoActingDark,
    },
    workshop: {
      light: emptyPaymentInfoWorkshopLight,
      dark: emptyPaymentInfoWorkshopDark,
    },
  },

  "empty-billing-info-icon": {
    "icerik-ureticiligi": {
      light: emptyBillingInfoCreatorLight,
      dark: emptyBillingInfoCreatorDark,
    },
    kurumsal: {
      light: emptyBillingInfoCorporateLight,
      dark: emptyBillingInfoCorporateDark,
    },
    muzik: {
      light: emptyBillingInfoMusicLight,
      dark: emptyBillingInfoMusicDark,
    },
    oyunculuk: {
      light: emptyBillingInfoActingLight,
      dark: emptyBillingInfoActingDark,
    },
    workshop: {
      light: emptyBillingInfoWorkshopLight,
      dark: emptyBillingInfoWorkshopDark,
    },
  },

  "new-payment-cart-icon": {
    "icerik-ureticiligi": {
      light: newPaymentCartCreatorLight,
      dark: newPaymentCartCreatorDark,
    },
    kurumsal: {
      light: newPaymentCartCorporateLight,
      dark: newPaymentCartCorporateDark,
    },
    muzik: {
      light: newPaymentCartMusicLight,
      dark: newPaymentCartMusicDark,
    },
    oyunculuk: {
      light: newPaymentCartActingLight,
      dark: newPaymentCartActingDark,
    },
    workshop: {
      light: newPaymentCartWorkshopLight,
      dark: newPaymentCartWorkshopDark,
    },
  },

  "empty-subscription-plan-icon": {
    "icerik-ureticiligi": {
      light: emptySubscriptionPlanCreatorLight,
      dark: emptySubscriptionPlanCreatorDark,
    },
    kurumsal: {
      light: emptySubscriptionPlanCorporateLight,
      dark: emptySubscriptionPlanCorporateDark,
    },
    muzik: {
      light: emptySubscriptionPlanMusicLight,
      dark: emptySubscriptionPlanMusicDark,
    },
    oyunculuk: {
      light: emptySubscriptionPlanActingLight,
      dark: emptySubscriptionPlanActingDark,
    },
    workshop: {
      light: emptySubscriptionPlanWorkshopLight,
      dark: emptySubscriptionPlanWorkshopDark,
    },
  },

  "empty-payment-history-icon": {
    "icerik-ureticiligi": {
      light: emptyPaymentHistoryCreatorLight,
      dark: emptyPaymentHistoryCreatorDark,
    },
    kurumsal: {
      light: emptyPaymentHistoryCorporateLight,
      dark: emptyPaymentHistoryCorporateDark,
    },
    muzik: {
      light: emptyPaymentHistoryMusicLight,
      dark: emptyPaymentHistoryMusicDark,
    },
    oyunculuk: {
      light: emptyPaymentHistoryActingLight,
      dark: emptyPaymentHistoryActingDark,
    },
    workshop: {
      light: emptyPaymentHistoryWorkshopLight,
      dark: emptyPaymentHistoryWorkshopDark,
    },
  },

  "payment-complete-icon": {
    "icerik-ureticiligi": {
      light: paymentCompleteCreatorLight,
      dark: paymentCompleteCreatorDark,
    },
    kurumsal: {
      light: paymentCompleteCorporateLight,
      dark: paymentCompleteCorporateDark,
    },
    muzik: {
      light: paymentCompleteMusicLight,
      dark: paymentCompleteMusicDark,
    },
    oyunculuk: {
      light: paymentCompleteActingLight,
      dark: paymentCompleteActingDark,
    },
    workshop: {
      light: paymentCompleteWorkshopLight,
      dark: paymentCompleteWorkshopDark,
    },
  },
};

export default icons;
