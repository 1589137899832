import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import API, { headers } from "../../utils/API";
import { getRoute, handleErrors } from "../../utils/helper";
import { connect } from "react-redux";

const TYPES = {
  "face-to-face-courses": 0,
  "crowd-management": 1,
};

const CoursesNav = (props) => {
  const [underline, setUnderline] = useState({ width: 0, left: 0 });
  const [courses, setCourses] = useState([]);

  const getSubCategories = () => {
    if (props.page === "") return;

    const apiURL =
      props.page === "face-to-face-courses" || props.page === "crowd-management"
        ? `CorporateCategory/SubCategories?type=${TYPES[props.page]}`
        : `Category/SubCategories`;

    API.get(apiURL, {
      headers: { ...headers },
    })
      .then(({ data: res }) => {
        const { data } = res;

        const route = getRoute(props.page);

        let courses = data
          .filter((cat) => cat.id !== 117)
          .sort((a, b) => a.id - b.id)
          .map((cat) => ({
            ...cat,
            link: getRoute(route?.id, { slug: cat.slug })?.link,
          }));

        setCourses(courses);

        if (`setCourses` in props) props.setCourses(courses);

        if (`setSelectedCategory` in props && `course` in props)
          props.setSelectedCategory(
            data.find((cat) => String(cat.slug) === String(props.course))
          );
      })
      .catch((err) => handleErrors(err));
  };

  useEffect(() => {
    getSubCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page, props.user]);

  const checkUnderline = () => {
    const activeEl = document.querySelector(".course-cats .active");

    if (
      activeEl &&
      (activeEl.width !== underline.width || activeEl.left !== underline.left)
    ) {
      const params = { width: activeEl.clientWidth, left: activeEl.offsetLeft };

      setUnderline(params);

      activeEl.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkUnderline, [document.querySelector(".course-cats .active")]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener("resize", checkUnderline, false);

    return () => {
      window.removeEventListener("resize", checkUnderline, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="CoursesNav overflow-auto">
      <div className="course-cats d-flex justify-content-start align-items-center position-relative">
        {courses.map((cat) => (
          <NavLink key={cat.id} to={cat.link} className="cat">
            {cat.name}
          </NavLink>
        ))}

        <div
          className="underline position-absolute d-none"
          style={{
            width: underline?.width || 0,
            left: underline?.left || 0,
          }}
        ></div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(CoursesNav);
