import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router";
import API from "../utils/API";
import { handleErrors } from "../utils/helper";

const AGREEMENT_TYPE = {
  "mesafeli-satis-sozlesmesi": "4",
  "gizlilik-ve-guvenlik-politikasi": "5",
  "iade-islemleri": "7",
  "kvkk-aydinlatma-metni": "10",
  "cerez-politikasi": "11",
  "uyelik-sozlesmesi": "12",
};

const Agreement = () => {
  const { name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const requestAgreement = () => {
      if (!(name in AGREEMENT_TYPE)) navigate("/404");
      else {
        const req = `Content/${AGREEMENT_TYPE[name]}`;
        API.get(req)
          .then(({ data }) => {
            const { title, body } = data.data;
            setTitle(title);
            setContent(body);
          })
          .catch((err) => handleErrors(err));
      }
    };

    requestAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="agreement-page position-relative">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="inner"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreement;
