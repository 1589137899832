/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import HAInput from "../utils/components/HAInput";
import { HAButton } from "../utils/components/HAButton";
import { InfoArea } from "../utils/components/InfoArea";
import { Link } from "react-router-dom";
import HACheckbox from "../utils/components/HACheckbox";
import {
  getIconByCategory,
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
  validate,
} from "../utils/helper";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { AppContext } from "../App/context/appContext";
import icons from "../utils/db/icons";
import API from "../utils/API";

const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;

const ContactWith = ({ theme, showInfoBanner }) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [contactIsSent, setContactIsSent] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([]);
  const [recaptchaTheme, setRecaptchaTheme] = useState("light");
  const [recaptchaKey, setRecaptchaKey] = useState(0);
  const { moduleTree, createModuleTree } = useContext(AppContext);

  const [contactInfos, setContactInfos] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    message: "",
    agreements: false,
    recaptchaCode: "",
  });

  const handleChange = ({ target }) => {
    const { type, name, value, checked } = target;
    const { email, phone } = contactInfos;

    if (name === "email" && value) {
      if (!validate(value, "email")) setErrors([...errors, name]);
      else resetCurrentError(name);
    }

    if (name === "phone") {
      if (!validate(value, "phone")) setErrors([...errors, name]);
      else resetCurrentError(name);
    }

    setContactInfos({
      ...contactInfos,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const resetCurrentError = (key) => {
    if (errors.includes(key))
      setErrors(errors.filter((error) => error !== key));
  };

  useEffect(() => {
    const formIsValid =
      Object.values(contactInfos).every((value) => value !== "") &&
      !errors.length &&
      !error &&
      contactInfos.agreements;

    if (formIsValid) setIsButtonEnabled(true);
    else setIsButtonEnabled(false);
  }, [contactInfos, error, errors]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isSending) setIsSending(true);

    sendGAEvent("event", "generate_lead");
    sendMetaEvent("track", "Lead");

    const requestData = {
      firstName: contactInfos.firstName,
      lastName: contactInfos.lastName,
      companyName: contactInfos.companyName,
      email: contactInfos.email,
      phoneNumber: contactInfos.phone,
      message: contactInfos.message,
      contactPermission: contactInfos.agreements,
      recaptcha: contactInfos.recaptchaCode,
      moduleName: moduleTree,
    };

    API.post("Contact/Request", requestData)
      .then(({ data }) => {
        setIsSending(false);
        setContactIsSent(true);
        resetForm();
      })
      .catch((err) => {
        handleErrors(err);
        showInfoBanner(err, "error", 1500);
      });
  };

  useEffect(() => {
    if (!moduleTree) createModuleTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    setContactInfos({
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phone: "",
      message: "",
      agreements: false,
    });
  };

  useEffect(() => {
    if (contactIsSent) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [contactIsSent]);

  useEffect(() => {
    setRecaptchaTheme(theme);
    setRecaptchaKey(theme === "light" ? 1 : 0);
  }, [theme]);

  return (
    <div className="ContactWith w-100">
      <div className="container-fluid">
        {!contactIsSent ? (
          <div className="row">
            <div className="col-12 col-md-5 mx-auto">
              <form
                action="#!"
                onSubmit={handleSubmit}
                className="d-flex flex-column shadow-sm"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-column gap-2 mb-2">
                      <h6 className="title">İletişime Geç</h6>
                      <p className="description">
                        Kurumsal eğitimlerimizle ilgili daha detaylı bilgi
                        almak, toplu alım fırsatlarından yararlanmak veya geri
                        bildirimleriniz için lütfen aşağıdaki formu doldurun, en
                        kısa sürede size dönüş yapalım!
                      </p>
                      {error && <InfoArea type="error">{error}</InfoArea>}
                    </div>
                  </div>
                  <div className="col-12 half-inputs">
                    <HAInput
                      name="firstName"
                      type="text"
                      value={contactInfos.firstName}
                      onChange={handleChange}
                      label="Adınız"
                      error={errors.includes("firstName")}
                    />
                    <HAInput
                      name="lastName"
                      type="text"
                      value={contactInfos.lastName}
                      onChange={handleChange}
                      label="Soyadınız"
                      error={errors.includes("lastName")}
                    />
                  </div>

                  <div className="col-12">
                    <HAInput
                      name="companyName"
                      type="text"
                      value={contactInfos.companyName}
                      onChange={handleChange}
                      label="Şirket Adı"
                      error={errors.includes("companyName")}
                    />
                  </div>
                  <div className="col-12">
                    <HAInput
                      name="email"
                      type="email"
                      value={contactInfos.email}
                      onChange={handleChange}
                      label="E-Posta Adresiniz"
                      error={errors.includes("email")}
                    />
                  </div>
                  <div className="col">
                    <HAInput
                      type="tel"
                      name="phone"
                      value={contactInfos.phone}
                      label="Telefon Numaranız"
                      onChange={handleChange}
                      formatNumber
                      format="(###) ### ####"
                      error={errors.includes("phone")}
                    />
                  </div>
                  <div className="col-12">
                    <HAInput
                      name="message"
                      type="textarea"
                      value={contactInfos.message}
                      onChange={handleChange}
                      label="Mesajınız"
                      error={errors.includes("message")}
                    />
                  </div>
                  <div className="col-12">
                    <HACheckbox
                      name="agreements"
                      checked={contactInfos.agreements}
                      onChange={handleChange}
                      error={errors.includes("agreements")}
                    >
                      <>
                        <Link
                          to={getRoute("agreement-page")?.link?.replace(
                            ":name",
                            "kvkk-aydinlatma-metni"
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          KVKK Aydınlatma Metni
                        </Link>{" "}
                        ve{" "}
                        <Link
                          to={getRoute("agreement-page")?.link?.replace(
                            ":name",
                            "uyelik-sozlesmesi"
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Üyelik Sözleşmesi
                        </Link>
                        ’ni okudum ve onaylıyorum.
                      </>
                    </HACheckbox>
                  </div>
                  <div className="col-12 my-2">
                    <ReCAPTCHA
                      key={recaptchaKey}
                      sitekey={SITEKEY}
                      theme={recaptchaTheme}
                      onChange={(value) =>
                        setContactInfos({
                          ...contactInfos,
                          recaptchaCode: value,
                        })
                      }
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <HAButton
                      className="w-100 login-button mt-2"
                      disabled={!isButtonEnabled}
                      action="submit"
                      isLoading={isSending}
                    >
                      İletişime Geç
                    </HAButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center info-container">
            <div className="col-10 col-md-4 d-flex flex-column align-items-center">
              {
                getIconByCategory({ iconMainKey: "contact-form-sent-icon" })[
                  "imageNode"
                ]
              }
              <span className="info-message text-center">
                Talebinizi aldık. En kısa sürede dönüş yapacağız. Hypers Academy
                Ekibi
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapPropsToState = (state) => ({
  theme:
    state.app.app.theme === process.env.REACT_APP_DARK_THEME_NAME
      ? "dark"
      : "light",
});

export default connect(mapPropsToState)(ContactWith);
