import React, { Component } from "react";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

export default class HASwitch extends Component {
  id = uniqueId();
  compRef = React.createRef(null);

  render() {
    const { name, checked, onChange, children } = this.props;
    return (
      <div
        className="HASwitch"
        id={this.id}
        ref={`refProp` in this.props ? this.props.ref : this.compRef}
      >
        <input
          type="checkbox"
          name={name}
          id={`switch-checkbox-${this.id}`}
          checked={checked}
          onChange={onChange}
          className="d-none"
        />

        <label
          htmlFor={`switch-checkbox-${this.id}`}
          className="d-flex justify-content-start align-items-center"
        >
          <div className="switch-holder d-flex justify-content-start align-items-center" />
          <p className="content">{children}</p>
        </label>
      </div>
    );
  }
}

HASwitch.defaultProps = {
  checked: false,
  onChange: () => {},
  children: "",
};

HASwitch.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any,
};
