import React from "react";
import CorporateTestimonialCard from "./CorporateTestimonialCard";

const CorporateTestimonials = ({ corporateTestimonials }) => {
  return (
    <div className="CorporateTestimonials container-fluid py-4">
      {corporateTestimonials.map((testimonial, index) => (
        <CorporateTestimonialCard key={index} testimonial={testimonial} />
      ))}
    </div>
  );
};

export default CorporateTestimonials;
