import React, { useEffect, useState } from "react";
import useAppContext from "../../utils/hooks/useAppContext";
import { useLocation, useNavigate, useParams } from "react-router";
import CoursesNav from "../components/CoursesNav";
import SubCoursesIntro from "../SubCourses/components/SubCoursesIntro";
import API from "../../utils/API";
import {
  formatLink,
  getCurrentRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import CorporatePresentationCard from "./components/CorporatePresentationCard";
import { setActiveSubRoute } from "../../App/appActions";
import { connect } from "react-redux";
import Slider from "../../Explore/components/Slider";

const CustomCourses = ({ setActiveSubRoute: setActiveSubRouteWithRedux }) => {
  const { activeCategory } = useAppContext();
  const { pathname } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();

  const [activeSubRoute, setActiveSubRoute] = useState("");
  const [courses, setCourses] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const [carousels, setCarousels] = useState();

  const getWorkshopCategoryDetails = () => getCategoryDetails("Workshops");

  const getCorporateCategoryDetails = () => {
    const url = `CorporateCategory/SubCategories/${currentTab?.id}`;
    getCategoryDetails(url);

    sendGAEvent("page_view", "view_item", {
      items: [
        {
          item_id: currentTab?.id,
          item_name: currentTab?.name,
        },
      ],
    });

    sendMetaEvent("track", "ViewContent", {
      content_ids: [currentTab?.id],
      content_type: "product",
    });
  };

  const getCategoryDetails = (url) => {
    API.get(url)
      .then(({ data: res }) => {
        const { corporateCarousel } = res.data;
        const { data } = res;
        setCategoryDetails({
          id: data.id,
          name: data.name,
          slug: data.slug,
          title: data.title,
          description: data.description,
          playerEmbedUrl: data.playerEmbedUrl,
          videoThumbnail: data.videoThumbnail,
          imageUrl: data.imageUrl,
          redirectUrl: data.redirectURL,
        });

        setCarousels(
          corporateCarousel &&
            corporateCarousel.map((carousel) => ({
              title: carousel.title,
              description: carousel.description,
              internalLink: carousel.internalLink,
              carouselList: carousel.corporateCarouselList.map((item) => ({
                carouselVideo: item.carouselVideo,
                playerEmbedUrl: item.playerEmbedUrl,
                videoThumbnail: item.videoThumbnail,
                carouselVideoUrl: item.carouselVideoUrl,
                carouselImageUrl: item.carouselImageUrl,
                imageUrl: item.imageUrl,
              })),
            }))
        );
      })
      .catch((err) => handleErrors(err));
  };

  const handlePathname = () => {
    let defaultSlug = "";

    if (slug && courses.find((c) => c.slug === slug)) defaultSlug = slug;
    else defaultSlug = courses[0]?.slug;

    const currentRoute = getCurrentRoute(pathname);

    if (currentRoute?.route?.link)
      navigate(formatLink(currentRoute?.route?.link, { slug: defaultSlug }));
  };

  useEffect(() => {
    if (courses && activeSubRoute && courses.length > 0) handlePathname();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  useEffect(() => {
    const currentRoute = getCurrentRoute(pathname);
    if (currentRoute?.route?.id) {
      setActiveSubRoute(currentRoute?.route?.id);
      setActiveSubRouteWithRedux(currentRoute?.route?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (courses.length > 0) setCurrentTab(courses.find((c) => c.slug === slug));
  }, [courses, slug]);

  useEffect(() => {
    if (currentTab) getCorporateCategoryDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setCategoryDetails(null);
    setCarousels(null);
    setCurrentTab(null);
    setCourses([]);
  }, [activeSubRoute]);

  useEffect(() => {
    if (activeCategory && activeCategory === "workshop")
      getWorkshopCategoryDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  return (
    <div className="SubCoursesPage CoursesPage CustomCourses">
      {activeCategory === "workshop" && (
        <div className="Explore">
          <Slider activeRoute={activeCategory} />
        </div>
      )}
      <div className="container-fluid">
        {activeCategory !== "workshop" && (
          <div className="row">
            <div className="col-12">
              <CoursesNav
                course={slug}
                page={activeSubRoute}
                setCourses={setCourses}
              />
            </div>
          </div>
        )}
        {activeCategory === "kurumsal" && (
          <div className="row">
            <div className="col-12">
              <SubCoursesIntro
                title={categoryDetails?.title}
                desc={categoryDetails?.description || ""}
                image={{
                  imageUrl: categoryDetails?.imageUrl,
                  redirectUrl: categoryDetails?.redirectUrl,
                }}
                video={{
                  id: categoryDetails?.playerEmbedUrl,
                  cover: categoryDetails?.videoThumbnail,
                }}
                activeCategory={activeCategory}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            {carousels &&
              carousels.length > 0 &&
              carousels?.map((info, index) => (
                <CorporatePresentationCard key={index} info={info} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setActiveSubRoute: (route) => dispatch(setActiveSubRoute(route)),
});

export default connect(null, mapDispatchToProps)(CustomCourses);
