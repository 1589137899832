import React from "react";

export const Testimonial = ({ name, imageUrl, text, title, handle }) => {
  return (
    <div className="Testimonial d-flex flex-column flex-md-row align-items-start justify-content-md-center align-items-md-center">
      <div className="top d-flex justify-content-start align-items-center">
        <div className="image-area position-relative">
          <div
            className="image d-flex justify-content-center align-items-center position-relative"
            style={{ backgroundImage: `url(${imageUrl})` }}
          >
            <img src={imageUrl} alt={name} className="w-100" />
          </div>
        </div>
        <div className="info d-flex d-md-none flex-column align-items-start">
          <h5 className="name">{name}</h5>
          <h6 className="title text-uppercase">{title}</h6>
          <span className="handle">{handle}</span>
        </div>
      </div>
      <div className="bottom d-flex flex-column align-items-start">
        <p
          className="content text-left"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className="info d-none d-md-flex flex-column align-items-start">
          <h5 className="name">{name}</h5>
          <h6 className="title text-uppercase">{title}</h6>
          <span className="handle">{handle}</span>
        </div>
      </div>
    </div>
  );
};
