import React, { Component } from "react";
import { Auth } from "../Auth";
import LoginBox from "../components/LoginBox";

export default class Login extends Component {
  render() {
    return (
      <Auth prevLocation={this.props.prevLocation}>
        <LoginBox />
      </Auth>
    );
  }
}
