import React, { Component } from "react";
import API, { headers } from "../../utils/API";
import { HAButton } from "../../utils/components/HAButton";
import HAInput from "../../utils/components/HAInput";
import {
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
  validate,
} from "../../utils/helper";
import Profile from "../Profile";

export default class ChangePassword extends Component {
  state = {
    isLoading: true,
    currPass: "",
    newPass: "",
    isButtonActive: false,
    isSending: false,
  };

  componentDidMount = () => {
    this.setState({ isLoading: false });
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, this.checkChanges);

  checkChanges = () => {
    if (!this.state.currPass || !this.state.newPass) {
      this.setState({ isButtonActive: false });
      return;
    }

    const keys = ["newPass"];
    const empty = keys.filter((key) => !validate(this.state[key], "password"));

    const isButtonActive = !Boolean(empty.length);

    this.setState({ isButtonActive });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSending: true }, () => {
      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      const { currPass: currentPassword, newPass: newPassword } = this.state;
      const data = {
        currentPassword,
        newPassword,
      };

      API.post("Account/ChangePassword", data, {
        headers: {
          ...headers,
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
        .then(({ data: res }) => {
          const { data, info } = res;

          if (data) {
            this.setState({ currPass: "", newPass: "", isSending: false }, () =>
              this.props.showInfoBanner("Şifreniz güncellendi.", "success")
            );
          } else {
            this.props.showInfoBanner(
              info?.friendlyMessage || `Bir hata oluştu!`,
              "error"
            );
            this.setState({ isSending: false });
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isSending: false });
        });
    });
  };

  render() {
    return (
      <Profile isLoading={this.state.isLoading}>
        <div className="page page--change-password">
          <section className="page-section">
            <div className="row">
              <div className="col">
                <h6 className="section-title">Şifremi Değiştir</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <HAInput
                        type="password"
                        label="Mevcut Şifreniz"
                        name="currPass"
                        value={this.state.currPass}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <HAInput
                        type="password"
                        label="Yeni Şifreniz"
                        name="newPass"
                        value={this.state.newPass}
                        onChange={this.handleChange}
                        showRules
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <HAButton
                        className="w-100"
                        disabled={
                          !this.state.isButtonActive || this.state.isSending
                        }
                        action="submit"
                      >
                        Şifreni Güncelle
                      </HAButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </Profile>
    );
  }
}
