import React, { Component } from "react";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

class HAInput extends Component {
  state = {
    showingPass: false,
    showingSuggestions: false,
    isFilled: false,
  };

  id = uniqueId();

  componentDidMount = () => {
    if (this.props.value !== "" && !this.state.isFilled)
      setTimeout(this.handleCheckIfFilled, 20);
  };

  componentDidUpdate = (prevProps) => {
    // if (prevProps.value !== this.props.value)
    //   if (this.props.value) this.setState({ isFilled: true });
    //   else this.setState({ isFilled: false });

    if ("suggestions" in this.props)
      if (prevProps.suggestions.length !== this.props.suggestions.length)
        this.handleFocus();

    if (this.props.value !== "" && !this.state.isFilled)
      setTimeout(this.handleCheckIfFilled, 20);
  };

  handleTogglePassword = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((prevState) => ({ showingPass: !prevState.showingPass }));
  };

  handleCheckIfFilled = (e = null) => {
    let value = null;
    if (e) {
      if (e.target !== document.querySelector("input:focus"))
        value = e.target.value;
    } else {
      value = this.props.value;
    }

    if (value) this.setState({ isFilled: true });
    else this.setState({ isFilled: false });
  };

  handleFocus = () => {
    this.setState({ isFilled: true });
    if ("suggestions" in this.props)
      if (this.props.suggestions.length && !this.state.showingSuggestions) {
        this.setState({ showingSuggestions: true });
      }
  };

  handleBlur = (e) => {
    this.handleCheckIfFilled(e);

    if ("suggestions" in this.props)
      if (this.props.suggestions.length && this.state.showingSuggestions)
        setTimeout(() => this.setState({ showingSuggestions: false }), 250);

    if (`onBlur` in this.props) this.props.onBlur(e);
  };

  render() {
    const {
      className,
      label,
      type,
      name,
      value,
      onChange,
      disabled,
      inputMode,
      maxLength,
      minLength,
      max,
      min,
      readOnly,
      error,
      inputProps,
    } = this.props;
    return (
      <div
        className={`HAInput d-flex flex-column align-items-start position-relative cursor--pointer ${
          "suggestions" in this.props ? `suggestions-fix` : ``
        } ${this.state.isFilled === true ? `active` : ``} ${
          `showRules` in this.props ? `show-rules` : ``
        } ${type === "textarea" ? `h-auto` : ``} ${error ? `error` : ``} ${
          readOnly ? `readonly` : ``
        } ${className}`}
        onClick={() => document.getElementById(`input-${this.id}`).focus()}
      >
        <label
          htmlFor={`input-${this.id}`}
          className={`position-absolute ${
            this.state.isFilled === true ? `active` : ``
          } ${readOnly ? "cursor--default" : "cursor--pointer"}`}
        >
          {label}
        </label>
        <div className="input-area w-100 h-100 d-flex justify-content-start align-items-center position-relative">
          {type === "textarea" ? (
            <textarea
              id={`input-${this.id}`}
              className="w-100"
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              inputMode={inputMode}
              maxLength={maxLength}
              minLength={minLength}
              readOnly={readOnly}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              {...inputProps}
            />
          ) : `format` in this.props ? (
            <NumberFormat
              id={`input-${this.id}`}
              className="w-100"
              type={type}
              name={name}
              value={value}
              onValueChange={({ formattedValue }) =>
                onChange({ target: { name, value: formattedValue } })
              }
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              readOnly={readOnly}
              inputMode={
                inputMode
                  ? inputMode
                  : type === "email"
                  ? "email"
                  : type === "tel"
                  ? "tel"
                  : "text"
              }
              format={this.props.format}
              isNumericString={this.props.isNumericString}
              mask={this.props.mask}
              {...inputProps}
            />
          ) : (
            <input
              id={`input-${this.id}`}
              className="w-100"
              type={
                type === "password"
                  ? this.state.showingPass
                    ? `text`
                    : `password`
                  : type
              }
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              inputMode={
                inputMode
                  ? inputMode
                  : type === "email"
                  ? "email"
                  : type === "tel"
                  ? "tel"
                  : "text"
              }
              maxLength={maxLength}
              minLength={minLength}
              max={max}
              min={min}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              readOnly={readOnly}
              {...inputProps}
            />
          )}
          {"suggestions" in this.props &&
            Boolean(this.props.suggestions.length) &&
            this.state.showingSuggestions && (
              <div className="suggestions overflow-auto position-absolute d-flex flex-column">
                {this.props.suggestions.map((suggestion, i) => (
                  <div
                    key={i}
                    className="suggestion-item cursor--pointer position-relative"
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
        </div>

        {type === "password" && (
          <button
            className="show-hide-button position-absolute p-0"
            onClick={this.handleTogglePassword}
            type="button"
          >
            {this.state.showingPass ? (
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6912 12.2616C10.5329 12.7995 9.27263 13.0706 8 13.0555C6.6879 13.0722 5.38937 12.7833 4.2032 12.211C3.28374 11.7535 2.45811 11.1215 1.7704 10.349C1.04039 9.55082 0.466638 8.61775 0.08 7.59996L0 7.34374L0.084 7.08589C0.662248 5.58148 1.63541 4.26848 2.8968 3.29081L0.8 1.15215L1.9304 0L15.5056 13.8462L14.3776 15H14.376L11.6912 12.2616ZM1.6936 7.34374C2.77713 9.89357 5.2767 11.5106 8 11.4236C8.83987 11.4306 9.67485 11.2927 10.4696 11.0156L9.0296 9.54686C8.70907 9.70712 8.35697 9.79084 8 9.79166C6.67797 9.7832 5.60829 8.69217 5.6 7.34374C5.60038 6.97881 5.68247 6.61879 5.84 6.29114L4.0288 4.44623C3.00634 5.17727 2.19892 6.17911 1.6936 7.34374ZM13.168 9.15438C13.6365 8.61414 14.0205 8.00341 14.3064 7.34374C13.2243 4.79267 10.7238 3.17501 8 3.26389C7.8024 3.26389 7.604 3.27123 7.412 3.2851L6 1.84328C6.6573 1.69986 7.32783 1.62901 8 1.63194C9.31209 1.61533 10.6106 1.90417 11.7968 2.47647C12.7163 2.93402 13.5419 3.56595 14.2296 4.33852C14.9593 5.13569 15.533 6.06762 15.92 7.08426L16 7.34374L15.916 7.60159C15.5414 8.59551 14.9873 9.50889 14.2824 10.2943L14.2816 10.2894L13.168 9.15438Z"
                  fill="#081720"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.2032 10.1859C3.28375 9.74533 2.45812 9.13693 1.7704 8.39315C1.04195 7.62415 0.468374 6.72664 0.08 5.74806L0 5.49981L0.084 5.25156C0.472652 4.27384 1.04499 3.37656 1.7712 2.60647C2.45867 1.86276 3.28403 1.25435 4.2032 0.813744C5.38937 0.26272 6.6879 -0.0153627 8 0.000654691C9.31209 -0.0153351 10.6106 0.262746 11.7968 0.813744C12.7163 1.25425 13.5419 1.86266 14.2296 2.60647C14.9594 3.37443 15.5332 4.27221 15.92 5.25156L16 5.49981L15.916 5.74806C14.661 8.95625 11.4994 11.0534 8 10.999C6.6879 11.015 5.38936 10.7369 4.2032 10.1859ZM1.6936 5.49981C2.77696 7.95486 5.27663 9.5118 8 9.42778C10.7232 9.51136 13.2227 7.95457 14.3064 5.49981C13.2243 3.04371 10.7238 1.48627 8 1.57184C5.2767 1.48804 2.77713 3.0449 1.6936 5.49981ZM5.61677 5.95842C5.38605 4.84791 5.98742 3.73224 7.05231 3.29517C8.1172 2.85809 9.34819 3.22169 9.99084 4.16313C10.6335 5.10456 10.5083 6.36088 9.692 7.16213C9.24506 7.60619 8.63581 7.85625 8 7.85659C6.84586 7.86409 5.84749 7.06892 5.61677 5.95842Z"
                  fill="#838B8F"
                />
              </svg>
            )}
          </button>
        )}
        {type === "password" && `showRules` in this.props && (
          <span className="pass-rules position-absolute">
            Şifreniz en az 1 büyük harf ve rakamlardan oluşan minimum 6 karakter
            içermelidir.
          </span>
        )}
      </div>
    );
  }
}

HAInput.defaultProps = {
  className: "",
  type: "text",
  onChange: () => {},
  placeholder: "",
  value: "",
  ccValue: "+90",
  disabled: false,
  maxLength: 999999,
  minLength: 0,
  readOnly: false,
};

HAInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  ccName: PropTypes.string,
  ccValue: PropTypes.string,
  disabled: PropTypes.bool,
  inputMode: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  suggestions: PropTypes.array,
};

export default HAInput;
