import React from "react";
import { Helmet } from "react-helmet-async";
import { getRoute } from "../utils/helper";

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <Helmet>
        <title>{getRoute("about-us")?.name}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h4 className="page-title">Hakkımızda</h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Hypers Academy, Hypers New Media tarafından, içerik üreticilerine
              ve içerik üreticisi olmak isteyenlere yönelik hazırlanan online
              eğitim platformudur.
            </p>

            <p>
              Bir içerik üreticisinin sahip olması gereken tüm bilgileri göz
              önünde bulundurarak tasarlanan Hypers Academy’de sektör
              profesyonelleri ve influencerlardan bilgi ve ilham veren
              içeriklerden oluşan bir eğitim müfredatı bulunmaktadır.
            </p>

            <p>
              Hypers Academy online eğitim platformu olup, internet bağlantısı
              olan her yerde bu eğitimlere erişim imkanı sağlar.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
