import React, { useState } from "react";
import logo from "../assets/images/home/logo-az.svg";
import arrowRight from "../assets/images/home/arrow-right.svg";
import defaultImage from "../assets/images/home/01.jpg";
import { brands } from "../utils/db/homeBrands";
import creatorHoverLogo from "../assets/images/home/creator-hover.svg";
import { setActiveRoute } from "../App/appActions";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const [backgroundImage, setBackgroundImage] = useState(defaultImage);
  const [creatorLogo, setCreatorLogo] = useState(
    brands.find((brand) => brand.slug === "icerik-ureticiligi").logo
  );

  return (
    <div
      className="HomePage"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container d-flex flex-column justify-content-center h-100">
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="logo">
                <img src={logo} alt="Hypers Logo" />
              </div>
              <p className="spot">
                Dijital geleceğin yaratıcıları olmaya hazır mısınız?
              </p>
            </div>
          </div>
          <div className="brands" id="brands">
            <div className="row">
              {brands
                .filter((brand) => brand.visible)
                .map((brand) => (
                  <div key={brand.id} className="col-md-6">
                    <a
                      href={`/${brand.slug}${
                        brand.slug !== "workshop" ? "/kesfet" : ""
                      }`}
                      target="_self"
                      className="brand"
                      onMouseOver={() => {
                        setBackgroundImage(brand.image);
                        if (brand.slug === "icerik-ureticiligi") {
                          setCreatorLogo(creatorHoverLogo);
                        }
                      }}
                      onMouseOut={() => {
                        setBackgroundImage(defaultImage);
                        if (brand.slug === "icerik-ureticiligi") {
                          setCreatorLogo(brand.logo);
                        }
                      }}
                      onClick={() => dispatch(setActiveRoute(brand.slug))}
                    >
                      <div className="d-flex align-items-center item" id="box1">
                        <div className={`brand-logo logo-${brand.class}`}>
                          <img
                            src={
                              brand.slug === "icerik-ureticiligi"
                                ? creatorLogo
                                : brand.logo
                            }
                            alt={brand.title}
                            className="d-none d-md-flex"
                          />
                          <img
                            src={
                              brand.slug === "icerik-ureticiligi"
                                ? creatorHoverLogo
                                : brand.logo
                            }
                            alt={brand.title}
                            className="d-flex d-md-none"
                          />
                        </div>
                        <div className="arrow-right">
                          <img src={arrowRight} alt="arrow icon" />
                        </div>
                      </div>
                      <div className="line"></div>
                      <p className="d-none d-md-block">{brand.description}</p>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
