import React from "react";

import is from "is_js";

export const SliderButtons = ({ states: state, next, prev }) => {
  return (
    <>
      <button
        className={`section-slider-button d-flex align-items-center justify-content-center prev position-absolute ${
          is.touchDevice() ? `d-none` : ``
        }`}
        onClick={prev}
        data-is-active={state.prev}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.95221 13.4C8.46314 13.4 8.8362 13.0286 8.8362 12.5252C8.8362 12.2611 8.73888 12.0383 8.56857 11.8732L6.74383 9.99159L4.39194 7.80462L6.28156 7.92016H11.4253C11.9606 7.92016 12.3337 7.54053 12.3337 7.0041C12.3337 6.45942 11.9606 6.07979 11.4253 6.07979H6.28156L4.40005 6.19533L6.74383 4.00836L8.56857 2.12673C8.73888 1.96168 8.8362 1.73885 8.8362 1.47477C8.8362 0.971349 8.46314 0.599976 7.95221 0.599976C7.71702 0.599976 7.49806 0.690756 7.2872 0.897074L1.95895 6.31912C1.77242 6.50068 1.66699 6.74827 1.66699 7.0041C1.66699 7.25168 1.77242 7.49927 1.95895 7.68083L7.31153 13.1194C7.50617 13.3092 7.71702 13.4 7.95221 13.4Z"
            fill="#303030"
          />
        </svg>
      </button>
      <button
        className={`section-slider-button d-flex align-items-center justify-content-center next position-absolute ${
          is.touchDevice() ? `d-none` : ``
        }`}
        onClick={next}
        data-is-active={state.next}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.04827 13.4C5.53735 13.4 5.16429 13.0286 5.16429 12.5252C5.16429 12.2611 5.26161 12.0383 5.43192 11.8732L7.25666 9.99159L9.60855 7.80462L7.71893 7.92016H2.57515C2.03989 7.92016 1.66683 7.54053 1.66683 7.0041C1.66683 6.45942 2.03989 6.07979 2.57515 6.07979H7.71893L9.60044 6.19533L7.25666 4.00836L5.43192 2.12673C5.26161 1.96168 5.16429 1.73885 5.16429 1.47477C5.16429 0.971349 5.53735 0.599976 6.04827 0.599976C6.28346 0.599976 6.50243 0.690756 6.71329 0.897074L12.0415 6.31912C12.2281 6.50068 12.3335 6.74827 12.3335 7.0041C12.3335 7.25168 12.2281 7.49927 12.0415 7.68083L6.68896 13.1194C6.49432 13.3092 6.28346 13.4 6.04827 13.4Z"
            fill="#303030"
          />
        </svg>
      </button>
    </>
  );
};
