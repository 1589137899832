import React from "react";
import { Helmet } from "react-helmet-async";
import PaymentInfo from "../Profile/pages/Payments/PaymentInfo";
import API, { headers } from "../utils/API";
import {
  checkIdentityNo,
  checkTaxNumber,
  getAccountDetails,
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../utils/helper";
import BillingInfo from "./components/BillingInfo";
import Sidebar from "./components/Sidebar";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { resetCart } from "../Auth/authActions";
import { connect } from "react-redux";

const Payment = ({ user, showInfoBanner, app, resetCart }) => {
  const [billingInfo, setBillingInfo] = React.useState({
    type: 1,
  });
  const [billingInfoErrors, setBillingInfoErrors] = React.useState([]);
  const [paymentInfo, setPaymentInfo] = React.useState({
    type: 2,
    accept: true,
    installment: null,
  });
  const [agreement, setAgreement] = React.useState(false);
  const [isButtonActive, setIsButtonActive] = React.useState(false);
  const [isPaying, setIsPaying] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    getAccountDetails(user.token)
      .then(({ data: res }) => {
        const { userInvoiceInfo } = res.data;

        if (!userInvoiceInfo) {
          return false;
        }

        const invoiceInfo = {
          type: userInvoiceInfo.invoiceType
            ? parseInt(userInvoiceInfo.invoiceType)
            : 1,
          idNo:
            String(userInvoiceInfo.invoiceType) === "1" &&
            userInvoiceInfo.identityNo
              ? parseInt(userInvoiceInfo.identityNo)
              : "",
          companyName: userInvoiceInfo.title || "",
          taxLocation: userInvoiceInfo.taxAdministration || "",
          taxNumber:
            (String(userInvoiceInfo.invoiceType) === "2" &&
              userInvoiceInfo.identityNo) ||
            "",
          city: userInvoiceInfo.city || "",
          zipcode: userInvoiceInfo.postCode || "",
          address: userInvoiceInfo.address || "",
        };

        setBillingInfo(invoiceInfo);
      })
      .catch((err) => handleErrors(err));
  }, [user.token]);

  React.useEffect(() => {
    if (paymentInfo.installment)
      setPaymentInfo({ ...paymentInfo, installment: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo.type, paymentInfo.number]);

  const handleUpdateBillingInfo = ({ target }) => {
    setBillingInfo((prevBillingInfo) => ({
      ...prevBillingInfo,
      [target.name]: target.value,
    }));

    if (billingInfoErrors.includes(target.name)) {
      setBillingInfoErrors((prevBillingInfoErrors) => {
        const errors = [...prevBillingInfoErrors];
        errors.splice(errors.indexOf(target.name), 1);
        return errors;
      });
    }
  };

  const handleSetBillingType = (type) =>
    setBillingInfo((prevBillingInfo) => ({ ...prevBillingInfo, type }));

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const errors = [...billingInfoErrors];

    const updateError = (state, name) => {
      if (state) {
        errors.splice(errors.indexOf(name), 1);
      } else {
        if (!errors.includes(name)) errors.push(name);
      }
    };

    if (!value.trim() && !errors.includes(name)) {
      errors.push(name);
    }

    if (name === "idNo" && billingInfo.type === 1) {
      updateError(checkIdentityNo(value), "idNo");
    } else if (name === "taxNumber" && billingInfo.type === 2) {
      updateError(checkTaxNumber(value), "taxNumber");
    }

    setBillingInfoErrors(errors);
  };

  const handleUpdatePaymentInfo = ({ target }) =>
    setPaymentInfo((prevPaymentInfo) => ({
      ...prevPaymentInfo,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    }));

  const handleSetPaymentType = (type) =>
    setPaymentInfo((prevPaymentInfo) => ({
      ...prevPaymentInfo,
      type,
    }));

  const handleSetPaymentInfo = (paymentInfo) =>
    setPaymentInfo({ ...paymentInfo, type: 1 });

  React.useEffect(() => {
    if (!agreement) {
      setIsButtonActive(false);
      return false;
    }

    if (paymentInfo.type === 1) {
      if (!paymentInfo.id) {
        setIsButtonActive(false);
        return false;
      }
    } else if (paymentInfo.type === 2) {
      const paymentInputsToCheck = ["number", "name", "expiry", "cvv"];

      if (paymentInputsToCheck.some((input) => !paymentInfo[input])) {
        setIsButtonActive(false);
        return false;
      }
    }

    const inputsToCheck = ["city", "zipcode", "address"];

    if (billingInfo.type === 1) {
      inputsToCheck.push("idNo");
    } else {
      inputsToCheck.push("companyName", "taxLocation", "taxNumber");
    }

    const formIsFilled = Object.keys(billingInfo).every(
      (key) =>
        !billingInfo[key] ||
        billingInfo[key] !== "" ||
        billingInfo[key] !== undefined
    );

    const personalPaymentIsValid =
      billingInfo.type === 1 && checkIdentityNo(billingInfo.idNo);

    const corporatePaymentIsValid =
      billingInfo.type === 2 && checkTaxNumber(billingInfo.taxNumber);

    if (!formIsFilled && !(personalPaymentIsValid || corporatePaymentIsValid)) {
      setIsButtonActive(false);
      return false;
    }

    setIsButtonActive(true);
  }, [billingInfo, agreement, paymentInfo]);

  const handlePay = () => {
    setIsPaying(true);

    const data = {
      invoiceInfo: {
        postCode: billingInfo.zipcode,
        city: billingInfo.city,
        district: "",
        country: "Turkey",
        address: billingInfo.address,
      },
      installment: paymentInfo.installment?.installmentNumber || 1,
    };

    if (billingInfo.type === 1) {
      data.invoiceInfo = {
        ...data.invoiceInfo,
        type: "1",
        identityNo: String(billingInfo.idNo),
      };
    } else {
      data.invoiceInfo = {
        ...data.invoiceInfo,
        type: "2",
        identityNo: String(billingInfo.taxNumber),
        title: billingInfo.companyName,
        taxAdministration: billingInfo.taxLocation,
      };
    }

    if (paymentInfo.type === 1) {
      data.cardInfo = {
        cardToken: paymentInfo.id,
      };
    } else {
      const [month, year] = paymentInfo.expiry.split("/");
      data.cardInfo = {
        cardHolderName: paymentInfo.name,
        cardNumber: paymentInfo.number,
        expireMonth: month,
        expireYear: year,
        cvc: paymentInfo.cvv,
        registerCard: paymentInfo.accept ? 1 : 0,
        cardToken: "",
      };
    }

    API.post("Payment/MakePayment", data, {
      headers: { ...headers, Authorization: `Bearer ${user.token}` },
    })
      .then(({ data: r }) => {
        if (r.status) {
          navigate({
            pathname: getRoute("payment-complete")?.link,
            search: createSearchParams({
              order: r.data,
            }).toString(),
          });

          sendGAEvent("event", "purchase", {
            transaction_id: r.data,
            currency: "TRY",
            value: user?.cart?.amountToBePaid,
            items: [
              ...user?.cart?.items.map((item) => ({
                item_id: item.id,
                item_name: item.title,
              })),
            ],
          });

          sendMetaEvent("track", "Purchase", {
            value: user?.cart?.amountToBePaid,
            currency: "TRY",
            content_ids: [...user?.cart?.items.map((item) => item.id)],
            content_type: "product",
          });

          resetCart();
        } else {
          if (`friendlyMessage` in r.info)
            showInfoBanner(r.info.friendlyMessage, "error");
          setIsPaying(false);
        }
      })
      .catch((err) => {
        handleErrors(err);
        setIsPaying(false);
      });
  };

  return (
    <div className="PaymentPage">
      <Helmet>
        <title>Ödeme</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <h6 className="page-title">Fatura Bilgilerim</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <BillingInfo
                    handleUpdateBillingInfo={handleUpdateBillingInfo}
                    billingInfo={billingInfo}
                    handleSetBillingType={handleSetBillingType}
                    handleInputBlur={handleInputBlur}
                    billingInfoErrors={billingInfoErrors}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="divider" />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <PaymentInfo
                    user={user}
                    theme={app.theme}
                    newCardArea={false}
                    isCart
                    handleSetPaymentType={handleSetPaymentType}
                    paymentInfo={paymentInfo}
                    handleUpdatePaymentInfo={handleUpdatePaymentInfo}
                    handleSetPaymentInfo={handleSetPaymentInfo}
                    showInfoBanner={showInfoBanner}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <Sidebar
              type={2}
              agreement={agreement}
              setAgreement={({ target }) => setAgreement(target.checked)}
              isButtonActive={isButtonActive}
              handlePay={handlePay}
              isPaying={isPaying}
              paymentInfo={paymentInfo}
              setPaymentInfo={setPaymentInfo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
});

export default connect(null, mapDispatchToProps)(Payment);
