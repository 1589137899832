import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import reportWebVitals from "./reportWebVitals";

import store from "./store";
import AppContextProvider from "./App/context/appContext";

const App = React.lazy(() => import("./App"));

const tagManagerArgs = {
  gtmId: "G-CNEH24X9SZ",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AppContextProvider>
        <Provider store={store}>
          <HelmetProvider>
            <BrowserRouter>
              <Suspense fallback={<div></div>}>
                <App />
              </Suspense>
            </BrowserRouter>
          </HelmetProvider>
        </Provider>
      </AppContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
