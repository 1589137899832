import React from "react";
import HAInput from "../../../utils/components/HAInput";

export const Personal = ({
  input,
  onChange,
  handleInputBlur,
  errors,
  ...rest
}) => {
  return (
    <div className="row">
      <div className="col">
        <HAInput
          name={input.name}
          value={input.value}
          label={input.label}
          onChange={onChange}
          onBlur={handleInputBlur}
          formatNumber
          format="###########"
          error={errors?.includes(input.name)}
        />
      </div>
    </div>
  );
};
