import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { resetCart } from "../Auth/authActions";
import { HAButton } from "../utils/components/HAButton";
import { getIconByCategory, getRoute } from "../utils/helper";
import useAppContext from "../utils/hooks/useAppContext";

const PaymentComplete = ({
  location,
  history,
  showInfoBanner,
  app,
  resetCart,
}) => {
  const { activeCategory } = useAppContext();
  const [orderId, setOrderId] = React.useState("");

  React.useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      if (params) {
        if (params.get("orderId")) {
          setOrderId(params.get("orderId"));
        } else {
          showInfoBanner("Sipariş bulunamadı.", "info");
          history.push("/");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (orderId) {
      resetCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <div className="PaymentComplete">
      <Helmet>
        <title>Ödeme Tamamlandı</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <input type="hidden" name="orderId" value={orderId} />
            <img
              src={
                getIconByCategory({
                  iconMainKey: "payment-complete-icon",
                  className: "icon",
                })?.["imageUrl"]
              }
              alt="Payment complete icon"
            />
            <p className="text">Ödemen başarıyla tamamlandı</p>
            <HAButton
              type="pri"
              action={getRoute("courses")
                ?.link.replace(":slug?", "")
                .replace(":category", activeCategory)}
              isLink
            >
              Eğitimlere Gözat
            </HAButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
});

export default connect(null, mapDispatchToProps)(PaymentComplete);
