import React from "react";
import { Link } from "react-router-dom";

const CoursesBreadcrumbs = ({ items }) => {
  return (
    <div className="CoursesBreadcrumbs d-flex align-items-center justify-content-start position-relative">
      {items.map((item) => (
        <div
          key={item.id}
          className="item position-relative d-flex align-items-center"
        >
          {item.link ? (
            <Link to={item.link} className="content">
              {item.title}
            </Link>
          ) : (
            <div className="content cursor--default">{item.title}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CoursesBreadcrumbs;
