import React from "react";
import Subscriptions from "../Explore/components/Subscriptions";
import Testimonials from "../Explore/components/Testimonials";

const Subscription = () => {
  return (
    <div className="SubscriptionPage">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Subscriptions />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Testimonials />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
