import React, { Component } from "react";
import { getIconByCategory, handleErrors } from "../../utils/helper";
import { EmptyArea } from "../components/EmptyArea";
import Profile from "../Profile";
import Filled from "./BillingInfo/Filled";

import API, { headers } from "../../utils/API";

export default class BillingInfo extends Component {
  state = {
    addingSectionOn: false,
    isLoading: true,
    invoiceInfo: null,
  };

  componentDidMount = () => {
    API.get("Account/GetUserInfo", {
      headers: { ...headers, Authorization: `Bearer ${this.props.user.token}` },
    })
      .then(({ data: res }) => {
        const { userInvoiceInfo } = res.data;

        if (!userInvoiceInfo) {
          this.setState({ isLoading: false });
          return false;
        }

        const invoiceInfo = {
          type: userInvoiceInfo.invoiceType
            ? parseInt(userInvoiceInfo.invoiceType)
            : 1,
          idNo:
            String(userInvoiceInfo.invoiceType) === "1" &&
            userInvoiceInfo.identityNo
              ? parseInt(userInvoiceInfo.identityNo)
              : "",
          companyName: userInvoiceInfo.title || "",
          taxLocation: userInvoiceInfo.taxAdministration || "",
          taxNumber:
            (String(userInvoiceInfo.invoiceType) === "2" &&
              userInvoiceInfo.identityNo) ||
            "",
          city: userInvoiceInfo.city || "",
          zipcode: userInvoiceInfo.postCode || "",
          address: userInvoiceInfo.address || "",
        };

        this.setState({ invoiceInfo, isLoading: false });
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isLoading: false });
      });
  };

  showAddingSection = () => this.setState({ addingSectionOn: true });

  render() {
    return (
      <Profile isLoading={this.state.isLoading}>
        <div className="page page--billing-info">
          <section className="page-section">
            <div className="row">
              <div className="col">
                <h6 className="section-title">Fatura Bilgilerim</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {!this.state.isLoading ? (
                  this.state.addingSectionOn || this.state.invoiceInfo ? (
                    <Filled
                      invoiceInfo={this.state.invoiceInfo}
                      user={this.props.user}
                      showInfoBanner={this.props.showInfoBanner}
                    />
                  ) : (
                    <EmptyArea
                      icon={
                        getIconByCategory({
                          iconMainKey: "empty-billing-info-icon",
                        })?.["imageUrl"]
                      }
                      content="Henüz kayıtlı bir fatura bilginiz yok."
                      buttonText="Fatura Bilgisi Ekle"
                      buttonLink={this.showAddingSection}
                    />
                  )
                ) : (
                  ``
                )}
              </div>
            </div>
          </section>
        </div>
      </Profile>
    );
  }
}
