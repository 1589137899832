import React from "react";
import CourseList from "./CourseList";

const Chapter = ({
  slug,
  title,
  courses,
  course,
  activeChapter,
  activeCourse,
  handleShowDetails,
  isAvailableToUseForUser,
}) => {
  return (
    <div className="row">
      <div className="col">
        <div
          className={`chapter-holder ${
            String(activeChapter) === String(slug) ? `active` : ``
          }`}
          id={`chapter-${slug}`}
        >
          <button
            className="chapter-trigger w-100 d-flex justify-content-between align-items-center"
            onClick={() =>
              handleShowDetails(document.getElementById(`chapter-${slug}`))
            }
          >
            <span className="chapter-title">{title}</span>

            <div className="icon position-relative" />
          </button>

          <CourseList
            chapter={slug}
            courses={courses}
            course={course}
            activeCourse={activeCourse}
            activeChapter={activeChapter}
            isAvailableToUseForUser={isAvailableToUseForUser}
          />
        </div>
      </div>
    </div>
  );
};

export default Chapter;
