import React from "react";
import { HAButton } from "../../../utils/components/HAButton";
import { Link } from "react-router-dom";
import CorporateCarouselArea from "./CorporateCarouselArea";
import useAppContext from "../../../utils/hooks/useAppContext";

const CorporatePresentationCard = ({ info }) => {
  const { createModuleTree, activeCategory } = useAppContext();

  if (!info) return null;

  const { title, description, carouselList, internalLink } = info;

  return (
    <div className="CorporatePresentationCard row d-flex">
      <div className="col-12 col-md-6 d-none d-md-flex">
        <CorporateCarouselArea carouselList={carouselList} />
      </div>

      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <div className="d-flex flex-column gap-2 content-area">
          <h2>{title}</h2>
          <p className="l-height--1-2 description">{description}</p>

          <div className="d-flex d-md-none">
            <CorporateCarouselArea carouselList={carouselList} />
          </div>

          <Link
            to={
              activeCategory === "workshop"
                ? `/workshop/${internalLink}`
                : "iletisime-gec"
            }
          >
            <HAButton
              className="btn btn-primary"
              onClick={() => createModuleTree(title)}
            >
              {activeCategory === "workshop"
                ? "Detayını Gör"
                : " İletişime Geç"}
            </HAButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CorporatePresentationCard;
