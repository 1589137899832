import { useEffect, useState } from "react";
import SwiperCore, { Autoplay, Controller, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import IntroVideoArea from "./IntroVideoArea";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { handleImageRedirect } from "../helper";

SwiperCore.use([Pagination, Controller, Autoplay]);

const HACardSlider = ({ carousels, ...props }) => {
  const [swiper, setSwiper] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSingleCarousel, setIsSingleCarousel] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);

  useEffect(() => {
    if (carousels.length === 1) setIsSingleCarousel(true);
    else if (carousels.length > 1) setIsSingleCarousel(false);
  }, [carousels]);

  const handleBulletClick = (index) => {
    setActiveIndex(index);
    swiper.slideTo(index);
  };

  return (
    <div className="HACardSlider">
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div
          className={`d-none d-md-flex ${
            activeIndex === 0 && isSingleCarousel ? "hidden" : "visible"
          }`}
        >
          <div
            className="slider-button p-0 d-flex justify-content-center align-items-center prev"
            onClick={() => swiper.slidePrev()}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.19002 16C9.82868 16 10.295 15.5358 10.295 14.9065C10.295 14.5764 10.1734 14.2979 9.96047 14.0916L7.67954 11.7395L4.73968 9.0058L7.10171 9.15023H13.5314C14.2005 9.15023 14.6668 8.67569 14.6668 8.00516C14.6668 7.32431 14.2005 6.84977 13.5314 6.84977H7.10171L4.74982 6.9942L7.67954 4.26048L9.96047 1.90845C10.1734 1.70213 10.295 1.4236 10.295 1.09349C10.295 0.464217 9.82868 0 9.19002 0C8.89604 0 8.62233 0.113475 8.35875 0.371373L1.69844 7.14894C1.46528 7.37589 1.3335 7.68536 1.3335 8.00516C1.3335 8.31464 1.46528 8.62411 1.69844 8.85106L8.38916 15.6493C8.63246 15.8865 8.89604 16 9.19002 16Z"
                fill="#303030"
              />
            </svg>
          </div>
        </div>
        <Swiper
          className="h-100"
          simulateTouch={true}
          allowTouchMove={true}
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (swiper = setSwiper(swiper))}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
            setVideoPaused(true);
          }}
          {...props}
        >
          {carousels.map((slide, i) => (
            <SwiperSlide key={i}>
              {slide.carouselImageUrl ? (
                handleImageRedirect({
                  imageUrl: slide.carouselImageUrl,
                  redirectLink: slide.imageUrl,
                })
              ) : (
                <IntroVideoArea
                  cover={slide.videoThumbnail}
                  id={slide.carouselVideo}
                  videoPaused={videoPaused}
                  setVideoPaused={setVideoPaused}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className={`d-none d-md-flex ${
            activeIndex === carousels.length - 1 && isSingleCarousel
              ? "hidden"
              : "visible"
          }`}
        >
          <div
            className="slider-button p-0 d-flex justify-content-center align-items-center next"
            onClick={() => swiper.slideNext()}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.80998 16C6.17132 16 5.70499 15.5358 5.70499 14.9065C5.70499 14.5764 5.82664 14.2979 6.03953 14.0916L8.32046 11.7395L11.2603 9.0058L8.89829 9.15023H2.46857C1.79949 9.15023 1.33317 8.67569 1.33317 8.00516C1.33317 7.32431 1.79949 6.84977 2.46857 6.84977H8.89829L11.2502 6.9942L8.32046 4.26048L6.03953 1.90845C5.82664 1.70213 5.70499 1.4236 5.70499 1.09349C5.70499 0.464217 6.17132 0 6.80998 0C7.10396 0 7.37767 0.113475 7.64125 0.371373L14.3016 7.14894C14.5347 7.37589 14.6665 7.68536 14.6665 8.00516C14.6665 8.31464 14.5347 8.62411 14.3016 8.85106L7.61084 15.6493C7.36754 15.8865 7.10396 16 6.80998 16Z"
                fill="#303030"
              />
            </svg>
          </div>
        </div>
      </div>

      <CustomPagination
        activeIndex={activeIndex}
        onBulletClick={handleBulletClick}
        slideCount={carousels && carousels.length}
      />
    </div>
  );
};
export default HACardSlider;

const CustomPagination = ({ activeIndex, onBulletClick, slideCount }) => {
  const totalSlides = slideCount;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="swiper-pagination swiper-pagination-bullets cursor--pointer">
        {Array.from({ length: totalSlides }, (_, index) => (
          <div
            key={index}
            className={`swiper-pagination-bullet ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => onBulletClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
