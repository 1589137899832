import React, { useEffect, useState } from "react";
import HAInput from "../utils/components/HAInput";
import HACheckbox from "../utils/components/HACheckbox";
import { Link } from "react-router-dom";
import { getLoginData, getRoute, validate } from "../utils/helper";
import { HAButton } from "../utils/components/HAButton";
import { useLocation, useNavigate } from "react-router";
import API from "../utils/API";
import { login } from "../Auth/authActions";
import { connect } from "react-redux";
import { Loader } from "../utils/components/Loader";

const CreateAccount = ({ showInfoBanner, login, activeRoute }) => {
  const [isSending, setIsSending] = useState(false);
  const [pageIsLoaded, setPageIsLoaded] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [token, setToken] = useState("");
  const [formDatas, setFormDatas] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    agreements: false,
    newsletter: false,
    baseContentType: 0,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const createAccount = (e) => {
    e.preventDefault();
    setIsSending(true);

    const { firstName, lastName, email, password, newsletter } = formDatas;

    const payload = {
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      email: email,
      password: password,
      termsAndConditionsVersion: "1.0",
      privacyPolicyVersion: "1.0",
      emailSubscription: newsletter,
    };

    API.post(`Account/CreatePasswordRequest?token=${token}`, payload)
      .then((res) => {
        const { status, data, info } = res;
        if (status) {
          setIsSending(false);

          showInfoBanner("Hesabınız başarıyla oluşturuldu.", "success");

          const user = getLoginData({ ...payload, ...data.data });
          login(user);

          navigate(
            getRoute("explore")?.link.replace(":category", activeRoute),
            { replace: true }
          );
        } else {
          showInfoBanner(
            info?.friendlyMessage || "Hesap oluşturulamadı.",
            "error"
          );
        }
      })
      .catch((err) => {
        setIsSending(false);
        console.log(err);
      });
  };

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    setFormDatas((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getDataByToken = () => {
    API.get(`Account/CreatePasswordRequest?token=${token}`)
      .then((res) => {
        const { status, data, info } = res;

        if (status) {
          setPageIsLoaded(true);

          if (!data.data) {
            navigate(
              getRoute("explore")?.link.replace(":category", activeRoute),
              { replace: true }
            );
            return;
          }
          setFormDatas((prev) => ({
            ...prev,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            email: data.data.email,
            baseContentType: data.data.baseContentType,
          }));
        } else {
          showInfoBanner(
            info?.friendlyMessage || "Hesap oluşturulamadı.",
            "error"
          );
        }
      })
      .catch((err) => {
        setIsSending(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (token) getDataByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const passwordIsValid =
      formDatas.password && validate(formDatas.password, "password");

    const formIsValid = passwordIsValid && formDatas.agreements;

    if (formIsValid) setButtonIsDisabled(false);
    else setButtonIsDisabled(true);
  }, [formDatas]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      if (params) {
        if (params.get("token")) {
          setToken(params.get("token"));
        }
      }
    }
  }, [location]);

  return !pageIsLoaded ? (
    <Loader />
  ) : (
    <div className="CreateAccount ContactWith w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-5 mx-auto">
            <form
              action="#!"
              onSubmit={createAccount}
              className="d-flex flex-column shadow-sm"
            >
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column gap-2 mb-2">
                    <h6 className="title">Hesap Oluştur</h6>
                  </div>
                </div>
                <div className="col-12 half-inputs">
                  <HAInput
                    name="firstName"
                    type="text"
                    value={formDatas.firstName || ""}
                    label="Adınız"
                    readOnly={true}
                  />
                  <HAInput
                    name="lastName"
                    type="text"
                    value={formDatas.lastName || ""}
                    label="Soyadınız"
                    readOnly={true}
                  />
                </div>

                <div className="col-12">
                  <HAInput
                    name="email"
                    type="email"
                    label="E-Posta Adresiniz"
                    value={formDatas.email || ""}
                    readOnly={true}
                  />
                </div>

                <div className="col-12">
                  <HAInput
                    name="password"
                    type="password"
                    label="Şifre"
                    value={formDatas.password || ""}
                    onChange={handleChange}
                    showRules
                  />
                </div>

                <div className="col-12 mt-2">
                  <HACheckbox
                    name="agreements"
                    checked={formDatas.agreements || false}
                    onChange={handleChange}
                  >
                    <>
                      <Link
                        to={getRoute("agreement-page")?.link?.replace(
                          ":name",
                          "kvkk-aydinlatma-metni"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        KVKK Aydınlatma Metni
                      </Link>{" "}
                      ve{" "}
                      <Link
                        to={getRoute("agreement-page")?.link?.replace(
                          ":name",
                          "uyelik-sozlesmesi"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Üyelik Sözleşmesi
                      </Link>
                      ’ni okudum ve onaylıyorum.
                    </>
                  </HACheckbox>
                </div>

                <div className="col-12">
                  <HACheckbox
                    name="newsletter"
                    checked={formDatas.newsletter || false}
                    onChange={handleChange}
                  >
                    Kampanyalardan haberdar olmak istiyorum.
                  </HACheckbox>
                </div>

                <div className="col-12 mt-3">
                  <HAButton
                    className="w-100 login-button mt-2"
                    disabled={buttonIsDisabled}
                    action="submit"
                    isLoading={isSending}
                  >
                    Hesap Oluştur
                  </HAButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  activeRoute: app.app.activeRoute,
});

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
