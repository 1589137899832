/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import HAInput from "../../utils/components/HAInput";
import { HAButton } from "../../utils/components/HAButton";
import { InfoArea } from "../../utils/components/InfoArea";
import { Link, redirect } from "react-router-dom";
import HACheckbox from "../../utils/components/HACheckbox";
import API, { headers } from "../../utils/API";
import {
  getCurrentRouteName,
  getLoginData,
  getPrevLocation,
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
  validate,
} from "../../utils/helper";
import { guest } from "../../App/routes.v2";
import { hideAuthBox, login } from "../authActions";
import { connect } from "react-redux";
import { showInfoBanner } from "../../App/appActions";

class RegisterBox extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    agreements: false,
    newsletter: false,
    isButtonEnabled: true,
    isSending: false,
    error: "",
    errors: [],
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, () => {
      this.resetCurrentError(target.name);
    });

  handleCheckbox = ({ target }) =>
    this.setState({ [target.name]: target.checked }, () =>
      this.resetCurrentError(target.name)
    );

  resetCurrentError = (key) => {
    if (this.state.errors.includes(key))
      this.setState({
        errors: this.state.errors.filter((error) => error !== key),
      });
  };

  checkForm = () => {
    const keys = ["firstName", "lastName", "email", "password", "agreements"];

    const errors = keys.filter((key) => !this.state[key]);

    if (
      !validate(this.state.password, "password") &&
      !errors.includes("password")
    )
      errors.push("password");

    if (!validate(this.state.email, "email") && !errors.includes("email"))
      errors.push("email");

    if (errors.length) {
      this.setState({ errors, error: "Hatalı ya da eksik alanlar var." });
      return false;
    }

    this.setState({ errors: [], error: "" });
    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.checkForm()) {
      return;
    }

    if (!this.state.isSending)
      this.setState({ isSending: true }, () => {
        sendGAEvent("event", "generate_lead");
        sendMetaEvent("track", "Lead");

        sendGAEvent("event", "sign_up");
        sendMetaEvent("track", "Sign Up");

        const { firstName, lastName, email, password, newsletter } = this.state;
        const data = {
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          email,
          password,
          termsAndConditionsVersion: "1.0",
          privacyPolicyVersion: "1.0",
          emailSubscription: newsletter,
        };

        API.post("Account/Register", data)
          .then((res) => {
            const { status, info, data } = res.data;

            if (status) {
              let redirectTo = null;

              const currentRoute = getCurrentRouteName(
                window.location.pathname
              );

              if (currentRoute in guest) {
                redirectTo = getPrevLocation();
              }

              API.get("Account/GetUserInfo", {
                headers: { ...headers, Authorization: `Bearer ${data.token}` },
              })
                .then(({ data: res }) => {
                  const { status } = res;

                  if (status) {
                    const user = getLoginData({ ...data, ...res.data });
                    this.props.login(user);

                    localStorage.removeItem("prevLocation");
                    // if (redirect) this.props.history.push(redirect);

                    redirect(getRoute("explore")?.link);

                    this.props.hideAuthBox();
                  } else if (info && "friendlyMessage" in info) {
                    this.setState({
                      isSending: false,
                      error: info.friendlyMessage,
                    });
                  }
                })
                .catch((err) => {
                  this.setState({ isSending: false });
                  handleErrors(err);
                });
            } else if (info && "friendlyMessage" in info) {
              this.setState({
                isSending: false,
                error: info.friendlyMessage || `Bilgilerinizi kontrol ediniz.`,
              });
            }
          })
          .catch((err) => {
            this.setState({ isSending: false });
            handleErrors(err);
          });
      });
  };

  render() {
    return (
      <div className="AuthBox">
        <h6 className="title">Üye Ol</h6>
        {this.state.error && (
          <InfoArea type="error">{this.state.error}</InfoArea>
        )}
        <form
          action="#!"
          onSubmit={this.handleSubmit}
          className="d-flex flex-column align-items-center"
        >
          <div className="half-inputs w-100 d-flex justify-content-between align-items-center">
            <HAInput
              name="firstName"
              type="text"
              value={this.state.firstName}
              onChange={this.handleChange}
              label="Adınız"
              error={this.state.errors.includes("firstName")}
            />
            <HAInput
              name="lastName"
              type="text"
              value={this.state.lastName}
              onChange={this.handleChange}
              label="Soyadınız"
              error={this.state.errors.includes("lastName")}
            />
          </div>
          <HAInput
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            label="E-Posta Adresiniz"
            error={this.state.errors.includes("email")}
          />
          <HAInput
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            label="Şifreniz"
            showRules
            error={this.state.errors.includes("password")}
          />
          <div className="checkboxes">
            <HACheckbox
              name="agreements"
              checked={this.state.agreements}
              onChange={this.handleCheckbox}
              error={this.state.errors.includes("agreements")}
            >
              <>
                <Link
                  to={getRoute("agreement-page")?.link.replace(
                    ":name",
                    "kvkk-aydinlatma-metni"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KVKK Aydınlatma Metni
                </Link>{" "}
                ve{" "}
                <Link
                  to={getRoute("agreement-page")?.link.replace(
                    ":name",
                    "uyelik-sozlesmesi"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Üyelik Sözleşmesi
                </Link>
                ’ni okudum ve onaylıyorum.
              </>
            </HACheckbox>
            <HACheckbox
              name="newsletter"
              checked={this.state.newsletter}
              onChange={this.handleCheckbox}
            >
              Kampanyalardan haberdar olmak istiyorum.
            </HACheckbox>
          </div>
          <HAButton
            className="w-100 login-button"
            disabled={!this.state.isButtonEnabled}
            action="submit"
            isLoading={this.state.isSending}
          >
            Üye Ol
          </HAButton>
        </form>
        <div className="forgot-password-link d-flex justify-content-center">
          <Link
            to={getRoute("login")?.link}
            onClick={
              `showLoginBox` in this.props ? this.props.showLoginBox : () => {}
            }
          >
            Hesabım var, giriş yapmak istiyorum.
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
  hideAuthBox: () => dispatch(hideAuthBox()),
  showInfoBanner: (message, type, timeout) =>
    dispatch(showInfoBanner(message, type, timeout)),
});

export default connect(null, mapDispatchToProps)(RegisterBox);
