import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { guest } from "../App/routes.v2";
import { getCurrentRouteName } from "../utils/helper";

export const Auth = ({ children, prevLocation }) => {
  const location = useLocation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    getRouteTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (prevLocation && `pathname` in prevLocation) {
      const { pathname } = prevLocation;
      const route = getCurrentRouteName(pathname);

      if (!(route in guest)) {
        localStorage.setItem("prevLocation", JSON.stringify(prevLocation));
      }
    }
  }, [prevLocation]);

  const getRouteTitle = () => {
    const name = getCurrentRouteName();

    setTitle(name || "");
  };

  return (
    <div className="Auth position-relative">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="page-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
